// src/components/LabEquipmentList/LabEquipmentList.js
import React, { useState } from 'react';
import './LabEquipmentList.css';

const LabEquipmentList = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: 'asc'
  });

  const equipment = [
    { id: 1, code: "253.2.5.7-69", name: "KESİNTİSİZ GÜÇ KAYNAKLARI", brand: "CLEAVER SCIENTIFIC", model: "NANOPAC-300P", type: "GÜÇ KAYNAĞI CİHAZI" },
    { id: 2, code: "253.3.1.3-18", name: "BULAŞIK YIKAMA MAKİNELERİ VE EKİPMANLARI", brand: "ARÇELİK", model: "6144", type: "BULAŞIK YIKAMA MAKİNESİ" },
    { id: 3, code: "253.3.1.3-19", name: "BULAŞIK YIKAMA MAKİNELERİ VE EKİPMANLARI", brand: "BEKO", model: "BM3003", type: "BULAŞIK YIKAMA MAKİNESİ" },
    { id: 4, code: "253.3.2.1.1-35", name: "BUZDOLAPLARI", brand: "ARÇELİK", model: "ARÇELİK 4223 EY", type: "İKİ KAPILI BUZDOLABI" },
    { id: 5, code: "253.3.2.1.1-46", name: "BUZDOLAPLARI", brand: "VESTEL", model: "NO-FROST", type: "BUZDOLABI" },
    { id: 6, code: "253.3.2.1.1-73", name: "BUZDOLAPLARI", brand: "BEKO", model: "PRO SMART INVERTER", type: "DERİN DONDURUCULU BUZDOLABI" },
    { id: 7, code: "253.3.2.1.2-4", name: "DONDURUCULAR", brand: "VESTEL", model: "DİKEY TİP", type: "DERİN DONDURUCU" },
    { id: 8, code: "253.3.2.1.99.1-8", name: "BUZ YAPMA MAKİNESİ", brand: "BAR LINE", model: "B-M2006 AS", type: "BUZ YAPMA MAKİNASI" },
    { id: 9, code: "253.3.2.2.1-25", name: "FIRINLAR", brand: "SAMSUNG", model: "MS23F300EEW", type: "MİKRODALGA FIRIN" },
    { id: 10, code: "253.3.4.1.1.2-57", name: "TERAZİLER", brand: "DİKOMSAN", model: "ES410", type: "HASSAS TERAZİ" },
    { id: 11, code: "253.3.4.1.1.2-58", name: "TERAZİLER", brand: "SHIMADZU", model: "ATX324", type: "HASSAS TERAZİ" },
    { id: 12, code: "253.3.4.1.1.2-59", name: "TERAZİLER", brand: "SHIMADZU", model: "ATX-R/ATY-R", type: "ANALİTİK TERAZİ ÖRNEK HAZIRLAMA CİHAZI" },
    { id: 13, code: "253.3.4.2.2-8", name: "KUMPASLAR", brand: "ÇOKESEN", model: "", type: "KUMPAS" },
    { id: 14, code: "253.3.5.1.1-103", name: "GENEL AMAÇLI TIBBİ CİHAZLAR VE ALETLER", brand: "AND", model: "SV-10", type: "VİSKOZİMETRE" },
    { id: 15, code: "253.3.5.1.1-131", name: "GENEL AMAÇLI TIBBİ CİHAZLAR VE ALETLER", brand: "AGİLENT", model: "XCELLİGENCE RTCA DP", type: "GERÇEK ZAMANLI HÜCRE ANALİZ CİHAZI" },
    { id: 16, code: "253.3.5.1.1-133", name: "GENEL AMAÇLI TIBBİ CİHAZLAR VE ALETLER", brand: "OHAUS", model: "HB2DG", type: "KURU BLOK ISITICI" },
    { id: 17, code: "253.3.5.1.1-134", name: "GENEL AMAÇLI TIBBİ CİHAZLAR VE ALETLER", brand: "APPLIED BIOSYSTEMS", model: "STEP ONE PLUS REAL TİME PCR SYSTEM", type: "GERÇEK ZAMANLI PCR" },
    { id: 18, code: "253.3.5.1.1-135", name: "GENEL AMAÇLI TIBBİ CİHAZLAR VE ALETLER", brand: "RWDLİFE", model: "C100", type: "HÜCRE SAYIM CİHAZI" },
    { id: 19, code: "253.3.5.1.16-14", name: "ELİZA YIKAYICI VE OKUYUCU", brand: "THERMO SCIENTIFIC", model: "MULTISCAN SKYHIGH 1550+WELLWASH 888", type: "MİKROPLAKA OKUYUCU VE YIKAYICI CİHAZI" },
    { id: 20, code: "253.3.5.1.5-7", name: "PİPETLER", brand: "EPPENDORF", model: "RESEARCH PLUS", type: "8 KANALLI PİPET" },
    { id: 21, code: "253.3.5.1.5-12", name: "PİPETLER", brand: "EPPENDORF", model: "MULTİPETTE E3", type: "ELEKTRONİK EL TİPİ DİSPENSER" },
    { id: 22, code: "253.3.5.22-12", name: "BİYOLOJİK ARAŞTIRMADA KULLANILAN CİHAZ VE ALETLER", brand: "BIORAD", model: "GENE PULSER XCELL", type: "ELEKTROPORASYON CİHAZI" },
    { id: 23, code: "253.3.5.99.3-15", name: "VORTEKS", brand: "OHAUS", model: "VXMNDG", type: "DİGİTAL VORTEX" },
    { id: 24, code: "253.3.5.99.3-16", name: "VORTEKS", brand: "BİOSAN", model: "MULTİ-VORTEX V-32", type: "VORTEKS" },
    { id: 25, code: "253.3.5.99.3-17", name: "VORTEKS", brand: "OHAUS", model: "VXMNAL", type: "ANOLOG VORTEKS" },
    { id: 26, code: "253.3.5.99.30-25", name: "MANYETİK ISITICILI KARIŞTIRICI", brand: "VELP", model: "F20500162", type: "MANYETİK ISITICILI KARIŞTIRICI" },
    { id: 27, code: "253.3.5.99.30-26", name: "MANYETİK ISITICILI KARIŞTIRICI", brand: "ISOLAB", model: "I.613.01.001", type: "MANYETİK ISITICILI KARIŞTIRICI" },
    { id: 28, code: "253.3.5.99.32-6", name: "HOMOJENİZATÖR", brand: "QİAGEN", model: "TİSSUE LYSEER LT", type: "HOMOJENİZATÖR" },
    { id: 29, code: "253.3.5.99.33-9", name: "DİSTİLE SU CİHAZI", brand: "ELGA", model: "VEOLİA PQDIUVM1", type: "DİSTİLE SU CİHAZI" },
    { id: 30, code: "253.3.5.99.33-10", name: "DİSTİLE SU CİHAZI", brand: "MERCK", model: "RİOS-DI 30UV", type: "DİSTİLE SU CİHAZI" },
    { id: 31, code: "253.3.6.1.8.2-22", name: "SPEKTROFOTOMETRELER", brand: "TECHCOMP", model: "S1020", type: "SPEKTROFOTOMETRE" },
    { id: 32, code: "253.3.6.1.8.2-23", name: "SPEKTROFOTOMETRELER", brand: "ALLSHENG", model: "NANO-300", type: "MİKRO HACİM SPEKTROFOTOMETRE" },
    { id: 33, code: "253.3.6.1.99.3-8", name: "JEL GÖRÜNTÜLEME VE ANALİZ SİSTEMLERİ", brand: "BIORAD", model: "CHEMIDOC MP IMAGING SYSTEM", type: "GÖRÜNTÜLEME VE VERİ KAYDETME CİHAZI" },
    { id: 34, code: "253.3.6.1.99.4-20", name: "PİPET SETİ", brand: "EPPENDORF", model: "RESEARCH PLUS", type: "OTOMATİK PİPET SETİ" },
    { id: 35, code: "253.3.6.1.99.4-27", name: "PİPET SETİ", brand: "RAININ", model: "PİPET-LİTE XLS", type: "OTOMATİK PİPET SETİ" },
    { id: 36, code: "253.3.6.2.9-9", name: "TERMAL ANALİZ VE ISIL ÖZELLİKLERİ ÖLÇME CİHAZLARI", brand: "APPLIED BIOSYSTEMS", model: "VERİTİPRO 96-WELL THERMAL CYCLER", type: "KONVENSİYONEL PCR" },
    { id: 37, code: "253.3.6.2.9-10", name: "TERMAL ANALİZ VE ISIL ÖZELLİKLERİ ÖLÇME CİHAZLARI", brand: "BIORAD", model: "T100 THERMAL CYCLER", type: "KONVENSİYONEL PCR(GRADİEN)" },
    { id: 38, code: "253.3.6.2.15.3-25", name: "PH ÖLÇME CİHAZLARI", brand: "OHAUS", model: "STARTER 3100", type: "PH ÖLÇME CİHAZI" },
    { id: 39, code: "253.3.6.2.15.3-26", name: "PH ÖLÇME CİHAZLARI", brand: "ISOLAB", model: "INSPECTED 082779", type: "PH METRE" },
    { id: 40, code: "253.3.6.2.15.3-27", name: "PH ÖLÇME CİHAZLARI", brand: "THERMO SCIENTIFIC", model: "ORION STAR A211", type: "PH METRE" },
    { id: 41, code: "253.3.6.2.24-11", name: "AKIŞ ÖLÇERLER (FLOWMETRELER)", brand: "BECKMAN COULTER", model: "CYTOFLEX S", type: "AKIŞ SİTOMETRİ CİHAZI" },
    { id: 42, code: "253.3.6.2.24-12", name: "AKIŞ ÖLÇERLER (FLOWMETRELER)", brand: "BECKMAN COULTER", model: "CYTOFLEX PLATE LOADER", type: "AKIŞ SİTOMETRİ PLAKASI" },
    { id: 43, code: "253.3.6.2.99.2-27", name: "KARIŞTIRICILAR", brand: "OHAUS", model: "SHLDMP03DG", type: "ORBİTAL MİKROPLAKA ÇALKALAYICI" },
    { id: 44, code: "253.3.6.2.99.3-10", name: "OCAKLAR", brand: "GLOBERLAB", model: "", type: "ÇEKER OCAK" },
    { id: 45, code: "253.3.6.2.99.4-29", name: "LABORATUVAR TİPİ BANYOLAR", brand: "DAIHAN", model: "WUC-D10H", type: "ULTRASONİK BANYO" },
    { id: 46, code: "253.3.6.2.99.4-30", name: "LABORATUVAR TİPİ BANYOLAR", brand: "DAIHAN", model: "MAXTURDY-18", type: "ÇALKALAMALI SU BANYOSU" },
    { id: 47, code: "253.3.6.2.99.4-31", name: "LABORATUVAR TİPİ BANYOLAR", brand: "İLDAM", model: "", type: "ÇALKALAMALI SU BANYOSU" },
    { id: 48, code: "253.3.6.3.1.1-24", name: "ETÜVLER", brand: "ELEKTROMAG", model: "420 BP 48 LT", type: "BAKTERİYOLOJİK ETÜV" },
    { id: 49, code: "253.3.6.3.1.1-31", name: "ETÜVLER", brand: "FROİLABO", model: "AİR PERFORMANCE", type: "KURU HAVA STERİLİZATÖRÜ" },
    { id: 50, code: "253.3.6.3.1.2-12", name: "İNKÜBATÖRLER", brand: "MEMMERT", model: "ICO 150", type: "KARBONDİOKSİTLİ İNKÜBATÖR" },
    { id: 51, code: "253.3.6.3.1.2-25", name: "İNKÜBATÖRLER", brand: "MİKROTEST", model: "MSC-250", type: "ÇALKALAMALI SOĞUTMALI İNKÜBATÖR" },
  { id: 52, code: "253.3.6.3.1.2-26", name: "İNKÜBATÖRLER", brand: "RWDLİFE", model: "D180", type: "KARBONDİOKSİTLİ İNKÜBATÖR" },
  { id: 53, code: "253.3.6.3.1.2-27", name: "İNKÜBATÖRLER", brand: "FAITHFUL", model: "FS-70B", type: "CONSTANT TEMPERATURE SHAKİNG İNCUBATOR" },
  { id: 54, code: "253.3.6.3.1.2-28", name: "İNKÜBATÖRLER", brand: "LABART", model: "LI-124D", type: "ELEKTROTERMAL İNKÜBATÖR" },
  { id: 55, code: "253.3.6.3.3-4", name: "AYIRICILAR (EKSTRAKTÖRLER) ELEKTRODİALİZ CİHAZLARI", brand: "BIOTAGE", model: "PRESSURE+ 48 MANİFOLD", type: "EKSTRAKSİYON CİHAZI" },
  { id: 56, code: "253.3.6.3.11-17", name: "ELEKTROFOREZ CİHAZLARI", brand: "BIORAD", model: "MİNİ PROTEAN SYSTEM", type: "ELEKTROFOREZ" },
  { id: 57, code: "253.3.6.3.11-19", name: "ELEKTROFOREZ CİHAZLARI", brand: "HOEFER", model: "HE-PLUS", type: "ELEKTROFOREZ" },
  { id: 58, code: "253.3.6.3.15.1-12", name: "OTOKLAVLAR", brand: "ALP", model: "CL-32L", type: "OTOKLAV" },
  { id: 59, code: "253.3.6.3.19-47", name: "SANTRİFÜJLER", brand: "HERMLE", model: "Z-326K", type: "SOĞUTMALI SANTRİFÜJ" },
  { id: 60, code: "253.3.6.3.19-48", name: "SANTRİFÜJLER", brand: "SIGMA", model: "1-14K", type: "SOĞUTMALI MİKRO SANTRİFÜJ" },
  { id: 61, code: "253.3.6.3.19-49", name: "SANTRİFÜJLER", brand: "WEIGHTLAB INSTRUMENTS", model: "WN-CMV6000", type: "AYARLANABİLİR MİKRO SANTRİFÜJ" },
  { id: 62, code: "253.3.6.3.19-50", name: "SANTRİFÜJLER", brand: "BECKMAN COULTER", model: "ALLEGRA X-30R", type: "MASAÜSTÜ SANTRİFÜJ" },
  { id: 63, code: "253.3.6.3.19-51", name: "SANTRİFÜJLER", brand: "BECKMAN COULTER", model: "OPTİMA XPN-100 ULTRACENTRİFUGE", type: "YER TİPİ SOĞUTMALI SANTRİFÜJ" },
  { id: 64, code: "253.3.6.3.99.3-2", name: "ELEKTROBLOTTER SİSTEM", brand: "BIORAD", model: "TRANS-BLOT TURBO TRANSFER SYSTEM", type: "WESTERN BLOT KONFİRMASYON SİSTEMİ" },
  { id: 65, code: "253.3.6.3.99.5-4", name: "DNA ANALİZ CİHAZI", brand: "INVITROJEN", model: "QUBİT 4", type: "FLUOROMETRE" },
  { id: 66, code: "253.3.6.6.2-19", name: "OPTİK MİKROSKOPLAR", brand: "NİKON", model: "E 100", type: "MİKROSKOP BİNOKÜLER" },
  { id: 67, code: "253.3.6.6.2-45", name: "OPTİK MİKROSKOPLAR", brand: "ZEISS", model: "AXİOLAB 5 (KAMERALI)", type: "ARAŞTIRMA MİKROSKOBU" },
  { id: 68, code: "253.3.6.6.2-55", name: "OPTİK MİKROSKOPLAR", brand: "ZEISS", model: "STEMİ 508", type: "STEREO KAMERA MİKROSKOP" },
  { id: 69, code: "253.3.6.6.2-56", name: "OPTİK MİKROSKOPLAR", brand: "NİKON", model: "ECLİPS 80İ", type: "TRİNOKÜLER ARAŞTIRMA MİKROSKOPU" },
  { id: 70, code: "253.3.6.99.1.1-321", name: "DİĞER LABORATUVAR CİHAZ VE ALETLERİ", brand: "ULTİMAKER 2-GO EXTENDED", model: "", type: "3D YAZICI" },
  { id: 71, code: "253.3.6.99.1.1-448", name: "DİĞER LABORATUVAR CİHAZ VE ALETLERİ", brand: "AXOLOTL BIOSYSTEMS", model: "AXO C3", type: "BİYOBASIM CİHAZI" },
  { id: 72, code: "255.99.1.3-16", name: "KABİN", brand: "METİSAFE", model: "CLASS II", type: "BİYOLOJİK GÜVENLİK KABİNİ" },
  { id: 73, code: "255.99.1.3-17", name: "KABİN", brand: "MİKROTEST", model: "MGK 120 CLASS II", type: "BİYOLOJİK GÜVENLİK KABİNİ" },
  { id: 74, code: "255.99.1.3-18", name: "KABİN", brand: "THERMO SCIENTIFIC", model: "MSC ADVANTAGE CLASS II", type: "BİYOLOJİK GÜVENLİK KABİNİ" },
  { id: 75, code: "255.1.5.16.1-24", name: "DERİN DONDURUCULAR", brand: "THERMO SCIENTIFIC", model: "TDE40086FV", type: "-86 DERECE ULTRA DERİN DONDURUCU" },
  { id: 76, code: "255.1.5.16.1-25", name: "DERİN DONDURUCULAR", brand: "MELİNG", model: "", type: "-164 DERECE KRİYOJENİK DONDURUCU" },
  { id: 77, code: "255.1.5.16.1-26", name: "DERİN DONDURUCULAR", brand: "UĞUR", model: "UED SERİSİ", type: "ÇEKMECELİ DERİN DONDURUCU" }
];

  const sortData = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const getSortedData = () => {
    if (!sortConfig.key) return equipment;

    return [...equipment].sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === 'asc' ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === 'asc' ? 1 : -1;
      }
      return 0;
    });
  };

  const filteredData = getSortedData().filter(item =>
    Object.values(item).some(value =>
      value.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  return (
    <div className="lab-equipment-list">
      <div className="search-container">
        <input
          type="text"
          placeholder="Cihaz ara..."
          className="search-input"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th onClick={() => sortData('code')}>
                Taşınır Kodu {sortConfig.key === 'code' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
              </th>
              <th onClick={() => sortData('name')}>
                Adı {sortConfig.key === 'name' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
              </th>
              <th onClick={() => sortData('brand')}>
                Markası {sortConfig.key === 'brand' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
              </th>
              <th onClick={() => sortData('model')}>
                Modeli {sortConfig.key === 'model' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
              </th>
              <th onClick={() => sortData('type')}>
                Cinsi {sortConfig.key === 'type' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((item) => (
              <tr key={item.id}>
                <td>{item.code}</td>
                <td>{item.name}</td>
                <td>{item.brand}</td>
                <td>{item.model}</td>
                <td>{item.type}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default LabEquipmentList;