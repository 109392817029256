import React, { useState } from 'react';
import './DeviceCalendar.css';

const ViewTypes = {
  MONTH: 'ay',
  WEEK: 'hafta',
  DAY: 'gün'
};

const weekDays = ['Pzt', 'Sal', 'Çar', 'Per', 'Cum', 'Cmt', 'Paz'];
const weekDaysFull = ['Pazartesi', 'Salı', 'Çarşamba', 'Perşembe', 'Cuma', 'Cumartesi', 'Pazar'];
const hours = Array.from({ length: 24 }, (_, i) => `${i.toString().padStart(2, '0')}:00`);

const DeviceCalendar = ({ selectedDevice }) => {
  // State tanımlamaları
  const [currentDate, setCurrentDate] = useState(new Date());
  const [currentView, setCurrentView] = useState(ViewTypes.WEEK);

  // Tarih işlemleri için yardımcı fonksiyonlar
  const getDaysInMonth = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth();
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    const firstDay = new Date(year, month, 1).getDay();
    
    const days = [];
    
    // Önceki ayın son günleri
    const prevMonthDays = new Date(year, month, 0).getDate();
    for (let i = firstDay - 1; i >= 0; i--) {
      days.push({
        day: prevMonthDays - i,
        isCurrentMonth: false
      });
    }
    
    // Bu ayın günleri
    for (let i = 1; i <= daysInMonth; i++) {
      days.push({
        day: i,
        isCurrentMonth: true
      });
    }
    
    // Sonraki ayın ilk günleri
    const remainingDays = 42 - days.length; // 6 satır x 7 gün
    for (let i = 1; i <= remainingDays; i++) {
      days.push({
        day: i,
        isCurrentMonth: false
      });
    }
    
    return days;
  };

  // Navigasyon fonksiyonları
  const goToPreviousPeriod = () => {
    const newDate = new Date(currentDate);
    switch (currentView) {
      case ViewTypes.MONTH:
        newDate.setMonth(currentDate.getMonth() - 1);
        break;
      case ViewTypes.WEEK:
        newDate.setDate(currentDate.getDate() - 7);
        break;
      case ViewTypes.DAY:
        newDate.setDate(currentDate.getDate() - 1);
        break;
      default:
        break;
    }
    setCurrentDate(newDate);
  };

  const goToNextPeriod = () => {
    const newDate = new Date(currentDate);
    switch (currentView) {
      case ViewTypes.MONTH:
        newDate.setMonth(currentDate.getMonth() + 1);
        break;
      case ViewTypes.WEEK:
        newDate.setDate(currentDate.getDate() + 7);
        break;
      case ViewTypes.DAY:
        newDate.setDate(currentDate.getDate() + 1);
        break;
      default:
        break;
    }
    setCurrentDate(newDate);
  };

  const goToToday = () => {
    setCurrentDate(new Date());
  };

  const formatDate = (date) => {
    return date.toLocaleDateString('tr-TR', { 
      year: 'numeric',
      month: 'long'
    });
  };

  // View render fonksiyonları
  const renderMonthView = () => {
    const daysInMonth = getDaysInMonth(currentDate);

    return (
      <div className="view-content month-view">
        <div className="weekdays">
          {weekDays.map(day => (
            <div key={day}>{day}</div>
          ))}
        </div>
        <div className="days">
          {daysInMonth.map((date, index) => {
            const isToday = date.isCurrentMonth && 
              date.day === new Date().getDate() && 
              currentDate.getMonth() === new Date().getMonth();

            return (
              <div
                key={index}
                className={`day ${!date.isCurrentMonth ? 'other-month' : ''} 
                  ${isToday ? 'today' : ''}`}
              >
                {date.day}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const renderWeekView = () => {
    return (
      <div className="view-content week-view">
        <div className="week-header">
          <div className="time-label"></div>
          {weekDaysFull.map(day => (
            <div key={day} className="week-day">
              {day}
            </div>
          ))}
        </div>
        <div className="week-body">
          {hours.map(hour => (
            <div key={hour} className="time-row">
              <div className="time-label">{hour}</div>
              {weekDaysFull.map(day => (
                <div key={`${day}-${hour}`} className="time-cell"></div>
              ))}
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderDayView = () => {
    return (
      <div className="view-content day-view">
        <div className="day-header">
          <h3>
            {currentDate.toLocaleDateString('tr-TR', { 
              weekday: 'long', 
              day: 'numeric', 
              month: 'long' 
            })}
          </h3>
        </div>
        <div className="time-slots">
          {hours.map(hour => (
            <div key={hour} className="time-slot">
              <div className="time-label">{hour}</div>
              <div className="time-content"></div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className="calendar-view">
      <div className="calendar-header">
        <div className="calendar-navigation">
          <button 
            className="nav-button"
            onClick={goToPreviousPeriod}
            aria-label="Önceki"
          >
            ←
          </button>
          <h2 className="current-month">{formatDate(currentDate)}</h2>
          <button 
            className="nav-button"
            onClick={goToNextPeriod}
            aria-label="Sonraki"
          >
            →
          </button>
        </div>
        <div className="calendar-controls">
          <button 
            className="today-button"
            onClick={goToToday}
          >
            Bugün
          </button>
          <div className="view-selector">
            <button 
              className={`view-button ${currentView === ViewTypes.MONTH ? 'active' : ''}`}
              onClick={() => setCurrentView(ViewTypes.MONTH)}
            >
              Ay
            </button>
            <button 
              className={`view-button ${currentView === ViewTypes.WEEK ? 'active' : ''}`}
              onClick={() => setCurrentView(ViewTypes.WEEK)}
            >
              Hafta
            </button>
            <button 
              className={`view-button ${currentView === ViewTypes.DAY ? 'active' : ''}`}
              onClick={() => setCurrentView(ViewTypes.DAY)}
            >
              Gün
            </button>
          </div>
        </div>
      </div>

      {currentView === ViewTypes.MONTH && renderMonthView()}
      {currentView === ViewTypes.WEEK && renderWeekView()}
      {currentView === ViewTypes.DAY && renderDayView()}
    </div>
  );
};

export default DeviceCalendar;