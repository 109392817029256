import React, { useState } from 'react';
import { Printer, FileSpreadsheet } from 'lucide-react';
import './Sharing.css';

const Sharing = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [searchTerm, setSearchTerm] = useState('');

  const pageSizeOptions = [5, 20, 50, 100, 'Tümü'];

  // CSV indirme fonksiyonu - UTF-8 encoding ile
  const handleCsvExport = () => {
    const headers = ['#', 'Başlık'];
    // UTF-8 BOM
    const BOM = '\uFEFF';
    
    const csvContent = [
      headers.join(';'), // Türkçe Excel için virgül yerine noktalı virgül kullanıyoruz
      // Örnek veri satırları eklenebilir
      // '1;Örnek Başlık 1',
      // '2;Türkçe Karakterli İçerik'
    ].join('\n');

    const blob = new Blob([BOM + csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', 'paylasim_merkezi.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  const handlePrint = () => {
    window.print();
  };

  return (
    <div className="kuyam_sharing_wrapper">
      <div className="kuyam_sharing_header">
        <h1 className="kuyam_sharing_title">Paylaşım Merkezi</h1>
        
        <div className="kuyam_sharing_buttons">
          <button 
            className="kuyam_sharing_print_btn"
            onClick={handlePrint}
            aria-label="Yazdır"
          >
            <Printer className="kuyam_sharing_btn_icon" />
            Yazdır
          </button>
          <button 
            className="kuyam_sharing_csv_btn"
            onClick={handleCsvExport}
            aria-label="CSV Çıkar"
          >
            <FileSpreadsheet className="kuyam_sharing_btn_icon" />
            CSV Çıkar
          </button>
        </div>
      </div>

      <div className="kuyam_sharing_table_wrapper">
        <table className="kuyam_sharing_table">
          <thead>
            <tr>
              <th>#</th>
              <th>Başlık</th>
            </tr>
          </thead>
          <tbody>
            <tr className="kuyam_sharing_empty_row">
              <td colSpan="2">Veri bulunmadı.</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="kuyam_sharing_pagination">
        <div className="kuyam_sharing_page_numbers">
          {pageSizeOptions.map((size) => (
            <button
              key={size}
              className={`kuyam_sharing_page_btn ${itemsPerPage === size ? 'kuyam_sharing_page_btn_active' : ''}`}
              onClick={() => setItemsPerPage(size)}
            >
              {size}
            </button>
          ))}
        </div>
        
        <div className="kuyam_sharing_search">
          <input
            type="text"
            placeholder="Ara"
            className="kuyam_sharing_search_input"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </div>
    </div>
  );
};

export default Sharing;