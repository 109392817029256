import React from 'react';
import './CurrentOffers.css';

const CurrentOffers = () => {
  const mockData = [
    // Örnek veri - gerçek uygulamada API'den gelecek
  ];

  return (
    <div className="current-offers">
      <h1 className="page-title">Mevcut Fiyat/Analiz Teklifleri</h1>
      
      <div className="pagination-info">
        <span>Gösterilen kayıtlar 0-100</span>
        <div className="pagination-buttons">
          <button className="pagination-button">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <polyline points="15 18 9 12 15 6"></polyline>
            </svg>
          </button>
          <button className="pagination-button">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <polyline points="9 18 15 12 9 6"></polyline>
            </svg>
          </button>
        </div>
      </div>

      <div className="table-container">
        <table className="offers-table">
          <thead>
            <tr>
              <th>Teklif No</th>
              <th>Tarih</th>
              <th>Müşteri Adı</th>
              <th>Birim</th>
              <th>Toplam</th>
              <th>Teklif Durumu</th>
              <th>İşlemler</th>
            </tr>
          </thead>
          <tbody>
            {mockData.length === 0 && (
              <tr>
                <td colSpan="7" className="empty-message">
                  Henüz kayıt bulunmamaktadır.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CurrentOffers;