// src/pages/kuyam-basvuru/dashboard/components/DashboardNavbar.jsx
import React, { useState } from 'react';
import kuyamLogo from '../../../../assets/kuyam_logo.png';
import ksbuLogo from '../../../../assets/ksbu_logo.png';
import trFlag from '../../../../assets/tr.png';
import enFlag from '../../../../assets/en.png';
import './DashboardNavbar.css';

const DashboardNavbar = () => {
  const [currentLang, setCurrentLang] = useState('tr');

  const toggleLanguage = () => {
    setCurrentLang(currentLang === 'tr' ? 'en' : 'tr');
  };

  return (
    <nav className="dashboard-navbar">
      <div className="dashboard-navbar-container">
        <div className="dashboard-navbar-content">
          <div className="dashboard-navbar-left">
            <div className="dashboard-brand-container">
              <img 
                src={kuyamLogo} 
                alt="KUYAM Logo" 
                className="dashboard-kuyam-logo"
              />
            </div>
          </div>
          <div className="dashboard-navbar-right">
            <button 
              className="dashboard-language-button"
              onClick={toggleLanguage}
              aria-label={currentLang === 'tr' ? 'Switch to English' : 'Türkçe\'ye geç'}
            >
              <img 
                src={currentLang === 'tr' ? trFlag : enFlag} 
                alt={currentLang === 'tr' ? 'Türkçe' : 'English'} 
                className="dashboard-language-image"
              />
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default DashboardNavbar;