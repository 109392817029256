// NavBar.js
import React, { useState, useEffect } from "react";
import { FileSpreadsheet, FileCheck } from 'lucide-react';
import { FaFileMedical, FaShieldAlt, FaHeartbeat, FaGlobe } from 'react-icons/fa';
import ToastMenu from "../ToastMenu/ToastMenu";
import { Link, useNavigate } from "react-router-dom";
import logo from "./logo-1.png";
import "./NavBar.css";

function NavBar() {
  const navigate = useNavigate();
  const [showMerkezimiz, setShowMerkezimiz] = useState(false);
  const [showYonetim, setShowYonetim] = useState(false);
  const [showIsAkisSurecleri, setShowIsAkisSurecleri] = useState(false);
  const [showLaboratuvarlar, setShowLaboratuvarlar] = useState(false);
  const [showArastirmalar, setShowArastirmalar] = useState(false);
  const [currentText, setCurrentText] = useState("");
  const [isTyping, setIsTyping] = useState(true);
  const texts = ["Test", "Laboratuvar", "Online", "Analiz"];
  
  // Diğer state tanımlamaları ile birlikte
  const [showReports, setShowReports] = useState(false); // Yeni eklenen state

  useEffect(() => {
    let currentIndex = 0;
    const typeNextText = () => {
      setIsTyping(false);
      setTimeout(() => {
        setCurrentText(texts[currentIndex]);
        setIsTyping(true);
        currentIndex = (currentIndex + 1) % texts.length;
      }, 100);
    };
    typeNextText();
    const interval = setInterval(typeNextText, 3000);
    return () => clearInterval(interval);
  }, []);

  const [showForms, setShowForms] = useState(false);

  const handleHomeClick = () => {
    navigate('/');
    window.location.reload(); // Sayfayı yenile
  };

  const handleRegulationsClick = (e) => {
    e.preventDefault();
    setShowMerkezimiz(false);
    navigate('/merkezimiz/yonetmelik');
  };

  const handlePurposeClick = (e) => {
    e.preventDefault();
    setShowMerkezimiz(false);
    navigate('/merkezimiz/kurulus-amacimiz');
  };

  const handleMissionVisionClick = (e) => {
    e.preventDefault();
    setShowMerkezimiz(false);
    navigate('/merkezimiz/misyon-vizyon');
  };

  const handleTeamClick = (e) => {
    e.preventDefault();
    setShowMerkezimiz(false);
    navigate('/merkezimiz/calisanlarimiz');
  };

  const handleVideosClick = (e) => {
    e.preventDefault();
    setShowMerkezimiz(false);
    navigate('/merkezimiz/tanitim-videolari');
  };

  const handleKVKKClick = (e) => {
    e.preventDefault();
    setShowMerkezimiz(false);
    navigate('/merkezimiz/kvkk');
  };

  const handleAnalysisProcessClick = (e) => {
    e.preventDefault();
    setShowIsAkisSurecleri(false);
    setShowMerkezimiz(false);
    navigate('/merkezimiz/analiz-basvuru-sureci');
  };

  const handleLabEquipmentClick = (e) => {
    e.preventDefault();
    setShowLaboratuvarlar(false);
    navigate('/laboratuvar-cihazlari');
  };

  const handleServiceChargesClick = (e) => {
    e.preventDefault();
    setShowLaboratuvarlar(false);
    navigate('/hizmet-bedelleri');
  };

  const handleApplyClick = () => {
    window.open('/kuyam-basvuru', '_blank');
  };

  const handleInfrastructureClick = (e) => {
    e.preventDefault();
    setShowArastirmalar(false);
    navigate('/arastirmalar/altyapi-projelerimiz');
  };

  const handleTrainingsClick = (e) => {
    e.preventDefault();
    setShowArastirmalar(false);
    navigate('/arastirmalar/egitimler-seminerler');
  };

  const handleInternshipProceduresClick = (e) => {
    e.preventDefault();
    setShowArastirmalar(false);
    navigate('/arastirmalar/staj-islemleri');
  };

  // 2024-2025 raporu için
  const handle2024Report = (e) => {
    e.preventDefault();
    const reportUrl = `${window.location.origin}/arastirmalar/reports/2024`;
    window.open(reportUrl, '_blank', 'noopener,noreferrer');
    setShowReports(false);
  };

  // 2023-2024 raporu için 
  const handle2023Report = (e) => {
    e.preventDefault();
    const reportUrl = `${window.location.origin}/arastirmalar/reports/2023`;
    window.open(reportUrl, '_blank', 'noopener,noreferrer');
    setShowReports(false);
  };

  // 2022-2023 raporu için
  const handle2022Report = (e) => {
    e.preventDefault();
    const reportUrl = `${window.location.origin}/arastirmalar/reports/2022`; 
    window.open(reportUrl, '_blank', 'noopener,noreferrer');
    setShowReports(false);
  };

  // 2021-2022 raporu için
  const handle2021Report = (e) => {
    e.preventDefault();
    const reportUrl = `${window.location.origin}/arastirmalar/reports/2021`;
    window.open(reportUrl, '_blank', 'noopener,noreferrer');
    setShowReports(false);
  };

  // 2020-2021 raporu için
  const handle2020Report = (e) => {
    e.preventDefault();
    const reportUrl = `${window.location.origin}/arastirmalar/reports/2020`;
    window.open(reportUrl, '_blank', 'noopener,noreferrer'); 
    setShowReports(false);
  };

  const handleBoardClick = (e) => {
    e.preventDefault();
    setShowYonetim(false);
    navigate('/yonetim/yonetim-kurulu');
  };

  const handleAdvisoryBoardClick = (e) => {
    e.preventDefault();
    setShowYonetim(false);
    navigate('/yonetim/danisma-kurulu');
  };

  const handleManagementClick = (e) => {
    e.preventDefault();
    setShowYonetim(false);
    navigate('/yonetim');
  };

  const handleOrgChartClick = (e) => {
    e.preventDefault();
    setShowYonetim(false);
    navigate('/yonetim/organizasyon-semasi');
  };

  return (
    <div className="navbar">
      <div className="left">
        <img 
          src={logo} 
          alt="KSBÜ Logo" 
          className="logo" 
          onClick={handleHomeClick}
          style={{ cursor: 'pointer' }}
        />
        <div className="textContainer">
          <span className="iltemText">KUYAM</span>
          <span className="subText">Uygulama ve Araştırma Merkezi</span>
          <div className="flex space-x-4">
            <a 
              href="https://bapsis.ksbu.edu.tr/Default2.aspx" 
              target="_blank" 
              rel="noopener noreferrer"
              className="group relative"
            >
              <FileSpreadsheet size={30} className="hover:text-blue-600 transition-colors" />
              <span className="absolute left-1/2 transform -translate-x-1/2 mt-2 w-max text-sm bg-gray-800 text-white px-2 py-1 rounded opacity-0 group-hover:opacity-100">
                Bapsis
              </span>
            </a>

            <a 
              href="https://hadyek.ksbu.edu.tr/defaultx" 
              target="_blank" 
              rel="noopener noreferrer"
              className="group relative"
            >
              <FaFileMedical size={30} className="hover:text-blue-600 transition-colors" />
              <span className="absolute left-1/2 transform -translate-x-1/2 mt-2 w-max text-sm bg-gray-800 text-white px-2 py-1 rounded opacity-0 group-hover:opacity-100">
                Hayvan Deneyleri Yerel Etik Kurulu
              </span>
            </a>

            <a 
              href="https://etikgirolmayan.ksbu.edu.tr/defaultx" 
              target="_blank" 
              rel="noopener noreferrer"
              className="group relative"
            >
              <FaShieldAlt size={30} className="hover:text-blue-600 transition-colors" />
              <span className="absolute left-1/2 transform -translate-x-1/2 mt-2 w-max text-sm bg-gray-800 text-white px-2 py-1 rounded opacity-0 group-hover:opacity-100">
                Girişimsel Olmayan Klinik Araştırmalar Etik Kurulu
              </span>
            </a>

            <a 
              href="https://kliniketik.ksbu.edu.tr/defaultx" 
              target="_blank" 
              rel="noopener noreferrer"
              className="group relative"
            >
              <FaHeartbeat size={30} className="hover:text-blue-600 transition-colors" />
              <span className="absolute left-1/2 transform -translate-x-1/2 mt-2 w-max text-sm bg-gray-800 text-white px-2 py-1 rounded opacity-0 group-hover:opacity-100">
                Klinik Araştırmalar Etik Kurulu
              </span>
            </a>

            <a 
              href="https://tto.ksbu.edu.tr/" 
              target="_blank" 
              rel="noopener noreferrer"
              className="group relative"
            >
              <FaGlobe size={30} className="hover:text-blue-600 transition-colors" />
              <span className="absolute left-1/2 transform -translate-x-1/2 mt-2 w-max text-sm bg-gray-800 text-white px-2 py-1 rounded opacity-0 group-hover:opacity-100">
                TTO
              </span>
            </a>
          </div>
        </div>
      </div>
      <ToastMenu />
      <div className="right">
        <div className="button-container">
          <div className="typewriter-container">
            <div className={`typewriter ${isTyping ? 'typing' : ''}`}>
              {currentText}
            </div>
          </div>
          <button 
            className="applyButton" 
            onClick={handleApplyClick}
          >
            BAŞVURU
          </button>
        </div>
      </div>
    </div>
  );
}

export default NavBar;