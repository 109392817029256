import React from 'react';
import './Home.css';
const Home = () => {
  return (
    <div>
      <h1 className="page-title">Geçmiş Analiz Taleplerim ve Analiz Sonuçlarım</h1>
      <div className="filter-container">
        <span className="filter-label">Analiz Durumuna Göre filtreler</span>
        <div className="filter-buttons">
          <button className="filter-button primary">Tümü</button>
          <button className="filter-button success">Raporlandı 0</button>
        </div>
      </div>
      <div className="search-form">
        <div className="form-grids">
          <div className="form-group">
            <label className="form-label">Teklif No:</label>
            <input type="text" className="form-input" />
          </div>
          <div className="form-group">
            <label className="form-label">Tarih:</label>
            <input type="text" className="form-input" placeholder="11 / 11 / 2024" />
          </div>
          <div className="form-group">
            <label className="form-label">Numune Kodu:</label>
            <input type="text" className="form-input" />
          </div>
          <div className="form-group">
            <label className="form-label">Analiz:</label>
            <input type="text" className="form-input" />
          </div>
          <button className="home-list-button">Listele</button>
        </div>
      </div>
      <div className="table-container">
        <table className="data-table">
          <thead className="table-header">
            <tr>
              <th>#</th>
              <th>Teklif No</th>
              <th>Tarih</th>
              <th>Adı Soyadı</th>
              <th>Numune Kodu</th>
              <th>Analiz</th>
              <th>Durum</th>
              <th>Bilgiler</th>
            </tr>
          </thead>
          <tbody className="table-body">
            <tr>
              <td colSpan="8" className="empty-message">
                Henüz kayıt bulunmamaktadır.
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default Home;