// src/TopBanner.js
import React from "react";
import "./TopBanner.css";

function TopBanner() {
  return (
    <div className="banner">
      <span className="banner-text">
        KÜTAHYA SAĞLIK BİLİMLERİ ÜNIVERSITESİ MERKEZ KAMPÜS, KÜTAHYA / TÜRKIYE
      </span>
      <div className="language">
        <img
          src="https://upload.wikimedia.org/wikipedia/en/a/ae/Flag_of_the_United_Kingdom.svg"
          alt="UK Flag"
          className="flag-icon"
        />
        ENGLISH
      </div>
    </div>
  );
}

export default TopBanner;
