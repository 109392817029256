// src/pages/Team/Team.js
import React from 'react';
import './Team.css';

const Team = () => {
    const contactInfo = {
        address: "Kütahya Sağlık Bilimleri Üniversitesi Evliya Çelebi Yerleşkesi Tavşanlı Yolu 10. km KÜTAHYA",
        phone: "0 (274) 260 00 43",
        email: "kuyam@ksbu.edu.tr"
    };

    return (
        <div className="team-container">
            <div className="team-header">
                <h1>Ekibimiz</h1>
                <h2>Uygulama ve Araştırma Merkezi</h2>
            </div>

            <div className="team-content">
                <div className="contact-info">
                    <div className="contact-item">
                        <span className="contact-icon">📍</span>
                        <span>{contactInfo.address}</span>
                    </div>
                    <div className="contact-item">
                        <span className="contact-icon">📞</span>
                        <span>{contactInfo.phone}</span>
                    </div>
                    <div className="contact-item">
                        <span className="contact-icon">✉️</span>
                        <span>{contactInfo.email}</span>
                    </div>
                </div>

                <div className="team-members">
                    {/* Ekip üyeleri eklendiğinde buraya eklenecek */}
                    <div className="team-placeholder">
                        <p>Ekip bilgileri güncellenecektir.</p>
                    </div>
                </div>
            </div>

            <div className="update-info">
                Son Güncelleme Tarihi: 01 Ağustos 2024, Perşembe
            </div>
        </div>
    );
};

export default Team;