// src/pages/MissionVision/MissionVision.js
import React from 'react';
import './MissionVision.css';

const MissionVision = () => {
    return (
        <div className="mission-vision-container">
            <div className="mission-section">
                <h1>Misyon</h1>
                <p className="mission-intro">
                    Kütahya Sağlık Bilimleri Üniversitesi Rektörlüğü bünyesinde kurulan Uygulama ve Araştırma Merkezi, 
                    nitelikli personeli ve gelişen altyapı ve laboratuvar olanakları ile alanında kabul gören nitelik ve 
                    kalite standartlarına ulaşmak için sürekli gelişme anlayışını benimsemiştir.
                </p>
                
                <h2>Merkezimiz;</h2>
                <ul className="mission-list">
                    <li>Başta üniversitemiz akademik personeli olmak üzere bölge üniversitelerden gelen araştırmacılara, 
                        lisans, yüksek lisans ve doktora öğrencilerine verimli ve etkin bir çalışma ortamı oluşturmayı,</li>
                    <li>Üniversite dışında bölgemizde faaliyet gösteren sanayi, kamu kurum ve kuruluşları ile üçüncü 
                        şahısların analiz ihtiyaçlarını karşılamak için merkezin olanakları ölçüsünde belli bir ücret 
                        karşılığında hizmet vermeyi,</li>
                    <li>Araştırma ve Geliştirme (AR-GE) faaliyetlerinin yürütülmesi için araştırma ve analiz merkezleri 
                        oluşturarak cihaz ve laboratuvar alt yapısı ihtiyaçlarını karşılamayı,</li>
                    <li>Disiplinler arası ve kurumlar arası ortak projeler üreterek bilimde yeni ufuk açan araştırmalar 
                        gerçekleştirmeyi,</li>
                    <li>Bünyesindeki araştırmacıları ile bilimsel yayınlar üreterek bilim literatürüne katkı sağlamak 
                        ve gerek yurtiçinde gerekse yurtdışında düzenlenen bilimsel içerikli kongre, sempozyum vb. 
                        bilimsel etkinliklere katılarak üniversitemizin buralarda temsilini sağlamayı,</li>
                    <li>Merkezimiz bünyesindeki Proje Destek Ofisi ile akademisyenlerimize BAP, TUBİTAK, KOSGEB, vb. 
                        alanlarda proje yazmaları hususunda yardımcı olmayı,</li>
                    <li>Bilim ve teknoloji alanında toplumsal ihtiyaçlar çerçevesinde yeni yöntem ve teknikler 
                        geliştirmeyi kendisine görev edinmiştir.</li>
                </ul>
            </div>

            <div className="vision-section">
                <h1>Vizyon</h1>
                <h2>Kütahya Sağlık Bilimleri Üniversitesi Uygulama ve Araştırma Merkezi,</h2>
                <ul className="vision-list">
                    <li>Bilim ve teknolojinin gelişmesi için gerekli ileri düzeyde araştırmalara olanak tanıyan 
                        sürdürülebilir altyapılar kurmayı,</li>
                    <li>Ar-Ge faaliyetlerinde ulusal ve uluslararası işbirliklerini geliştirerek ülkemiz 
                        kalkınmasının hızlandırılmasına katkıda bulunmayı,</li>
                    <li>Üniversitemizin bilimsel literatürde saygınlığını arttırmayı ve daha üst seviyelere taşımayı,</li>
                    <li>Üniversitemizin akademik birikimine katkı sunmayı, bölge kaynaklarının etkin kullanımında 
                        rol oynamayı, katma değeri yüksek ürünlere yönelik bilgi üretmeyi,</li>
                    <li>Merkeze bağlı laboratuvarlarda teknik eleman istihdam etmek, görevli olan nitelikli 
                        elemanların yurtiçinde ve gerektiğinde yurtdışında ortak çalışmaların yapılmasını sağlayan 
                        seçkin bir merkez olmayı kendine hedef edinmiştir.</li>
                </ul>
            </div>

            <div className="update-info">
                Son Güncelleme Tarihi: 02 Nisan 2021, Cuma
            </div>
        </div>
    );
};

export default MissionVision;