import React, { useState } from 'react';
import { Search, FileText, Trash2, FilePlus } from 'lucide-react';
import SearchAnalysisModal from './SearchAnalysisModal';
import CreateRequestModal from './CreateRequestModal';
import './NewOffers.css';

const NewOffers = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filterStatus, setFilterStatus] = useState('all');
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
  const [isRequestModalOpen, setIsRequestModalOpen] = useState(false);
  
  const offers = [
    { 
      id: 1045, 
      date: "2024-03-14",
      status: "draft",
      amount: "₺0.00",
      department: "KÜTAHYA SAĞLIK BİLİMLERİ ÜNİVERSİTESİ UYGULAMA VE ARAŞTIRMA MERKEZİ (KUYAM)",
      requestor: "Önder Öztürk"
    },
    { 
      id: 989, 
      date: "2024-03-11",
      status: "pending",
      amount: "₺0.00",
      department: "KÜTAHYA SAĞLIK BİLİMLERİ ÜNİVERSİTESİ UYGULAMA VE ARAŞTIRMA MERKEZİ (KUYAM)",
      requestor: "Önder Öztürk"
    }
  ];

  // Arama ve filtreleme fonksiyonu
  const filteredOffers = offers.filter(offer => {
    const matchesSearch = 
      offer.id.toString().includes(searchTerm.toLowerCase()) ||
      offer.department.toLowerCase().includes(searchTerm.toLowerCase()) ||
      offer.requestor.toLowerCase().includes(searchTerm.toLowerCase());
    
    const matchesStatus = filterStatus === 'all' || offer.status === filterStatus;
    
    return matchesSearch && matchesStatus;
  });

  const handleDelete = (id) => {
    // Silme işlemi için API çağrısı yapılacak
    console.log('Silinecek talep ID:', id);
  };

  const handleView = (id) => {
    // Görüntüleme sayfasına yönlendirme yapılacak
    console.log('Görüntülenecek talep ID:', id);
  };

  return (
    <div className="new-offers-container">
      {/* Başlık ve Butonlar */}
      <div className="page-header">
        <h1 className="page-title">Talepler Listesi</h1>
        <div className="header-buttons">
          <button 
            className="btn btn-success"
            onClick={() => setIsRequestModalOpen(true)}
          >
            <FilePlus className="h-5 w-5" />
            <span>Talep Oluştur</span>
          </button>
          <button 
            className="btn btn-primary"
            onClick={() => setIsSearchModalOpen(true)}
          >
            <Search className="h-5 w-5" />
            <span>Analiz Aramak için Tıklayın</span>
          </button>
        </div>
      </div>

      {/* Gösterilen Kayıt Sayısı */}
      <div className="records-info">
        Gösterilen kayıtlar 0-100
      </div>

      {/* Teklif Listesi */}
      <div className="table-container">
        <table className="offers-table">
          <thead>
            <tr>
              <th>Talep No</th>
              <th>Tarih</th>
              <th>Adı Soyadı</th>
              <th>Birim</th>
              <th>Tutar</th>
              <th>İstek Durumu</th>
              <th className="text-center">İşlemler</th>
            </tr>
          </thead>
          <tbody>
            {filteredOffers.length > 0 ? (
              filteredOffers.map((offer) => (
                <tr key={offer.id}>
                  <td>
                    <div className="title-cell">
                      <FileText className="title-icon" size={20} />
                      <span>{offer.id}</span>
                    </div>
                  </td>
                  <td>{new Date(offer.date).toLocaleDateString('tr-TR')}</td>
                  <td>{offer.requestor}</td>
                  <td>{offer.department}</td>
                  <td>{offer.amount}</td>
                  <td>
                    <span className={`status-badge ${
                      offer.status === 'draft' ? 'status-draft' : 'status-pending'
                    }`}>
                      {offer.status === 'draft' ? 'Taslak' : 'Bekliyor'}
                    </span>
                  </td>
                  <td>
                    <div className="action-buttons">
                      <button 
                        className="action-button btn-view"
                        onClick={() => handleView(offer.id)}
                        title="Görüntüle"
                      >
                        <Search className="view-icon" size={16} />
                      </button>
                      <button 
                        className="action-button btn-delete"
                        onClick={() => handleDelete(offer.id)}
                        title="Sil"
                      >
                        <Trash2 className="delete-icon" size={16} />
                      </button>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="7" className="empty-state">
                  {searchTerm || filterStatus !== 'all' 
                    ? 'Arama kriterlerine uygun talep bulunamadı.'
                    : 'Henüz talep bulunmuyor.'}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* Sayfalama */}
      <div className="pagination">
        <button className="btn btn-previous" disabled>
          ←
        </button>
        <button className="btn btn-next">
          →
        </button>
      </div>

      {/* Analiz Arama Modalı */}
      <SearchAnalysisModal 
        isOpen={isSearchModalOpen}
        onClose={() => setIsSearchModalOpen(false)}
      />

      {/* Talep Oluşturma Modalı */}
      <CreateRequestModal 
        isOpen={isRequestModalOpen}
        onClose={() => setIsRequestModalOpen(false)}
      />
    </div>
  );
};

export default NewOffers;