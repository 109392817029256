import React, { useState, useEffect } from 'react';
import './Announcements.css';

const Announcements = () => {
    const allItems = [
        // Sertifikalı Kurslar
        {
            type: 'course',
            date: '22.02.2023',
            title: 'Uygulamalı Flow Sitometri Kursu'
        },
        {
            type: 'course',
            date: '22.02.2023',
            title: 'Ultrasantrifüj Eğitimi'
        },
        {
            type: 'course',
            date: '17.10.2021',
            title: 'Tübitak 2237/B Sağlık ve Biyoteknoloji Alanında Araştırma Projeleri Hazırlama'
        },
        {
            type: 'course',
            date: '27.01.2021',
            title: 'Uygulamalı Western Blot Kursu'
        },
        // Öğrenci Ziyaretleri
        {
            type: 'student',
            date: '20.05.2019',
            title: 'İstek İlkokulları Öğrenci Grubu'
        },
        {
            type: 'student',
            date: '20.05.2019',
            title: 'DPU Biyokimya Bölümü Öğrenci Grubu'
        },
        {
            type: 'student',
            date: '20.05.2019',
            title: 'Tavşanlı Bilsem Öğrenci Grubu'
        },
        // Kurum Ziyaretleri
        {
            type: 'institution',
            date: '16.09.2021',
            title: 'Bilecik Şeyh Edebali Üniversitesi Merkezi Araştırma Laboratuvarı'
        },
        {
            type: 'institution',
            title: 'Bezmialem'
        },
        {
            type: 'institution',
            title: 'KUTTAM'
        },
        {
            type: 'institution',
            title: 'Arum'
        },
        {
            type: 'institution',
            title: 'Anadolu Üniversitesi TTO'
        },
        // Duyurular
        {
            type: 'announcement',
            date: 'Nisan 2, 2020',
            title: 'Korona Tedbirleri Kapsamında Alınan Önlemler'
        },
        {
            type: 'announcement',
            date: 'Ocak 29, 2019',
            title: 'Otomasyon Sistemi Kullanım Kılavuzu'
        },
        {
            type: 'announcement',
            date: 'Ocak 29, 2019',
            title: 'Merkezimizle İletişime Geçilmesi Gereken Laboratuvarlar'
        }
    ];

    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % allItems.length);
        }, 3000);

        return () => clearInterval(timer);
    }, [allItems.length]);

    return (
        <div className="announcements-content">
            <h2 className="column-title">Duyurular</h2>
            <div className="scrolling-content">
                <div 
                    className="scroll-items" 
                    style={{ transform: `translateY(-${currentIndex * 50}px)` }}
                >
                    {allItems.map((item, index) => (
                        <div 
                            key={index} 
                            className={`scroll-item ${item.type} ${index === currentIndex ? 'active' : ''}`}
                        >
                            <div className="item-content">
                                <span className="item-type">
                                    {item.type === 'course' && '• Kurs'}
                                    {item.type === 'student' && '• Öğrenci Ziyareti'}
                                    {item.type === 'institution' && '• Kurum Ziyareti'}
                                    {item.type === 'announcement' && '• Duyuru'}
                                </span>
                                <div className="item-title">{item.title}</div>
                                {item.date && <span className="item-date">{item.date}</span>}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="announcements-footer">
                <a href="/duyurular" className="all-announcements-link">Tüm Duyurular</a>
            </div>
        </div>
    );
};

export default Announcements;