import React from 'react';
import './BiyoInovatifLab.css';

function BiyoInovatifLab() {
    return (
        <div className="laboratory-container">
            <div className="laboratory-content">
                <h1 className="lab-title">Biyo-inovatif Teknoloji Laboratuvarı</h1>
                
                <div className="lab-description">
                    <p>
                        Biyo-inovatif Teknoloji Laboratuvarımız tüm sağlık bilimlerine ve mühendislik 
                        çalışmalarına yönelik 3 Boyutlu Modelleme ve 3 Boyutlu Baskılamanın yapılabileceği 
                        bir merkezdir.
                    </p>
                    
                    <p>
                        Biyo-inovatif Teknoloji Laboratuvarımızda; katı model baskılama ve biyobaskılama 
                        ile ilgili çalışmalar yapılmaktadır. Biyomalzeme modellemeleri ve üretimi 
                        çalışmalarını başarıyla sonuçlandırmaktayız.
                    </p>
                    
                    <p>
                        Laboratuvarımız alanında uzman personeli ve yenilikçi yaklaşımı ile ortak 
                        çalışmalarını sürdürmektedir.
                    </p>
                </div>

                <div className="lab-contact">
                    <h2>Sorumlu Akademisyen</h2>
                    <p>Öğr. Gör. Emirhan BOZOĞLAN</p>
                    
                    <div className="last-update">
                        <p>Son Güncelleme Tarihi: 21 Mart 2022, Pazartesi</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BiyoInovatifLab;