// src/pages/BiyokimyaLab/BiyokimyaLab.js
import React from 'react';
import './BiyokimyaLab.css';

const BiyokimyaLab = () => {
    return (
        <div className="laboratory-container">
            <div className="laboratory-content">
                <h1 className="lab-title">Biyokimya Laboratuvarı</h1>
                
                <div className="lab-description">
                    <p>
                        Laboratuvarımızın amacı; preklinik ve klinik çalışmalarda kan, serum, plazma, 
                        idrar, diğer vücut sıvıları ve doku örneklerinden ELISA ve Otoanalizör cihazları 
                        ile rutin ve ileri biyokimyasal testlerin yapılmasını sağlamaktır.
                    </p>
                    
                    <p>
                        Yeni sentez bileşiklerin, ilaçların ve biyomalzemelerin akut, subakut ve kronik 
                        toksisite analizleri için <em>in vivo</em> yöntemler kullanılmaktadır.
                    </p>
                    
                    <p>
                        Biyokimyasal mekanizmaların ve sinyal yolaklarının araştırılması ve yeni 
                        biyobelirteçlerin keşfi üzerinde araştırmalar desteklenmektedir.
                    </p>
                    
                    <p>
                        Diğer temel ve klinik branşlar ile multidisipliner çalışmalar gerçekleştirilmektedir.
                    </p>
                </div>

                <div className="lab-contact">
                    <h2>Sorumlu Akademisyen</h2>
                    <p>Doç. Dr. Fatih Kar</p>
                    
                    <div className="last-update">
                        <p>Son Güncelleme Tarihi: 01 Ağustos 2024, Perşembe</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BiyokimyaLab;