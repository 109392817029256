// src/pages/KuyamBasvuru/KuyamForgotPassword.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const KuyamForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({});
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // API çağrısı
      setSuccess(true);
    } catch (error) {
      setErrors({ submit: error.message });
    }
  };

  return (
    <div className="page-container">
      <div className="kuyam-container">
        <div className="login-box">
          <h2>Şifre Sıfırlama</h2>
          {success ? (
            <div className="success-message">
              <p>Şifre sıfırlama bağlantısı e-posta adresinize gönderildi.</p>
              <button 
                className="login-button"
                onClick={() => navigate('/kuyam-basvuru/login')}
              >
                Giriş Sayfasına Dön
              </button>
            </div>
          ) : (
            <form onSubmit={handleSubmit} className="auth-form">
              <div className="form-group">
                <input
                  type="email"
                  placeholder="E-posta"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <button type="submit" className="login-button">
                Şifre Sıfırlama Bağlantısı Gönder
              </button>
            </form>
          )}
          <div className="links">
            <a href="#" onClick={() => navigate('/kuyam-basvuru/login')}>
              Giriş sayfasına dön
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KuyamForgotPassword;