import React, { useState } from 'react';
import './styles.css';

import Tab1 from './Tab1';
import Tab2 from './Tab2';
import Tab3 from './Tab3';
import Tab4 from './Tab4';
import Tab5 from './Tab5';
import FormPreview from './FormPreview';

const FormTabs = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [formData, setFormData] = useState({
    name: '',
    institution: '',
    phone: '',
    email: '',
    address: '',
    sampleCount: '',
    samples: [
      { id: '01', name: '', content: '', properties: '' },
      { id: '02', name: '', content: '', properties: '' },
      { id: '03', name: '', content: '', properties: '' },
      { id: '04', name: '', content: '', properties: '' }
    ],
    deliveryMethod: { elden: false, kargo: false },
    harmfulEffect: { var: false, yok: false },
    msds: {
      solunum: { checked: false, value: '' },
      deri: { checked: false, value: '' },
      goz: { checked: false, value: '' }
    },
    purpose: { yLisans: false, doktora: false, proje: false, danismanlik: false, diger: false },
    paymentSource: {
      universite: false,
      universiteSanayi: false,
      bireysel: false,
      ozelSektor: false,
      kamu: false,
      protokol: false,
      kuyam: false
    },
    testMethods: [
      { isActive: false, method: '', duration: '' },
      { isActive: false, method: '', duration: '' },
      { isActive: false, method: '', duration: '' }
    ],
    testDevices: {
      modeling3D: false,
      printing3D: false,
      solidModelPrinting: false,
      bioprinting: false,
      biomaterialMod: false
    },
    additionalServices: { testProtocol: false, consultingService: false, other: '' },
    testParameters: [['', '', '']],
    additionalInfo: '',
    storageConditions: '',
    returnPreference: { want: false, dontWant: false }
  });

  const tabs = [
    { title: 'Müşteri Bilgileri', component: <Tab1 formData={formData} setFormData={setFormData} /> },
    { title: 'Kullanım Amacı / Ödeme', component: <Tab2 formData={formData} setFormData={setFormData} /> },
    { title: 'Numune Bilgileri', component: <Tab3 formData={formData} setFormData={setFormData} /> },
    { title: 'Deney Bilgileri', component: <Tab4 formData={formData} setFormData={setFormData} /> },
    { title: 'Onay ve İmza', component: <Tab5 formData={formData} setFormData={setFormData} /> },
  ];

  const nextTab = () => {
    if (activeTab < tabs.length - 1) setActiveTab(activeTab + 1);
  };

  const prevTab = () => {
    if (activeTab > 0) setActiveTab(activeTab - 1);
  };

  const openPdfView = () => {
    sessionStorage.setItem('formData', JSON.stringify(formData));
    window.open('/forms/biyokimya/pdf-view', '_blank');
  };

  return (
    <div className="form-tabs-container">
      <div className="form-tabs-left">
        <div className="form-tabs-buttons">
          {tabs.map((tab, index) => (
            <button
              key={index}
              className={activeTab === index ? 'active' : ''}
              onClick={() => setActiveTab(index)}
            >
              {tab.title}
            </button>
          ))}
        </div>

        <div className="form-tabs-content tab-container">
          {tabs[activeTab].component}
        </div>

        <div className="form-tabs-navigation">
          {activeTab > 0 && <button className="button-primary" onClick={prevTab}>Geri</button>}
          {activeTab < tabs.length - 1 && <button className="button-primary" onClick={nextTab}>İleri</button>}
          {activeTab === tabs.length - 1 && (
            <button className="button-primary" onClick={openPdfView}>PDF Olarak Aç</button>
          )}
        </div>
      </div>

      <div className="form-tabs-right">
        <h3>Form Önizleme</h3>
        <FormPreview formData={formData} />
      </div>
    </div>
  );
};

export default FormTabs;
