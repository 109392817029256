import React, { useState } from 'react';
import { Search } from 'lucide-react';
import './AltyapiProjeleri.css';

const AltyapiProjeleri = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const projectsPerPage = 10;

    const projects = [
        {
            title: "Anabolik Androjenik Steroidlerin Atık Sularda Tespiti İçin Yöntem Validasyonu ve Yöntemin Gerçek Atık Su Örneklerine Uygulanması",
            researchers: "ŞENER H., DAĞLIOĞLU N., ERTAŞ B. S.",
            type: "Yükseköğretim Kurumları Destekli Proje",
            status: "Tamamlandı"
        },
        {
            title: "COVID-19 sonucu MIS-C gelişen ve gelişmeyen pediatrik hastaların laboratuvar bulgularının karşılaştırılmalı olarak değerlendirilmesi",
            researchers: "Aydın E.",
            type: "TÜBİTAK Projesi",
            status: "Devam Ediyor"
        },
        {
            title: "Enjekte edilebilir hidrojellerin sürtünme davranışının incelenmesi",
            researchers: "Göncü Y., Kar F., Şentürk H., Ay G. M.",
            type: "Yükseköğretim Kurumları Destekli Proje",
            status: "Devam Ediyor"
        },
        {
            title: "Üç Boyutlu Biyobaskılama ile İn Vitro Skleroderma Fibrozis Modeli Oluşturmak",
            researchers: "Koldemir Gündüz M., Koçak Sezgin A., Kaymak G.",
            type: "Türkiye Sağlık Enstitüleri Başkanlığı (TÜSEB) Araştırma Projesi",
            status: "Devam Ediyor"
        },
        {
            title: "İsoproterenol ile Miyokard İnfarktüsü Oluşturulmuş Sıçanlarda Phoenixin‐14 Uygulamasının AMPK, JAK2/STAT3, Sema 3E Sinyal Yolakları Üzerine Etkileri",
            researchers: "Kar F., Akcılar R., Özbayer C., Koçak F. E., Taşar M.",
            type: "TÜSEB A Grubu Acil AR-GE Projesi",
            status: "Devam Ediyor"
        },
        {
            title: "Yeni sentezlenmiş metiltiyazol türevi bileşiğin lipopolisakkarit (LPS) ile indüklenmiş nöroinflamasyon üzerindeki etkinliğinin in vivo araştırılması",
            researchers: "Kar E., Alataş İ. Ö.",
            type: "Yükseköğretim Kurumları Destekli Proje",
            status: "Tamamlandı"
        },
        {
            title: "Current Approaches in Central Nervous System Infections",
            researchers: "Aydın E.",
            type: "AB Destekli Diğer Projeler",
            status: "Tamamlandı"
        },
        {
            title: "Liken Türlerinden Elde Edilen Ekstraktların Adipogenez Mekanizması Üzerine Etkilerinin İn Vitro ve İn Vivo Ortamlarda Moleküler ve Biyokimyasal Yöntemlerle İncelenmesi",
            researchers: "Koldemir Gündüz M., Açıkgöz B.",
            type: "TÜBİTAK Projesi",
            status: "Devam Ediyor"
        },
        {
            title: "Luteolinin Candida albicans Escherichia coli ve P aeruginosaya Karşı etkinlik potansiyelinin tekli ve çoklu araştırılması",
            researchers: "Aydın E.",
            type: "Yükseköğretim Kurumları Destekli Proje",
            status: "Devam Ediyor"
        },
        {
            title: "Yüksek Yağlı Diyetle Obezite Modeli Oluşturulan Sıçanlarda Asprosinin Beyin Dokusunda Sinyal Yolakları Üzerindeki Etkileri",
            researchers: "AKCILAR R., BEHDİOĞLU B., KAR F., KOÇAK F. E., KOÇAK C.",
            type: "TÜBİTAK Projesi",
            status: "Devam Ediyor"
        },
        {
            title: "Lactobacillus salivarius' un GSBL üreten E.coli bakterileri üzerinde antibakteriyel etkinliği",
            researchers: "Aydın E.",
            type: "TÜBİTAK Projesi",
            status: "Tamamlandı"
        },
        {
            title: "Adiposit Hücreleri Üzerinde Ferulik Asit ve Pseudevernia furfuracea Ekstraktının İrisin Düzeylerine Bağlı Olarak Yağlanmayı Önleyici Etkilerinin Araştırılması",
            researchers: "Koldemir Gündüz M., Altıntaş A.",
            type: "TÜBİTAK Projesi",
            status: "Devam Ediyor"
        },
        {
            title: "Kabergolin Etken Maddesinin Zebra Balığı Karaciğer Hücreleri Üzerine Oksidatif Stres ve Apoptotik Etkilerinin İn Vitro Araştırılması",
            researchers: "KAYMAK G., KOLDEMİR GÜNDÜZ M., KOÇAK SEZGİN A.",
            type: "Yükseköğretim Kurumları Destekli Proje",
            status: "Tamamlandı"
        },
        {
            title: "CXCR6 ve CXCL16 Genlerindeki Sekans Varyantların Akciğer Kanseri Riski ile İlişkisinin Belirlenmesi",
            researchers: "ÖZBAYER C., ÇALIŞKAN A.",
            type: "Yükseköğretim Kurumları Destekli Proje",
            status: "Tamamlandı"
        },
        {
            title: "SKLERODERMADA LGK-974 VE ETC-159'UN TEDAVİ ETKİNLİĞİNİN ARAŞTIRILMASI",
            researchers: "Koçak Sezgin A.",
            type: "Türkiye Sağlık Enstitüleri Başkanlığı (TÜSEB) Araştırma Projesi",
            status: "Devam Ediyor"
        },
        {
            title: "Klinik örneklerden izole edilen ESKAPE patojenlerin direnç ve biyofilm genlerinin moleküler karakterizasyonu borik asitin biyofilm yeteneğine etkisinin hücre kültür yöntemi ile incelenmesi",
            researchers: "Çelebi Ö., Aydın E., Çelebi D., Rakıcı E.",
            type: "Yükseköğretim Kurumları Destekli Proje",
            status: "Devam Ediyor"
        },
        {
            title: "Letrozol ile İndüklenmiş Polikistik Over Sendromunda PKOS Pirfenidonun Ovaryan Fibrozis Üzerine Etkisinin Araştırılması",
            researchers: "Çakır Gündoğdu A., Kar F., Koçak A., Kurtcan N. S., Şenol G., Özatik O., Altunel E.",
            type: "Yükseköğretim Kurumları Destekli Proje",
            status: "Devam Ediyor"
        },
        {
            title: "Nod1/Card4 Ve Nod2/Card15 Genlerinin Genetik Varyasyonlarının Covid-19 Hastalık Şiddeti İle İlişkilendirilmesi",
            researchers: "Özbayer C.",
            type: "TÜBİTAK Projesi",
            status: "Devam Ediyor"
        },
        {
            title: "Farklı Aktivasyon Teknikleri ile Uygulanan %17 EDTA ve %9 Etidronik Asitin Dentinden Büyüme Faktörlerinin Salınımına Etkisi",
            researchers: "KAYA MUMCU A., KURNAZ S., KİRAZ G., KOLDEMİR GÜNDÜZ M.",
            type: "Yükseköğretim Kurumları Destekli Proje",
            status: "Tamamlandı"
        },
        {
            title: "Hypogymnia tubulosa liken ekstraktının B16-F10 melanoma hücrelerindeki anti-proliferatif, antibakteriyal ve apoptotik etkilerinin araştırılması",
            researchers: "Koldemir Gündüz M., Aydın D.",
            type: "TÜBİTAK Projesi",
            status: "Devam Ediyor"
        },
        {
            title: "Atletlerin Tükürük Kortizol Seviyelerinin Ağız ve Diş Sağlığı İlişkisinin Araştırılması",
            researchers: "Kar F., Ünveren A.",
            type: "Yükseköğretim Kurumları Destekli Proje",
            status: "Devam Ediyor"
        },
        {
            title: "Nazal CPAP Uygulanan Yenidoğanlarda Sarmalama Yönteminin Stres Düzeyine Etkisi",
            researchers: "YAVUZ B., BİLDİRİCİ Y., KOÇAK SEZGİN A., Akkoca Z.",
            type: "Yükseköğretim Kurumları Destekli Proje",
            status: "Tamamlandı"
        },
        {
            title: "Usnea florida liken ekstraktının HT29 kolon kanseri üzerindeki antikanser, antioksidan ve apoptotik etkilerinin araştırılması",
            researchers: "Koldemir Gündüz M., Gülistan Ş.",
            type: "TÜBİTAK Projesi",
            status: "Devam Ediyor"
        },
        {
            title: "Unilateral A.carotis Comminusun iskemi/reperfüzyon Modelinde Dithiocarbomat ile Thymoquinone'nin Antioksidan Sistem ve Cerebrum Üzerindeki etkisinin araştırılması",
            researchers: "Öz S., Kar F., Ortadeveci A., Kaya M., Özden H.",
            type: "Yükseköğretim Kurumları Destekli Proje",
            status: "Devam Ediyor"
        },
        {
            title: "Collaborative Research on Occupational Safety and Health",
            researchers: "Çögenli M. Z., Ulukaya F., Koldemir Gündüz M., Kaymak G., Aydın E., Durmaz A.",
            type: "AB Destekli Diğer Projeler",
            status: "Devam Ediyor"
        },
        {
            title: "Diyabetik nefropatide Finerenone ve Exenatide'ın hibrit kullanımının olası tedavi etkinliğinin araştırılması",
            researchers: "Koldemir Gündüz M., Koçak Sezgin A., Kaymak G., Aydın E.",
            type: "Türkiye Sağlık Enstitüleri Başkanlığı (TÜSEB) Araştırma Projesi",
            status: "Devam Ediyor"
        },
        {
            title: "Prenatal Irisin Uygulamasının, Maternal Lipopolisakkarit Uygulaması ile Indüklenen Beyin Hasarına ve Kognitif Fonksiyonlara Etkisi",
            researchers: "Savran Ö. Y., Kar F., Yığman Z., Özbayer C., Küçük A.",
            type: "TÜBİTAK Projesi",
            status: "Tamamlandı"
        },
        {
            title: "Borik Asidin U251 glioblastoma hücre hattında SEMA3A/Pleksin A1/nörofilin 1 sinyal yolağı aracılığıyla oksidatif stres ve apoptoz üzerindeki etkilerinin araştırılması",
            researchers: "Kar F., Övenler Z.",
            type: "TÜBİTAK Projesi",
            status: "Tamamlandı"
        },
        {
            title: "Dejenere sinovial sıvılar için hareketi kolaylaştırmaya yönelik yeni sentezlenmiş enjekte edilebilir bor nitrür hyalüronik asit hibrit hidrojellerin tribolojisi ve osteoartrit üzerinde moleküler araştırmaları",
            researchers: "Göncü Y., Kar F., Şentürk H., Özbayer C., Kar E., Burukoğlu Dönmez D., Köse N., Ay N.",
            type: "TÜBİTAK Projesi",
            status: "Devam Ediyor"
        },
        {
            title: "H pylori enfeksiyonunun prevalansı antibiyotik direnci ve virulans genlerinin belirlenmesi",
            researchers: "PERÇİN RENDERS D., AYDIN E., COŞGUN S., UZUN A. N., ÇALIŞKAN KARTAL A.",
            type: "Yükseköğretim Kurumları Destekli Proje",
            status: "Tamamlandı"
        },
        {
            title: "TLR9 ve IRAK4 Genlerindeki Sekans Varyantların Akciğer Kanseri Riski ile İlişkisinin Belirlenmesi",
            researchers: "ÖZBAYER C., GÜNEY T. G.",
            type: "Yükseköğretim Kurumları Destekli Proje",
            status: "Tamamlandı"
        },
        {
            title: "Siklofosfamid ile indüklenmiş İnterstisiyel Sistit Hayvan Modelinde Nesfatin1in antiinflamatuar antioksidan ve antiapoptotik etkilerinin incelenmesi",
            researchers: "ÖZATİK F. Y., ARI N. S., ÖZATİK O., TEKŞEN Y., KESKİN U., KOLDEMİR GÜNDÜZ M., KURAĞI Ç. Ç., ÇENGELLİ ÜNEL Ç.",
            type: "Yükseköğretim Kurumları Destekli Proje",
            status: "Tamamlandı"
        },
        {
            title: "Cerrobend Alaşımına Bor Bileşiği Katkılanarak Yüksek Enerjili X Işınlarına Karşı Yeni Bir Zırhlama Malzemesinin Geliştirilmesi",
            researchers: "Manisa K., Şahin M. C., Karadeniz Saygılı S., Bircan H., Özdemir H. İ.",
            type: "Yükseköğretim Kurumları Destekli Proje",
            status: "Devam Ediyor"
        },
        {
            title: "Normotermi ve kontrollü hipotermi altında arkus aorta klempajının iskemik uzak organ hasarı oksidatif stres ve metabolik pH üzerine etkisinin araştırılması",
            researchers: "Şişli E., Kar F., Taştekin T., Çetinkaya D., Şahin A., Dernek S., Uslu S.",
            type: "Yükseköğretim Kurumları Destekli Proje",
            status: "Tamamlandı"
        },
        {
            title: "İki Farklı İmplant Yüzeyinin Osteoblastik Diferansiyasyon Üzerine Etkilerinin 3 Boyutlu Hücre Kültürü Yöntemi İle Karşılaştırılması",
            researchers: "GÜLER AYYILDIZ B., BOZOĞLAN E., KOÇAK SEZGİN A.",
            type: "Yükseköğretim Kurumları Destekli Proje",
            status: "Tamamlandı"
        },
        {
            title: "Kulak izinin morfolojik ve metrik tanımlaması ve adli bilimlerde kullanımı",
            researchers: "SEZGİN N., ŞENER H., GÜLEKÇİ Y.",
            type: "Yükseköğretim Kurumları Destekli Proje",
            status: "Tamamlandı"
        },
        {
            title: "Deneysel Ülser Modelinde Borik Asitin Gastroprotektif Etkinliğinin in vivo Olarak Araştırılması",
            researchers: "Kar F., Özbayer C., Çakır Gündoğdu A.",
            type: "Yükseköğretim Kurumları Destekli Proje",
            status: "Tamamlandı"
        },
        {
            title: "Yüksek Doz Oral Akut Alkol Alımı ile İndüklenen Organ Hasarında Farklı Dozlarda Borik Asitin Koruyucu Rolünün Araştırılması",
            researchers: "Oğlakçı İlhan A., Kar F., Kanbak G., Can B., Söğüt İ.",
            type: "Yükseköğretim Kurumları Destekli Proje",
            status: "Tamamlandı"
        },
        {
            title: "Oral almaya başlayıncaya kadar postoperatif hastalarda gelişen triptofan ve melatonin düzeylerindeki değişiklikler ve inflamatuar yanıta etkileri",
            researchers: "Yaşar F., Uslu S., Kar F., Badak B., Salış M., Öner K. S.",
            type: "Yükseköğretim Kurumları Destekli Proje",
            status: "Devam Ediyor"
        },
        {
            title: "Klinik örneklerden izole edilen Escherichia coli izolatlarının moleküler epidemiyolojik karakterizasyonu ve yüksek riskli ST131 klonunun araştırılması",
            researchers: "Aydın E., Çelebi S.",
            type: "Yükseköğretim Kurumları Destekli Proje",
            status: "Devam Ediyor"
        },
        {
            title: "GSBL üreten ve üretmeyen Escherichia coli izolatlarında ST131 klonunun varlığı ve biyofilm oluşumunun karşılaştırılarak araştırılması",
            researchers: "Çelebi D., Aydın E., Rakıcı E.",
            type: "Yükseköğretim Kurumları Destekli Proje",
            status: "Devam Ediyor"
        },
        {
            title: "Pandemi döneminde sağlık personellerinde uzun süre kişisel koruyucu ekipman kullanımına bağlı gelişen yüzeyel mantar enfeksiyon etkenleri",
            researchers: "Berikten D., Aydın E., Renders D. P., Arslan Utku S.",
            type: "Diğer Resmi Kurumlarca Desteklenen Proje",
            status: "Devam Ediyor"
        },
        {
            title: "NOTCH1 ve NOTCH3 Genlerindeki Sekans Varyantların Prostat Kanser Riski ile İlişkisinin Belirlenmesi",
            researchers: "ÖZBAYER C., KURT H., ÖZEN A., YAĞCI E.",
            type: "Yükseköğretim Kurumları Destekli Proje",
            status: "Tamamlandı"
        },
        {
            title: "Mikroplastiklerin Plasenta ile Kordon Kanı Örneklerinde Tespiti icin Yöntem Geliştirilmesi",
            researchers: "Bıyık İ., Dağlıoğlu N., Gündoğdu S., Şener H.",
            type: "Yükseköğretim Kurumları Destekli Proje",
            status: "Devam Ediyor"
        },
        {
            title: "Üç Boyutlu Doku Kültürü Modellemesi ile Bor ve GLP-1R Agonistinin Adipositler Üzerindeki Etkilerinin Tüm Genom Transkriptom Profillemesi ile Araştırılması",
            researchers: "Koldemir Gündüz M., Kaymak G., Berikten D., Şener H., Şahin M. C., Yerlikaya A.",
            type: "TÜBİTAK Projesi",
            status: "Devam Ediyor"
        },
        {
            title: "Sklerodermada Peptid Pd29 ve Upadacitinib (Abt-494) Etkinliğinin İncelenmesi",
            researchers: "Koçak Sezgin A.",
            type: "TÜBİTAK Projesi",
            status: "Tamamlandı"
        },
        {
            title: "TÜBİTAK/2237-B Programı Sağlık ve Biyoteknoloji Alanlarında Araştırma Projeleri Hazırlama Eğitimi",
            researchers: "Koçak Sezgin A.",
            type: "TÜBİTAK Projesi",
            status: "Tamamlandı"
        },
        {
            title: "Pestisit ve Farmasötik Kirleticilerinin Plasenta ve Kordon Kanında Tespit Edilmesi",
            researchers: "Şener H., Gülekçi Y., Bıyık İ., Dağlıoğlu N.",
            type: "Yükseköğretim Kurumları Destekli Proje",
            status: "Devam Ediyor"
        },
        {
            title: "Polikistik over sendromu tanısı alan ve almayan sağlıklı kadınların serum alfa klotho düzeyleri ve thioldisülfid homeostazının karşılaştırılması",
            researchers: "Bıyık İ., Öztaş E., Erten Ö., Işıklar Ö. Ö., Berikten D.",
            type: "Yükseköğretim Kurumları Destekli Proje",
            status: "Devam Ediyor"
        },
        {
            title: "Üç Boyutlu Biyoyazıcıda Üretilen Biyodevrenin Periferik Sinir Hasarı İyileşmesine ve Elektriksel İmpuls İletimine Katkısının Araştırılması",
            researchers: "Şahin M. C., Aydın H. E., Kaya İ., Bozoğlan E., Cingöz İ. D., Kavuncu S.",
            type: "Yükseköğretim Kurumları Destekli Proje",
            status: "Devam Ediyor"
        },
        {
            title: "Kütahya Sağlık Bilimleri Üniversitesi Uygulama ve Araştırma Merkezi Alt Yapı Projesi (KUYAM)",
            researchers: "Koldemir Gündüz M., Berikten D., Şener H., Özbayer C., Şahin M. C., Yerlikaya A., Koçak Sezgin A., Çakır Gündoğdu A., Karadeniz Saygılı S., Özyurt R., et al.",
            type: "CB Strateji ve Bütçe Başkanlığı (Kalkınma Bakanlığı) Projesi",
            status: "Devam Ediyor"
        },
        {
            title: "Van ilindeki içme sularından izole edilen antibiyotik dirençli Escherichia coli suşlarındaki integron gen kasetlerinin moleküler karakterizasyonu",
            researchers: "Aydın E., Özgümüş O. B., Rakıcı E.",
            type: "Yükseköğretim Kurumları Destekli Proje",
            status: "Devam Ediyor"
        },
        {
            title: "Omurga Cerrahisinde Üç Boyutlu Biyoyazıcı ile Üretilen Biyouyumlu Kemik Greftleri ile Geleneksel Kemik Greftlerinin Karşılaştırılması",
            researchers: "Şahin M. C., Bozoğlan E., Aydın H. E., Arslantaş A., Aydın N.",
            type: "Yükseköğretim Kurumları Destekli Proje",
            status: "Devam Ediyor"
        },
        {
            title: "Böbrek İskemi-Reperfüzyon Modeli'nde Conivaptan ve Borik Asit Tedavisinin Uzak-Organ Hasarı Üzerindeki Etkilerinin Araştırılması",
            researchers: "Can B., Kar E., Alataş İ. Ö., Kanbak G., Kar F., Şentürk H., Burukoğlu Dönmez D.",
            type: "Yükseköğretim Kurumları Destekli Proje",
            status: "Tamamlandı"
        },
        {
            title: "İn vitro ortamda inkretin bazlı tedavilerin adipogenez üzerine etkilerinin araştırılması",
            researchers: "KOLDEMİR GÜNDÜZ M., ŞENER H., BERİKTEN D.",
            type: "Yükseköğretim Kurumları Destekli Proje",
            status: "Tamamlandı"
        },
        {
            title: "Kütahya Kaplıcalarında Termal Su ve İç Ortam Havasından Potansiyel İnfeksiyon Riski Oluşturabilen Aspergillus sp Türlerinin Belirlenmesi",
            researchers: "BERİKTEN D., KOLDEMİR GÜNDÜZ M., KIVANÇ M.",
            type: "Yükseköğretim Kurumları Destekli Proje",
            status: "Tamamlandı"
        },
        {
            title: "Akut karotis arter oklüzyonu sonucunda oluşan serebral iskemide ranolazinin beyin dokusundaki antiiskemik etkisinin in vivo araştırılması",
            researchers: "AKGÜL E., ERKUL S., ÇEKİRDEKÇİ A., AYDIN H. E., PARLAR A. İ., BOZOĞLAN E., ŞENER H., KOLDEMİR GÜNDÜZ M., ŞAHİN M. C., ALPTEKİN ERKUL G. S.",
            type: "Yükseköğretim Kurumları Destekli Proje",
            status: "Tamamlandı"
        },
        {
            title: "PEGANUM HARMALA TOHUM EKSTRESİNİN SIÇANLARDA KRONİK ÖNGÖRÜLEMEYEN HAFİF STRES MODELİNDE ANTİDEPRESAN VE ANKSİYOLİTİK ETKİLERİNİN İNCELENMESİ",
            researchers: "Berikten D., Aydın H. E., Tekşen Y., Özatik F. Y., Koldemir Gündüz M.",
            type: "Yükseköğretim Kurumları Destekli Proje",
            status: "Devam Ediyor"
        },
        {
            title: "İçme Suyu ile Ağır Metal Maruziyeti Olan Kişilerde Ağır Metal Konsantrasyonlarının Risk Algılarının ve Sağlık Risklerinin Belirlenmesi",
            researchers: "ARIKAN İ., TELLİOĞLU M., ŞENER H., KARAKUŞ H.",
            type: "Yükseköğretim Kurumları Destekli Proje",
            status: "Tamamlandı"
        },
        {
            title: "Menengiç (Pistacia terebinthus L) ekstraktının hepatoselüler karsinom hücrelerinde (HepG2) antioksidatif ve antikarsinojenik etkilerinin incelenmesi",
            researchers: "KAYMAK G., KOLDEMİR GÜNDÜZ M.",
            type: "Yükseköğretim Kurumları Destekli Proje",
            status: "Tamamlandı"
        },
        {
            title: "Ratlarda Renal İskemi Reperfüzyon ile Oluşturulan Böbrek Hasarına Karşı Curcumin ve LOXblock-1'in potansiyel etkilerinin Semaforin 3A ve Pleksin A1 Üzerinden Araştırılması",
            researchers: "Kar F., Uslu S.",
            type: "Yükseköğretim Kurumları Destekli Proje",
            status: "Tamamlandı"
        },
        {
            title: "Sıçanlarda intravenöz hBN uygulanması ile meydana gelen biyokimyasal ve histopatolojik etkilerin araştırılması",
            researchers: "Kar F., Ay N.",
            type: "Yükseköğretim Kurumları Destekli Proje",
            status: "İptal Edildi"
        },
        {
            title: "Prostat kanserinde eksozom ile taşınan metastaz ilişkili miRNA'ların ekspresyonlarının belirlenmesi",
            researchers: "Kurt H., Yağcı E., Özen A., Özbayer C.",
            type: "Yükseköğretim Kurumları Destekli Proje",
            status: "Devam Ediyor"
        },
        {
            title: "Prenatal Dönemde Bağırsak Mikrobiyotasının Korunmasının Nörodejeneratif ve Alzheimer Yatkınlığı Üzerine Etkileri",
            researchers: "Kar F., Kanbak G., Uslu S., Burukoğlu Dönmez D., Akyüz F., Hacıoğlu C.",
            type: "Yükseköğretim Kurumları Destekli Proje",
            status: "Tamamlandı"
        },
        {
            title: "Oral Glikoz Tolerans Testi Sonrasında Meydana Gelen Değişikliklerin Oksidatif Stres ve Biyokimyasal Parametreler Üzerinden İncelenmesi",
            researchers: "Küskü Kiraz Z., Kar E., Uslu S., Kar F., Kocatürk E., Kebapçı M. N.",
            type: "Yükseköğretim Kurumları Destekli Proje",
            status: "Tamamlandı"
        },
        {
            title: "65 Yaş ve Üstü Bireylerde Egzersizin Malnütrisyon Riski, İrisin, D Vitamini, Lipit Profilleri ve Glisemi Üzerine Etkilerinin Değerlendirilmesi: Tek Merkezli Çalışma Raporu",
            researchers: "Kar F., Kavlak Y.",
            type: "Yükseköğretim Kurumları Destekli Proje",
            status: "Tamamlandı"
        },
        {
            title: "Kranioplasti Cerrahisinde Biyouyumlu Hidroksiapatit-PLA Filamenti İle Uzun Ömürlü Kranioplasti Kiti Üretimi",
            researchers: "Şahin M. C., Aydın H. E.",
            type: "TÜBİTAK Projesi",
            status: "Devam Ediyor"
        },
        {
            title: "Koruyucu Katkı Maddesi Kuaterniyum-15'in, İnsan Sağlığı Üzerine Toksik Etkilerinin In vivo ve In vitro Olarak Belirlenmesi ve Oluşan Hasarların Sağaltımı İçin E- Vitamininin Koruyucu ve İyileştirici Rolünün Araştırılması",
            researchers: "Kaymak G., Koldemir Gündüz M.",
            type: "TÜBİTAK Projesi",
            status: "Tamamlandı"
        },
        {
            title: "Renal İskemi Reperfüzyon ile İndüklenmiş Oksidatif Stress Hasarına Karşı Borik Asidin Potansiyel Koruyucu Etkilerinin Araştırılması",
            researchers: "Kar F., Şentürk H.",
            type: "Yükseköğretim Kurumları Destekli Proje",
            status: "Tamamlandı"
        },
        {
            title: "Plevral Efüzyonlarda Biyolojik Belirteçlerin Hasta Yönetimindeki Yeri",
            researchers: "Metintaş M., Özbayer C., Metintaş S., Ak G.",
            type: "Yükseköğretim Kurumları Destekli Proje",
            status: "Devam Ediyor"
        },
        {
            title: "Aβ (1-42) ile Muamele Edilmiş Sıçan Sinaptozomları Üzerinde Koruyucu Ajan Olarak Bexarotene ve Nikotinamid (NA) Fonksiyonunun Ex Vivo Olarak Araştırılması",
            researchers: "Kar F., Kanbak G.",
            type: "Yükseköğretim Kurumları Destekli Proje",
            status: "Tamamlandı"
        },
        {
            title: "CXCR4 / CXCL12 ve Eotaksin 1 / CCR3 Gen Varyantları ve Akciğer Kanseri Riski: Vaka Kontrol Çalışması",
            researchers: "Özbayer C., Kurt H.",
            type: "Yükseköğretim Kurumları Destekli Proje",
            status: "Tamamlandı"
        },
        {
            title: "Malign Plevral Mezotelyomalı Hastaların Tedavi Takibinde ve Prognoz Analizinde miRNA'ların Yeri",
            researchers: "Özbayer C., Metintaş M., Ak G.",
            type: "Yükseköğretim Kurumları Destekli Proje",
            status: "Tamamlandı"
        },
        {
            title: "Omurga Cerrahisi Operasyonlarında simülasyon ve üç boyutlu modelin cerrahi plan üzerine etkisi",
            researchers: "AYDIN H. E.",
            type: "Yükseköğretim Kurumları Destekli Proje",
            status: "Tamamlandı"
        },
        {
            title: "Tribenuron-metil'in toksik etkilerinin Zebra balığı (Danio rerio) karaciğer dokusunda İn vivo ve İn vitro ortamlarda incelenmesi",
            researchers: "Kaymak G., Kayhan F. E.",
            type: "Yükseköğretim Kurumları Destekli Proje",
            status: "Devam Ediyor"
        }
    ];
    

  const filteredProjects = projects.filter(project => 
    project.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
    project.researchers.toLowerCase().includes(searchQuery.toLowerCase()) ||
    project.type.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Sayfalama hesaplamaları
  const indexOfLastProject = currentPage * projectsPerPage;
  const indexOfFirstProject = indexOfLastProject - projectsPerPage;
  const currentProjects = filteredProjects.slice(indexOfFirstProject, indexOfLastProject);
  const totalPages = Math.ceil(filteredProjects.length / projectsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="container">
      <h1 className="page-title">Alt Yapı Projelerimiz</h1>
      
      <div className="search-container">
        <Search className="search-icon" />
        <input
          type="text"
          placeholder="Proje ara... (Proje adı, araştırmacı veya proje türü)"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="search-input"
        />
      </div>

      <div className="projects-grid">
        {currentProjects.map((project, index) => (
          <div key={index} className="project-card">
            <div className="project-content">
              <div className="project-main">
                <h2 className="project-title">{project.title}</h2>
                <p className="project-info">
                  <strong>Araştırmacılar:</strong> {project.researchers}
                </p>
                <p className="project-info">
                  <strong>Proje Türü:</strong> {project.type}
                </p>
              </div>
              <span className={`status-badge ${
                project.status === 'Devam Ediyor' ? 'ongoing' : 'completed'
              }`}>
                {project.status}
              </span>
            </div>
          </div>
        ))}
      </div>

      {totalPages > 1 && (
        <div className="pagination">
          <button 
            onClick={() => paginate(currentPage - 1)} 
            disabled={currentPage === 1}
            className="pagination-button"
          >
            Önceki
          </button>
          
          {[...Array(totalPages)].map((_, index) => (
            <button
              key={index + 1}
              onClick={() => paginate(index + 1)}
              className={`pagination-button ${currentPage === index + 1 ? 'active' : ''}`}
            >
              {index + 1}
            </button>
          ))}
          
          <button 
            onClick={() => paginate(currentPage + 1)} 
            disabled={currentPage === totalPages}
            className="pagination-button"
          >
            Sonraki
          </button>
        </div>
      )}
    </div>
  );
};

export default AltyapiProjeleri;