import React, { useState } from 'react';
import './Profile.css';
import CustomerProfileModal from './CustomerProfileModal';
import ViewProfileModal from './ViewProfileModal';

function Profile() {
  const [profiles] = useState([
    {
      id: 1,
      title: "Öğretim Görevlisi",
      fullName: "Önder Öztürk",
      group: "Diğer Üniversiteler ve Kamu Kurumları",
      email: "onder.ozturk@ksbu.edu.tr",
      institution: "KSBÜ",
      birthDate: "",
      tcNo: "10676748144",
      invoiceAddress: "Ali paşa mah. Hamza Güner cd. 16/4 Kütahya",
      address: "Ali paşa mah. Hamza Güner cd. 16/4 Kütahya",
      taxOffice: "",
      taxNo: "0",
      phoneNumber: "05333798110",
      mobilePhone: "05333798110",
      isLocked: true,
      hasViewPermission: true,
      hasEditPermission: true
    }
  ]);
  const [pageSize, setPageSize] = useState(20);
  const [searchTerm, setSearchTerm] = useState('');
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [passwordQuality, setPasswordQuality] = useState('');

  const [isCustomerModalOpen, setIsCustomerModalOpen] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  // State ekleyin
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);

  // Görüntüleme butonunun onClick işleyicisi
  const handleViewClick = (profile) => {
    setSelectedCustomer(profile);
    setIsViewModalOpen(true);
  };

  const handleEditClick = (profile) => {
    setSelectedCustomer(profile);
    setIsCustomerModalOpen(true);
  };

  const handlePrint = () => {
    window.print();
  };

  const handlePageSizeChange = (size) => {
    setPageSize(size);
  };

  const checkPasswordQuality = (pass) => {
    if (!pass) return '';
    
    let strength = 0;
    if (pass.length >= 8) strength++;
    if (/[A-Z]/.test(pass)) strength++;
    if (/[a-z]/.test(pass)) strength++;
    if (/[0-9]/.test(pass)) strength++;

    if (strength === 4) return 'Güçlü';
    if (strength >= 2) return 'Orta';
    return 'Zayıf';
  };

  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    if (password === passwordConfirm) {
      // TODO: Şifre değiştirme API çağrısı
      console.log('Şifre değiştirme başarılı');
      setIsPasswordModalOpen(false);
      setPassword('');
      setPasswordConfirm('');
      setPasswordQuality('');
    } else {
      alert('Şifreler eşleşmiyor!');
    }
  };

  return (
    <div className="profile-container">
      {/* Üst Toolbar */}
      <div className="top-toolbar">
        <div className="toolbar-left"></div>
        <div className="toolbar-right">
          <button onClick={handlePrint} className="print-button">
            <span className="icon">🖨</span> Yazdır
          </button>
        </div>
      </div>

      {/* Profil tablosu */}
      <div className="content-area">
        <div className="table-container">
          <table className="profile-table">
            <thead>
              <tr>
                <th>#</th>
                <th>Ünvanı</th>
                <th>⇈ Adı Soyadı</th>
                <th>Grubu</th>
                <th>E-mail</th>
                <th className="actions-column">İşlemler</th>
              </tr>
            </thead>
            <tbody>
              {profiles.map((profile) => (
                <tr key={profile.id}>
                  <td>{profile.id}</td>
                  <td>{profile.title}</td>
                  <td>{profile.fullName}</td>
                  <td>{profile.group}</td>
                  <td>
                    <a href={`mailto:${profile.email}`} className="email-link">
                      {profile.email}
                    </a>
                  </td>
                  <td className="actions-column">
                    <div className="action-buttons">
                      {profile.hasViewPermission && (
                        <button 
                          className="action-button view-button"
                          onClick={() => handleViewClick(profile)}
                          title="Görüntüle"
                        >
                          🔍
                        </button>
                      )}
                      {profile.hasEditPermission && (
                        <>
                          <button 
                            className="action-button edit-button"
                            onClick={() => handleEditClick(profile)}
                            title="Profili Düzenle"
                          >
                            ✏️
                          </button>
                          <button 
                            className="action-button password-button"
                            onClick={() => setIsPasswordModalOpen(true)}
                            title="Şifre Değiştir"
                          >
                            🔑
                          </button>
                        </>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Alt araç çubuğu */}
      <div className="profile-toolbar">
        <div className="page-size-selector">
          <button 
            className={`page-button ${pageSize === 20 ? 'active' : ''}`}
            onClick={() => handlePageSizeChange(20)}
          >
            20
          </button>
          <button 
            className={`page-button ${pageSize === 50 ? 'active' : ''}`}
            onClick={() => handlePageSizeChange(50)}
          >
            50
          </button>
          <button 
            className={`page-button ${pageSize === 100 ? 'active' : ''}`}
            onClick={() => handlePageSizeChange(100)}
          >
            100
          </button>
          <button 
            className={`page-button ${pageSize === 0 ? 'active' : ''}`}
            onClick={() => handlePageSizeChange(0)}
          >
            Tümü
          </button>
        </div>
        
        <div className="search-box">
          <input
            type="text"
            placeholder="Ara"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="search-input"
          />
        </div>
      </div>

      {/* Şifre Değiştirme Popup */}
      {isPasswordModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <div className="modal-header">
              <h2>Şifreyi değiştir</h2>
              <button 
                className="modal-close"
                onClick={() => setIsPasswordModalOpen(false)}
              >
                ✕
              </button>
            </div>
            
            <form onSubmit={handlePasswordSubmit} className="password-form">
              <div className="form-group">
                <label>Şifre:</label>
                <input
                  type="password"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    setPasswordQuality(checkPasswordQuality(e.target.value));
                  }}
                  className="form-input"
                />
                {passwordQuality && (
                  <div className={`password-quality ${passwordQuality.toLowerCase()}`}>
                    Şifre Kalitesi: {passwordQuality}
                  </div>
                )}
              </div>

              <div className="form-group">
                <label>Şifre Tekrar:</label>
                <input
                  type="password"
                  value={passwordConfirm}
                  onChange={(e) => setPasswordConfirm(e.target.value)}
                  className="form-input"
                />
              </div>

              <div className="password-requirements">
                Şifre
                <ul>
                  <li>En az 8 karakter</li>
                  <li>En az bir Büyük Harf</li>
                  <li>En az bir küçük Harf</li>
                  <li>En az bir rakam</li>
                </ul>
                dan oluşmalıdır
              </div>

              <button type="submit" className="submit-button">
                Şifre Değiştir
              </button>
            </form>
          </div>
        </div>
      )}

      <ViewProfileModal 
        isOpen={isViewModalOpen}
        onClose={() => setIsViewModalOpen(false)}
        profile={selectedCustomer}
      />
      <CustomerProfileModal 
        isOpen={isCustomerModalOpen}
        onClose={() => setIsCustomerModalOpen(false)}
        customer={selectedCustomer}
      />
    </div>
  );
}

export default Profile;