import React, { useState } from 'react';
import './DeviceAppointments.css';
import DeviceCalendar from './DeviceCalendar';
import DatePicker, { registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import tr from 'date-fns/locale/tr';

registerLocale('tr', tr);

function DeviceAppointments() {
  const [appointments] = useState([]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState('');
  const [showDeviceSelector, setShowDeviceSelector] = useState(true);
  const [showCalendar, setShowCalendar] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [showPrintPreview, setShowPrintPreview] = useState(false);

  // Popup form states
  const [popupDevice, setPopupDevice] = useState('');
  const [popupDate, setPopupDate] = useState(null);
  const [popupSession, setPopupSession] = useState('');
  const [popupInfo, setPopupInfo] = useState('');

  const devices = [
    { id: 'rtpcr', name: 'RT-PCR (Real Time PCR) Cihazı' },
    { id: 'pcr', name: 'PCR Cihazı' },
    { id: 'western', name: 'Western Blot Sistemi' },
    { id: 'microscope', name: 'Mikroskop' },
    { id: 'elisa_reader', name: 'ELISA Reader (Mikroplaka Okuyucu)' },
    { id: 'elisa_washer', name: 'ELISA Yıkayıcı' },
    { id: 'cell_culture', name: 'Hücre Kültürü Laboratuvarı' },
    { id: 'autoclave', name: 'Otoklav' },
    { id: 'centrifuge', name: 'Santrifüj' }
  ];

  const sessions = [
    { id: 'morning', name: 'Sabah (09:00-12:00)' },
    { id: 'afternoon', name: 'Öğle (13:00-17:00)' }
  ];

  const handleDeviceSelect = (deviceId) => {
    setSelectedDevice(deviceId);
    setIsMenuOpen(false);
  };

  const handleGitClick = () => {
    if (selectedDevice) {
      setShowCalendar(true);
      setShowDeviceSelector(false);
    }
  };

  const handleResetCalendar = () => {
    setShowCalendar(false);
    setShowDeviceSelector(true);
    setSelectedDevice('');
  };

  const handlePopupSubmit = (e, saveAndNew = false) => {
    e.preventDefault();
    console.log('New Appointment:', {
      device: popupDevice,
      date: popupDate,
      session: popupSession,
      info: popupInfo
    });

    if (saveAndNew) {
      setPopupDevice('');
      setPopupDate(null);
      setPopupSession('');
      setPopupInfo('');
    } else {
      setIsPopupOpen(false);
    }
  };

  const handlePrint = () => {
    window.print();
  };

  return (
    <div className="appointments-container">
      {/* Sol Panel */}
      <div className="appointments-panel">
        <h1 className="panel-title">Randevu Talepleri</h1>
        
        <div className="panel-actions">
          <button className="add-button" onClick={() => setIsPopupOpen(true)}>
            <span className="icon">+</span> Ekle
          </button>
          <button className="print-button" onClick={() => setShowPrintPreview(true)}>
            <span className="icon">🖨</span> Yazdır
          </button>
        </div>

        <div className="table-container">
          <table className="appointments-table">
            <thead>
              <tr>
                <th>#</th>
                <th>Cihaz Adı</th>
                <th>Randevu Tarih</th>
                <th>Seans</th>
                <th>Onay</th>
              </tr>
            </thead>
            <tbody>
              {appointments.length === 0 ? (
                <tr>
                  <td colSpan="5" className="no-data">Veri bulunmadı.</td>
                </tr>
              ) : (
                appointments.map(appointment => (
                  <tr key={appointment.id}>
                    <td>{appointment.id}</td>
                    <td>{appointment.deviceName}</td>
                    <td>{appointment.date}</td>
                    <td>{appointment.session}</td>
                    <td>{appointment.approval}</td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>

        <div className="pagination">
          <div className="page-buttons">
            <button className="page-button">500</button>
            <button className="page-button">20</button>
            <button className="page-button">50</button>
            <button className="page-button">100</button>
            <button className="page-button">Tümü</button>
          </div>
          <div className="search-box">
            <input type="text" placeholder="Ara" className="search-input" />
          </div>
        </div>
      </div>

      {/* Sağ Panel */}
      <div className="calendar-panel">
        <div className="calendar-panel-header">
          <h1 className="panel-title">Cihaz Takvimleri</h1>
          {showCalendar && (
            <button onClick={handleResetCalendar} className="reset-button">
              Yeni Cihaz Seç
            </button>
          )}
        </div>
        
        {showDeviceSelector && (
          <div className="device-selector">
            <div className="custom-select">
              <button 
                className="select-button"
                onClick={() => setIsMenuOpen(!isMenuOpen)}
              >
                {selectedDevice ? devices.find(d => d.id === selectedDevice)?.name : 'Seçiniz'}
              </button>
              
              {isMenuOpen && (
                <div className="select-menu">
                  {devices.map((device) => (
                    <button
                      key={device.id}
                      className={`menu-item ${selectedDevice === device.id ? 'selected' : ''}`}
                      onClick={() => handleDeviceSelect(device.id)}
                    >
                      {device.name}
                    </button>
                  ))}
                </div>
              )}
            </div>
            <button 
              className="go-button"
              disabled={!selectedDevice}
              onClick={handleGitClick}
            >
              Git
            </button>
          </div>
        )}

        {showCalendar && selectedDevice && (
          <DeviceCalendar selectedDevice={selectedDevice} />
        )}
      </div>

      {/* Randevu Ekleme Popup */}
      {isPopupOpen && (
        <div className="popup-overlay">
          <div className="popup-content">
            <h2>Yeni Randevu Ekle</h2>
            <form onSubmit={(e) => handlePopupSubmit(e, false)}>
              <div className="form-group">
                <label>Cihaz Adı<span className="required">*</span></label>
                <select
                  value={popupDevice}
                  onChange={(e) => setPopupDevice(e.target.value)}
                  required
                >
                  <option value="">- boş -</option>
                  {devices.map(device => (
                    <option key={device.id} value={device.id}>{device.name}</option>
                  ))}
                </select>
              </div>

              <div className="form-group">
                <label>Randevu Tarih<span className="required">*</span></label>
                <DatePicker
                  selected={popupDate}
                  onChange={date => setPopupDate(date)}
                  dateFormat="dd/MM/yyyy"
                  locale="tr"
                  placeholderText="Tarih Seçin"
                  className="date-picker"
                  required
                />
              </div>

              <div className="form-group">
                <label>Seans<span className="required">*</span></label>
                <select
                  value={popupSession}
                  onChange={(e) => setPopupSession(e.target.value)}
                  required
                >
                  <option value="">- boş -</option>
                  {sessions.map(session => (
                    <option key={session.id} value={session.id}>{session.name}</option>
                  ))}
                </select>
              </div>

              <div className="form-group">
                <label>Analiz Hakkında Bilgi</label>
                <textarea
                  value={popupInfo}
                  onChange={(e) => setPopupInfo(e.target.value)}
                  rows={4}
                />
              </div>

              <div className="button-group">
                <button type="submit" className="primary-button">
                  Kaydet ve Listeye Dön
                </button>
                <button
                  type="button"
                  className="secondary-button"
                  onClick={(e) => handlePopupSubmit(e, true)}
                >
                  Kaydet ve Yeni Ekle
                </button>
                <button
                  type="button"
                  className="cancel-button"
                  onClick={() => setIsPopupOpen(false)}
                >
                  Geri Dön
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* Yazdırma Önizleme Popup */}
      {showPrintPreview && (
        <div className="popup-overlay print-preview-overlay">
          <div className="popup-content print-preview-content">
            <div className="print-preview-header">
              <h2>Yazdırma Önizleme</h2>
              <div className="print-actions">
                <button className="print-action-button" onClick={handlePrint}>
                  <span className="icon">🖨</span> Yazdır
                </button>
                <button className="cancel-button" onClick={() => setShowPrintPreview(false)}>
                  Kapat
                </button>
              </div>
            </div>
            
            <div className="print-area">
              <h1>Randevu Talepleri</h1>
              <div className="print-date">Tarih: {new Date().toLocaleDateString('tr-TR')}</div>
              
              <table className="print-table">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Cihaz Adı</th>
                    <th>Randevu Tarih</th>
                    <th>Seans</th>
                    <th>Onay</th>
                  </tr>
                </thead>
                <tbody>
                  {appointments.length === 0 ? (
                    <tr>
                      <td colSpan="5" className="no-data">Veri bulunmadı.</td>
                    </tr>
                  ) : (
                    appointments.map(appointment => (
                      <tr key={appointment.id}>
                        <td>{appointment.id}</td>
                        <td>{appointment.deviceName}</td>
                        <td>{appointment.date}</td>
                        <td>{appointment.session}</td>
                        <td>{appointment.approval}</td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default DeviceAppointments;