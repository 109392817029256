// src/pages/kuyam-basvuru/dashboard/components/Sidebar.jsx
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Home, FileText, DollarSign, Calendar, Clock, User, Share2, LogOut } from 'lucide-react';
import './Sidebar.css';

const menuItems = [
  { icon: Home, text: 'Ana Sayfa', path: '/kuyam-basvuru/dashboard' },
  { icon: FileText, text: 'Yeni/Taslak Fiyat Teklifleri', path: '/kuyam-basvuru/dashboard/new-offers' },
  { icon: FileText, text: 'Mevcut Fiyat/Analiz Teklifleri', path: '/kuyam-basvuru/dashboard/current-offers' },
  { icon: DollarSign, text: 'Bütçe', path: '/kuyam-basvuru/dashboard/budget' },
  { icon: FileText, text: 'Ödemelerim ve Faturalarım', path: '/kuyam-basvuru/dashboard/payments' },
  { icon: Calendar, text: 'Cihaz Takvimleri', path: '/kuyam-basvuru/dashboard/device-calendar' },
  { icon: Clock, text: 'Cihaz Randevuları', path: '/kuyam-basvuru/dashboard/device-appointments' },
  { icon: User, text: 'Bilgilerim & Şifre', path: '/kuyam-basvuru/dashboard/profile' },
  { icon: Share2, text: 'Paylaşım Merkezi', path: '/kuyam-basvuru/dashboard/sharing' }
];

const Sidebar = () => {
  return (
    <div className="sidebar">
      <div className="sidebar-container">
        <div className="profile-section">
          <div className="profile-container">
            <div className="profile-avatar"></div>
            <span className="profile-name">Önder Öztürk</span>
          </div>
        </div>
        
        <nav className="nav-container">
          {menuItems.map((item, index) => (
            <NavLink
              key={index}
              to={item.path}
              className={({ isActive }) =>
                `nav-item ${isActive ? 'active' : ''}`
              }
            >
              <item.icon className="nav-item-icon" />
              {item.text}
            </NavLink>
          ))}
        </nav>

        <div className="version-info">
          Version 2.2.1- CTOP
        </div>
      </div>
    </div>
  );
};

export default Sidebar;