import React, { useState } from 'react';
import { X, Upload } from 'lucide-react';
import './AddPaymentModal.css';

const AddPaymentModal = ({ isOpen, onClose, onSave }) => {
  const [formData, setFormData] = useState({
    date: '',
    amount: '0.00',
    description: '',
    file: null
  });

  if (!isOpen) return null;

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(formData);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleFileChange = (e) => {
    if (e.target.files?.length) {
      setFormData(prev => ({
        ...prev,
        file: e.target.files[0]
      }));
    }
  };

  return (
    <div className="payment-modal-overlay">
      <div className="payment-modal-container">
        <div className="payment-modal-content">
          {/* Modal Header */}
          <div className="payment-modal-header">
            <h2 className="payment-modal-title">Tahsilat İşlemi</h2>
            <button 
              onClick={onClose}
              className="payment-modal-close"
            >
              <X size={24} />
            </button>
          </div>

          {/* Form */}
          <form onSubmit={handleSubmit} className="payment-form">
            <div className="form-grid">
              <div className="form-group">
                <label className="form-label">
                  Tarih
                  <span className="required">*</span>
                </label>
                <input
                  type="date"
                  name="date"
                  value={formData.date}
                  onChange={handleChange}
                  className="form-input"
                  required
                />
              </div>

              <div className="form-group">
                <label className="form-label">
                  Tahsilat Tutar
                  <span className="required">*</span>
                </label>
                <input
                  type="number"
                  name="amount"
                  value={formData.amount}
                  onChange={handleChange}
                  className="form-input"
                  min="0"
                  step="0.01"
                  required
                />
              </div>

              <div className="form-group full-width">
                <label className="form-label">
                  Açıklama
                </label>
                <textarea
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                  className="form-textarea"
                  rows={3}
                />
              </div>

              <div className="form-group full-width">
                <label className="form-label">
                  Dekont/Dosya
                </label>
                <div className="file-upload-container">
                  <div className="file-upload-area">
                    <input
                      type="file"
                      id="file-upload"
                      onChange={handleFileChange}
                      className="file-input"
                    />
                    <label htmlFor="file-upload" className="file-upload-label">
                      <Upload size={24} />
                      <span>
                        {formData.file ? formData.file.name : 'Dosya seçin veya sürükleyin'}
                      </span>
                    </label>
                  </div>
                  {formData.file && <span className="file-name">{formData.file.name}</span>}
                </div>
              </div>
            </div>

            {/* Buttons */}
            <div className="form-buttons">
              <button
                type="button"
                onClick={onClose}
                className="cancel-button"
              >
                Geri Dön
              </button>
              <div className="primary-buttons">
                <button
                  type="submit"
                  className="submit-button"
                >
                  Kaydet
                </button>
                <button
                  type="button"
                  className="save-continue-button"
                  onClick={() => {
                    handleSubmit({ preventDefault: () => {} });
                    setFormData({
                      date: '',
                      amount: '0.00',
                      description: '',
                      file: null
                    });
                  }}
                >
                  Kaydet ve Yeni Ekle
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddPaymentModal;