import React from 'react';
import './styles.css';

const Tab5 = ({ formData, setFormData }) => {
  const handleCheckboxChange = (section, field) => {
    setFormData(prev => ({
      ...prev,
      [section]: {
        ...prev[section],
        [field]: !prev[section][field]
      }
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  return (
    <div className="tab5-container tab-container">
      <h2>Onay ve İmza</h2>
      <p>
        BU FORMDA BELİRTMİŞ OLDUĞUM BİLGİLERİN DOĞRULUĞUNU VE KUYAM DENEY HİZMETİ
        SÖZLEŞMESİ'NDEKİ HÜKÜMLERİ AYNEN KABUL ETTİĞİMİ BEYAN EDERİM.
      </p>

      <h3>Saklama Koşulları</h3>
      <textarea
        name="storageConditions"
        value={formData.storageConditions}
        onChange={handleInputChange}
        className="renkli-textarea"
      />

      <h3>Artan Numune İade Tercihi</h3>
      <label>
        <input
          type="checkbox"
          checked={formData.returnPreference.want}
          onChange={() => handleCheckboxChange('returnPreference', 'want')}
        />
        İstiyorum
      </label>
      <label>
        <input
          type="checkbox"
          checked={formData.returnPreference.dontWant}
          onChange={() => handleCheckboxChange('returnPreference', 'dontWant')}
        />
        İstemiyorum
      </label>

      <div className="signature">
        <p>Yetkili/Proje Yürütücüsü Adı Soyadı ve İmza</p>
        <div className="signature-line"></div>
      </div>
    </div>
  );
};

export default Tab5;
