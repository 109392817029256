import React from 'react';
import { X } from 'lucide-react';
import './SampleInfoModal.css';

const SampleInfoModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="sample-info-modal-overlay">
      <div className="sample-info-modal-container">
        <div className="sample-info-modal-header">
          <h2 className="sample-info-modal-title">Bilgilendirme</h2>
          <button 
            className="sample-info-modal-close"
            onClick={onClose}
          >
            <X size={24} />
          </button>
        </div>
        
        <div className="sample-info-content">
          <div className="sample-info-image-container">
            <img 
              src="/images/sample-types.png" 
              alt="Numune tipleri görseli"
              className="sample-info-image"
            />
            <div className="sample-info-text">
              <p className="info-header">
                Dikkat: Bazı analiz ve çalışmalar
              </p>
              <p>
                <span className="text-red">paketlenmiş olarak gelen numuneye,</span><br/>
                <span className="text-green">bazıları da her bir numuneye</span><br/>
                <span>uygulanacak şekilde fiyatlandırılır.</span>
              </p>
            </div>
          </div>

          <div className="sample-info-notice">
            <p className="notice-red">
              Paket numunelere yaptırılacak analizler için miktar alanına 1 yazılmalıdır.
              Söz konusu analizler aşağıda listelenmiştir.
            </p>
          </div>

          <div className="analysis-list">
            <ul>
              <li>
                <span className="analysis-name">EBSD Nokta Analizi ve Haritalama</span>
                <span className="lab-name">(ELEKTRON MİKROSKOBU LABORATUVARI)</span>
              </li>
              <li>
                <span className="analysis-name">SEM-Katodolüminesans (SEM-CL)</span>
                <span className="lab-name">(ELEKTRON MİKROSKOBU LABORATUVARI)</span>
              </li>
              <li>
                <span className="analysis-name">Saf Su</span>
                <span className="lab-name">(KİMYASAL ANALİZ LABORATUVARI)</span>
              </li>
              <li>
                <span className="analysis-name">Ultra Saf Su</span>
                <span className="lab-name">(KİMYASAL ANALİZ LABORATUVARI)</span>
              </li>
              <li>
                <span className="analysis-name">FE-SEM Görüntüleme (EDX, ETD, TLD, DBS, LVD, GAD, Helix)</span>
                <span className="lab-name">(ELEKTRON MİKROSKOBU LABORATUVARI)</span>
              </li>
              <li>
                <span className="analysis-name">SEM-EBIC Dedektörü</span>
                <span className="lab-name">(ELEKTRON MİKROSKOBU LABORATUVARI)</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SampleInfoModal;