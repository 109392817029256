import React from 'react';
import './InternshipProcedures.css';

const InternshipProcedures = () => {
  return (
    <div className="internship-container">
      <div className="content-wrapper">
        <h1 className="main-title">
          Ulusal Staj Programı Kapsamında Staj Yapacak Öğrencilerin Dikkatine !!
        </h1>

        <div className="info-card">
          <div className="card-content">
            <p className="description">
              Gençlerimizin fırsat eşitliği çerçevesinde ve liyakat esaslarına uygun olarak kamu kurumları ve özel sektör 
              kuruluşlarınca sunulan staj olanaklarından faydalanmasını sağlamak adına Cumhurbaşkanlığı İnsan Kaynakları Ofisi 
              tarafından Ulusal Staj Programı (USP) başlatılmıştır.
            </p>

            <div className="links-section">
              <div className="link-item">
                <p>İşveren kuruluşun KSBÜ olduğu durumdaki staj iş akış şeması için</p>
                <a href="https://testksbu.ksbu.edu.tr/duyuru_medya/uploads/183/1836697a81c671f5_İşveren%20Kuruluş%20KSBÜ%20ise.pdf" className="action-link">tıklayınız.</a>
              </div>

              <div className="link-item">
                <p>İşveren kuruluşun diğer kamu kurumları olduğu durumdaki staj iş akış şeması için</p>
                <a href="https://testksbu.ksbu.edu.tr/duyuru_medya/uploads/183/1836697a868057c2_İşveren%20Kuruluş%20Diğer%20Kamu%20Kurumları%20İse.pdf" className="action-link">tıklayınız.</a>
              </div>

              <div className="link-item">
                <p>Cumhurbaşkanlığı İnsan Kaynakları Ofisi Staj Seferbirliği Uygulama Yönergesi için</p>
                <a href="https://bidb.ksbu.edu.tr/duyuru_medya/uploads/183/files/Staj_Seferbirligi_Uygulama_Yoenergesi(2).pdf" className="action-link">tıklayınız.</a>
              </div>
            </div>
          </div>
        </div>

        <div className="announcement-section">
          <h2>Duyurular</h2>
          <div className="announcement-item">
            <h3>Ulusal Staj Programı Kapsamında Staj Yapacak Öğrencilerin Dikkatine !!</h3>
            <p>21 Temmuz 2024, Pazar</p>
          </div>
          <a href="#" className="view-all">Tümü</a>
        </div>
      </div>
    </div>
  );
};

export default InternshipProcedures;