// src/pages/Purpose/Purpose.js
import React from 'react';
import './Purpose.css';

const Purpose = () => {
    return (
        <div className="purpose-container">
            <div className="purpose-header">
                <h1>Kuruluş Amacımız</h1>
                <p className="purpose-intro">
                    KUYAM'ın (Kütahya Sağlık Bilimleri Üniversitesi Merkezi Araştırma Laboratuvarı Uygulama ve Araştırma Merkezi) kuruluş amacı, sağlık bilimleri alanında ülkemize katma değer sağlayacak yerli ve milli teknolojilerin geliştirilmesi ve uluslararası alanda rekabet edebilir ürünlerin ortaya çıkarılmasıdır.
                </p>
            </div>

            <div className="purpose-sections">
                <section className="purpose-section">
                    <h2>Araştırma ve Geliştirme</h2>
                    <p>KUYAM, sağlık ve mühendislik alanlarında AR-GE ve ÜR-GE faaliyetleri yürüterek disiplinler arası entegrasyonu sağlamayı hedeflemektedir. Temel ve Klinik Tıp Bilimleri ile Mühendislik ve Doğa Bilimleri alanlarında bilimsel araştırma projeleri hazırlamak ve yürütmek de merkezimizin öncelikli amaçları arasındadır.</p>
                </section>

                <section className="purpose-section">
                    <h2>Eğitim ve İnsan Kaynakları Geliştirme</h2>
                    <p>Merkezimiz, multidisipliner bir araştırma ortamı oluşturarak insan kaynakları ve varlık değerlerini etkin ve verimli kullanmayı amaçlamaktadır. Ayrıca, laboratuvar ve teknolojik donanım kullanım eğitimleri vererek, yüksek lisans, doktora ve doktora sonrası eğitim programlarına altyapı sağlamayı hedeflemektedir.</p>
                </section>

                <section className="purpose-section">
                    <h2>Uluslararası İşbirliği ve Rekabet</h2>
                    <p>KUYAM, uluslararası düzeyde araştırmacı akademisyen, uzman ve öğrenciler için bilimsel araştırma ve çalışma ortamı sağlamayı amaçlamaktadır. Ulusal ve uluslararası düzeyde araştırma merkezleri, kurum ve kuruluşlar ile işbirliği yaparak, global ölçekte rekabet gücümüzü artırmayı hedeflemekteyiz.</p>
                </section>

                <section className="purpose-section">
                    <h2>Bölgesel Kalkınma ve Sanayi İşbirliği</h2>
                    <p>Merkezimiz, bölgemizde sektörel sanayinin kurulması, geliştirilmesi ve rekabet gücünün arttırılması amacıyla inovatif araştırma ve geliştirme altyapısı kurmayı ve bilimsel danışmanlık hizmeti sağlamayı amaçlamaktadır.</p>
                </section>

                <section className="purpose-section">
                    <h2>Sağlık Hizmetlerinde İnovasyon</h2>
                    <p>KUYAM, sağlık hizmetlerinin niteliği, verimliliği ve ulaşılabilirliğini arttırmak amacıyla yenilikçi ürünler geliştirmeyi hedeflemektedir.</p>
                </section>

                <section className="purpose-section">
                    <h2>Bilim Kültürünün Yaygınlaştırılması</h2>
                    <p>Merkezimiz, genç nesillerde araştırma ve bilim kültürünün gelişmesine katkıda bulunmayı amaçlamaktadır.</p>
                </section>
            </div>

            <div className="purpose-footer">
                <p>Bu amaçlar doğrultusunda KUYAM, ülkemizin sağlık bilimleri alanında öncü bir araştırma ve geliştirme merkezi olmayı hedeflemektedir.</p>
            </div>
        </div>
    );
};

export default Purpose;