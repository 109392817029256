import React from 'react';
import './YonetimKurulu.css';

const YonetimKurulu = () => {
  const boardMembers = [
    {
      title: "Prof. Dr.",
      name: "Ahmet TEKİN",
      role: "YÖNETİM KURULU BAŞKANI"
    },
    {
      title: "Prof. Dr.",
      name: "Emel KOÇAK",
      role: "YÖNETİM KURULU ÜYESİ",
      info: "Tıp Dekanı"
    },
    {
      title: "Prof. Dr.",
      name: "Azmi YERLİKAYA",
      role: "YÖNETİM KURULU ÜYESİ",
      info: "Temel Bilimler"
    },
    {
      title: "Prof. Dr.",
      name: "Bekir ARAS",
      role: "YÖNETİM KURULU ÜYESİ",
      info: "Cerrahi Bilim"
    },
    {
      title: "Doç. Dr.",
      name: "Safa KURNAZ",
      role: "YÖNETİM KURULU ÜYESİ",
      info: "Diş Hekimliği"
    },
    {
      title: "Dr. Öğr. Üyesi",
      name: "Dursun CEYLAN",
      role: "YÖNETİM KURULU ÜYESİ",
      info: "Dahili Bilim"
    },
    {
      title: "Doç. Dr.",
      name: "Fatih KAR",
      role: "YÖNETİM KURULU ÜYESİ",
      info: "Koordinatör"
    }
  ];

  return (
    <div className="yk-container">
      <div className="yk-content">
        <div className="yk-header-card">
          <h1 className="yk-title">YÖNETİM KURULU</h1>
          <div className="yk-subtitle">KUYAM (UYGULAMA VE ARAŞTIRMA MERKEZİ)</div>
          <div className="member-count">(7 Üye)</div>
        </div>

        <div className="board-container">
          {boardMembers.map((member, index) => (
            <div key={index} className="board-member-card">
              <div className="member-header">
                <div className="member-title">{member.title}</div>
                <div className="member-name">{member.name}</div>
              </div>
              <div className="member-role">{member.role}</div>
              {member.info && <div className="member-info">{member.info}</div>}
            </div>
          ))}
        </div>

        <div className="board-info-card">
          <h2 className="info-title">Yönetim Kurulu Hakkında</h2>
          <p className="info-text">
            KUYAM Yönetim Kurulu, merkezimizin en yüksek karar organıdır. 
            Farklı bilim dallarından seçkin akademisyenlerden oluşan kurulumuz, 
            merkezimizin stratejik kararlarını alır ve faaliyetlerini yönlendirir.
          </p>
          
          <div className="duties-section">
            <h3 className="duties-title">Yönetim Kurulunun Görevleri:</h3>
            <ul className="duties-list">
              <li>Merkezin çalışmalarıyla ilgili plan ve programların hazırlanmasını ve uygulanmasını sağlamak</li>
              <li>Merkezin yatırım, program ve bütçe tasarısını hazırlamak</li>
              <li>Araştırma, eğitim-öğretim ve uygulama alanları ile ilgili kararlar almak</li>
              <li>Merkezin bünyesinde kurulacak bilimsel çalışma gruplarını oluşturmak</li>
              <li>İlgili mevzuatla verilen diğer görevleri yerine getirmek</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default YonetimKurulu;