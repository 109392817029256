// src/components/DanismaKurulu/DanismaKurulu.js
import React from 'react';
import './DanismaKurulu.css';

const DanismaKurulu = () => {
  const advisoryMembers = [
    {
      title: "Prof. Dr.",
      name: "Ahmet TEKİN",
      role: "MÜDÜR (REKTÖR UHDESİNDE)"
    },
    {
      title: "Doç. Dr.",
      name: "Fatih KAR",
      role: "KORDİNATÖR"
    },
    {
      title: "Doç. Dr.",
      name: "Cansu ÖZBAYER",
      role: "TIBBİ BİYOLOJİ"
    },
    {
      title: "Doç. Dr.",
      name: "Derya BERİKTEN",
      role: "MİKROBİYOLOJİ"
    },
    {
      title: "Doç. Dr.",
      name: "Ayşe KOÇAK SEZGİN",
      role: "TIBBİ BİYOKİMYA"
    },
    {
      title: "Doç. Dr.",
      name: "Meliha KOLDEMİR GÜNDÜZ",
      role: "TIBBİ BİYOLOJİ, MOLEKÜLER BİYOLOJİ"
    },
    {
      title: "Dr. Öğr. Üyesi",
      name: "Neslihan MERİÇ",
      role: "GENETİK VE BİYOMÜHENDİSLİK, KÖK HÜCRE"
    },
    {
      title: "Dr. Öğr. Üyesi",
      name: "Ezgi KAR",
      role: "TIBBİ BİYOKİMYA"
    },
    {
      title: "Öğr. Gör.",
      name: "Emirhan BOZOĞLAN",
      role: "BİYOMÜHENDİSLİK"
    },
    {
      title: "Öğr. Gör.",
      name: "N. Feyza ÜSTÜNEL",
      role: "GIDA BİLİMİ VE TEKNOLOJİSİ, TIBBİ BİYOLOJİ"
    }
  ];

  return (
    <div className="danisma-container">
      <div className="danisma-content">
        <div className="danisma-header-card">
          <h1 className="danisma-title">KUYAM</h1>
          <h2 className="danisma-subtitle">(UYGULAMA VE ARAŞTIRMA MERKEZİ)</h2>
          <h2 className="danisma-subtitle-2">DANIŞMA KURULU</h2>
        </div>

        <div className="members-card">
          <div className="table-responsive">
            <table className="members-table">
              <thead>
                <tr>
                  <th>Ünvan, Ad Soyad</th>
                  <th>Görev/Alan</th>
                </tr>
              </thead>
              <tbody>
                {advisoryMembers.map((member, index) => (
                  <tr key={index} className={index % 2 === 0 ? 'even' : 'odd'}>
                    <td>
                      <div className="member-title">{member.title}</div>
                      <div className="member-name">{member.name}</div>
                    </td>
                    <td className="member-role">{member.role}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <div className="info-card">
          <h3 className="info-title">Danışma Kurulu Hakkında</h3>
          <p className="info-text">
            KUYAM Danışma Kurulu, merkezimizin bilimsel ve teknolojik çalışmalarında 
            danışmanlık yapan, stratejik kararlarında yol gösteren değerli 
            akademisyenlerden oluşmaktadır. Her biri kendi alanında uzman olan kurul 
            üyelerimiz, merkezimizin gelişimine ve hedeflerine ulaşmasına katkı 
            sağlamaktadır.
          </p>
          <div className="duties">
            <h4 className="duties-title">Danışma Kurulunun Görevleri:</h4>
            <ul className="duties-list">
              <li>Merkezin faaliyet alanlarıyla ilgili önerilerde bulunmak</li>
              <li>Araştırma projeleri için değerlendirme ve öneriler sunmak</li>
              <li>Eğitim programları hakkında görüş bildirmek</li>
              <li>Ulusal ve uluslararası işbirliği olanaklarını değerlendirmek</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DanismaKurulu;