import React from 'react';
import './Analizler.css';

function Analizler() {
    const analyses = [
        "qPCR gen ekspresyon analizi",
        "Western blotting-Protein ifade analizi",
        "ELISA-Protein ifade analizi",
        "Oksidatif stress analizleri (SOD,MDA, TAS, TOS,OSI, Katalaz,)",
        "Apoptotik Markerler (Caspase 3/9,Cytocrome c)",
        "Inflamasyon markerlerı",
        "Sitotoksisite",
        "Genotoksisite",
        "Immünhistokimya",
        "Histokimya",
        "İmmünfloresans boyama",
        "TUNNEL boyama",
        "DNA fragmantasyon",
        "Hücre kültürü uygulamaları",
        "Primer hücre kültürü uygulamaları",
        "İskemi hayvan modelleri",
        "Diyabetik hayvan modelleri",
        "Nörodejenaratif hayvan modelleri",
        "İlaç/doz çalışmaları",
        "Etken madde analizleri",
        "Eser element analizleri",
        "Microarray",
        "iCELLigence hücre kültürü sistemi",
        "Konvansiyonel PCR",
        "Doku, kan ve hücreden DNA izolasyon yöntemleri",
        "DNA saflığı ve miktarının ölçülmesi",
        "Doku, kan ve hücreden RNA izolasyon yöntemleri",
        "Işık mikroskobu ve stereo mikroskop uygulamaları",
        "Floresans mikroskop ile görüntüleme",
        "Biyoinformatik araçlar kullanılarak DNA dizi veri analizleri",
        "Mikroorganizmaların moleküler taksonomisi",
        "Fungusların filogenetik analizleri",
        "Fungusların morfolojik tanılanması",
        "Mikrobiyal biyoüretim yöntemleri",
        "Antimikrobiyal etki analizleri",
        "Mikrobiyal çeşitliliğin belirlenmesi",
        "Mikrobiyal proses teknolojileri ve optimizasyonu",
        "3 Boyutlu Modelleme",
        "3 Boyutlu Baskılama",
        "Biyobaskılama",
        "Biyomalzemeler",
        "Bitki ekstraktlarının bileşen analizleri",
        "Biyolojik Materyallerde Uyuşturucu ve Uyarıcı Madde Analizleri",
        "Narkotik Madde Analizi",
        "Pestisit Analizi",
        "Farmasötik Analizi",
        "Patlayıcı Madde Analizi",
        "Organik Ateşli Silah Atış Artıkları Analizi"
    ];

    return (
        <div className="analyses-container">
            <div className="analyses-content">
                <h1 className="analyses-title">Analizler</h1>
                
                <div className="analyses-description">
                    <p>
                        KUYAM Laboratuvarlarında yapılan analizler aşağıda listelenmiştir. 
                        Detaylı bilgi için iletişime geçiniz.
                    </p>
                </div>

                <div className="analyses-grid">
                    {analyses.map((analysis, index) => (
                        <div key={index} className="analysis-item">
                            <span className="analysis-number">{index + 1}</span>
                            <span className="analysis-name">{analysis}</span>
                        </div>
                    ))}
                </div>

                <div className="last-update">
                    <p>Son Güncelleme Tarihi: 01 Ağustos 2024, Perşembe</p>
                </div>
            </div>
        </div>
    );
}

export default Analizler;