import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation, Navigate } from 'react-router-dom';
import TopBanner from './components/TopBanner/TopBanner';
import NavBar from './components/NavBar/NavBar';
import SliderMenu from './components/SliderMenu/SliderMenu';
import MainLayout from './components/MainLayout/MainLayout';
import Regulations from './pages/Regulations/Regulations';
import Purpose from './pages/Purpose/Purpose';
import MissionVision from './pages/MissionVision/MissionVision';
import Team from './pages/Team/Team';
import Videos from './pages/Videos/Videos';
import KVKK from './pages/KVKK/KVKK';
import AnalysisProcess from './pages/AnalysisProcess/AnalysisProcess';

import ActivityReports24 from './pages/ActivityReports/ActivityReports24';
import ActivityReports23 from './pages/ActivityReports/ActivityReports23';
import ActivityReports22 from './pages/ActivityReports/ActivityReports22';
import ActivityReports21 from './pages/ActivityReports/ActivityReports21';
import ActivityReports20 from './pages/ActivityReports/ActivityReports20';

import LabEquipment from './pages/LabEquipment/LabEquipment';
import ServiceCharges from './pages/ServiceCharges/ServiceCharges'; 
import KuyamBasvuru from './pages/KuyamBasvuru/KuyamBasvuru';
import KuyamLogin from './pages/KuyamBasvuru/KuyamLogin';
import KuyamForgotPassword from './pages/KuyamBasvuru/KuyamForgotPassword';
import KuyamResetPassword from './pages/KuyamBasvuru/KuyamResetPassword';
import AltyapiProjeleri from './pages/AltyapiProjeleri/AltyapiProjeleri';
import EgitimlerSeminerler from './pages/EgitimlerSeminerler/EgitimlerSeminerler';
import InternshipProcedures from './pages/InternshipProcedures/InternshipProcedures';

import AllAnnouncements from './pages/AllAnnouncements/AllAnnouncements';
import BiyokimyaLab from './pages/BiyokimyaLab/BiyokimyaLab';
import KokHucreLab from './pages/KokHucreLab/KokHucreLab';
import MolekulerLab from './pages/MolekulerLab/MolekulerLab';
import BiyoInovatifLab from './pages/BiyoInovatifLab/BiyoInovatifLab';
import Analizler from './pages/Analizler/Analizler';
import KuyamDashboard from './pages/kuyam-basvuru/dashboard/Dashboard';
import Footer2Up from './components/Footer2Up/Footer2Up';
import FooterUp from './components/FooterUp/FooterUp';
import Footer from './components/Footer/Footer';
import YonetimKurulu from './components/YonetimKurulu/YonetimKurulu';
import DanismaKurulu from './components/DanismaKurulu/DanismaKurulu';
import Yonetim from './components/Yonetim/Yonetim';
import OrganizasyonSema from './components/OrganizasyonSema/OrganizasyonSema';

import EtikKurulIzinForm from './pages/Forms/EtikKurulIzinForm';
import BiyokimyaForm from './pages/Forms/BiyokimyaForm';
import KokHucreForm from './pages/Forms/KokHucreForm';
import MolekulerTipForm from './pages/Forms/MolekulerTipForm';
import BiyoInovatifForm from './pages/Forms/BiyoInovatifForm';

import FormTabs from './pages/Forms/Biyokimya/FormTabs';
import PDFView from './pages/Forms/Biyokimya/pdf-view';

import './App.css';

// Route koruma için özel bileşen
const PrivateRoute = ({ children }) => {
  const isAuthenticated = localStorage.getItem('token'); // JWT token kontrolü
  const location = useLocation();

  if (!isAuthenticated) {
    // Kullanıcı giriş yapmamışsa login sayfasına yönlendir ve mevcut konumu state'e kaydet
    return <Navigate to="/kuyam-basvuru/login" state={{ from: location }} />;
  }

  return children;
};

// Slider kontrolü için özel bileşen
const AppContent = () => {
  const location = useLocation();
  const isHomePage = location.pathname === '/';
  
  // KUYAM başvuru sayfaları için özel stil kontrolü
  const isKuyamPage = location.pathname.includes('/kuyam-basvuru');

  return (
    <div className="app">
      {!isKuyamPage && <TopBanner />}
      {!isKuyamPage && <NavBar />}
      {isHomePage && <SliderMenu />}
      <div className={`app-content ${isKuyamPage ? 'kuyam-content' : ''}`}>
        <Routes>
          {/* Ana Sayfa */}
          <Route path="/" element={<MainLayout />} />

          {/* Merkezimiz */}
          <Route path="/merkezimiz/yonetmelik" element={<Regulations />} />
          <Route path="/merkezimiz/kurulus-amacimiz" element={<Purpose />} />
          <Route path="/merkezimiz/misyon-vizyon" element={<MissionVision />} />
          <Route path="/merkezimiz/calisanlarimiz" element={<Team />} />
          <Route path="/merkezimiz/tanitim-videolari" element={<Videos />} />
          <Route path="/merkezimiz/kvkk" element={<KVKK />} />
          <Route path="/merkezimiz/analiz-basvuru-sureci" element={<AnalysisProcess />} />

          {/* Laboratuvarlar */}
          <Route path="/hizmet-bedelleri" element={<ServiceCharges />} />
          <Route path="/laboratuvar-cihazlari" element={<LabEquipment />} />
          <Route path="/biyokimya-laboratuvari" element={<BiyokimyaLab />} />
          <Route path="/kok-hucre-ve-rejeneratif-tip-laboratuvari" element={<KokHucreLab />} />
          <Route path="/hucresel-ve-molekuler-tip-laboratuvari" element={<MolekulerLab />} />
          <Route path="/biyo-inovatif-teknoloji-laboratuvari" element={<BiyoInovatifLab />} />

          {/* KUYAM Başvuru Routes */}
          <Route path="/kuyam-basvuru" element={<KuyamBasvuru />} />
          <Route path="/kuyam-basvuru/login" element={<KuyamLogin />} />
          <Route path="/kuyam-basvuru/forgot-password" element={<KuyamForgotPassword />} />
          <Route path="/kuyam-basvuru/reset-password" element={<KuyamResetPassword />} />

          // In your routes configuration:
          <Route path="/yonetim/yonetim-kurulu" element={<YonetimKurulu />} />
          <Route path="/yonetim/danisma-kurulu" element={<DanismaKurulu />} />
          <Route path="/yonetim" element={<Yonetim />} />
          <Route path="/yonetim/organizasyon-semasi" element={<OrganizasyonSema />} />

          {/* Korumalı Dashboard Route */}
          <Route 
            path="/kuyam-basvuru/dashboard/*" 
            element={
              <PrivateRoute>
                <KuyamDashboard />
              </PrivateRoute>
            } 
          />

          {/* Diğer Sayfalar */}
          <Route path="/arastirmalar/altyapi-projelerimiz" element={<AltyapiProjeleri />} />
          <Route path="/arastirmalar/egitimler-seminerler" element={<EgitimlerSeminerler />} />
          <Route path="/arastirmalar/staj-islemleri" element={<InternshipProcedures />} />

          <Route path="/arastirmalar/reports/2024" element={<ActivityReports24 />} />
          <Route path="/arastirmalar/reports/2023" element={<ActivityReports23 />} />
          <Route path="/arastirmalar/reports/2022" element={<ActivityReports22 />} />
          <Route path="/arastirmalar/reports/2021" element={<ActivityReports21 />} />
          <Route path="/arastirmalar/reports/2020" element={<ActivityReports20 />} />
          
          <Route path="/duyurular" element={<AllAnnouncements />} />
          <Route path="/analizler" element={<Analizler />} />

          <Route path="/forms/etikkurul" element={<EtikKurulIzinForm />} />
          
          <Route path="/forms/kok-hucre" element={<KokHucreForm />} />
          <Route path="/forms/molekuler-tip" element={<MolekulerTipForm />} />
          <Route path="/forms/biyo-inovatif" element={<BiyoInovatifForm />} />

          {/* Biyokimya Formu yeni yapısı */}
          <Route path="/forms/biyokimya" element={<FormTabs />} />
          
          {/* PDF Görüntüleme sayfası */}
          <Route path="/forms/biyokimya/pdf-view" element={<PDFView />} />
        </Routes>
      </div>
      {!isKuyamPage && (
        <>
          <Footer2Up />
          <FooterUp />
          <Footer />
        </>
      )}
    </div>
  );
};

const App = () => {
  return (
    <Router>
      <AppContent />
    </Router>
  );
};

export default App;