import React, { useState } from 'react';
import './Survey.css';

const Survey = () => {
  const [formData, setFormData] = useState({
    serviceCount: '',
    nameInstitution: '',
    contactInfo: '',
    ratings: {},
    reasons: [],
    comparison: '',
    suggestions: ''
  });

  // Anket soruları
  const questions = [
    'Aldığım hizmetlerle ilgili yapılan ön bilgilendirme (web sayfası, proforma fatura, analiz konusunda bilgilendirme, numune kabul vb.) yeterliydi.',
    'Başvuru talebimin cevaplandırılma süresi yeterliydi.',
    'Aldığım hizmetler belirtilen sürede gerçekleşti/tamamlandı.',
    'Gerektiğinde laboratuvar personeline (şahsen, e-posta, telefon ile) ulaşabiliyorum.',
    'Test/analizlerde kullanılan metotlar istek ve ihtiyaçlarımı karşılıyor.',
    'Analiz raporları yeterince anlaşılır ve istediğim bilgileri içeriyor.',
    'Analiz sonuçlarına güveniyorum.',
    'Laboratuvar personeli test/analiz konusunda teknik olarak yeterli, bilgili ve tecrübelidir.',
    'Verilen hizmetleri kaliteli, güvenilir ve yeterli buluyorum.',
    'Hizmet veren personelin iş sırasındaki davranış ve tutumlarından memnunum.',
    'Genel fiyat politikasından memnunum.',
    'Laboratuvarınızı diğer kurum ve kuruluşlara tavsiye ederim.'
  ];

  const reasonOptions = [
    'Kalitesinin uygunluğu',
    'Fiyat uygunluğu',
    'Sonuçların zamanında teslimi',
    'Aynı şehirde olmak',
    'Güvenilirliğiniz',
    'Diğer'
  ];

  const comparisonOptions = [
    'Başka kuruluşlarla çalışmadım.',
    'Diğer kuruluşlara göre iyi sayılabilir.',
    'Diğer kuruluşlara göre mükemmel.',
    'Diğer kuruluşlara göre yetersiz.',
    'Diğer kuruluşlara göre kötü.',
    'Aynı',
    'Diğer'
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleRatingChange = (questionIndex, value) => {
    setFormData(prev => ({
      ...prev,
      ratings: {
        ...prev.ratings,
        [questionIndex]: value
      }
    }));
  };

  const handleReasonChange = (value) => {
    setFormData(prev => {
      const reasons = prev.reasons.includes(value)
        ? prev.reasons.filter(r => r !== value)
        : [...prev.reasons, value];
      return { ...prev, reasons };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form data:', formData);
  };

  const handleClear = () => {
    setFormData({
      serviceCount: '',
      nameInstitution: '',
      contactInfo: '',
      ratings: {},
      reasons: [],
      comparison: '',
      suggestions: ''
    });
  };

  return (
    <div className="kuyam_survey_container">
      <h1 className="kuyam_survey_title">Merkezi Araştırma Laboratuvarı Araştırmacı Memnuniyet Anketi</h1>
      
      <form className="kuyam_survey_form" onSubmit={handleSubmit}>
        {/* Temel Bilgiler */}
        <div className="kuyam_survey_basic_info">
          <div className="kuyam_survey_field">
            <label className="kuyam_survey_label">
              <span className="required-field">(*)</span>
              Alınan Test/Analiz Hizmeti Sayısı
            </label>
            <input
              type="text"
              name="serviceCount"
              value={formData.serviceCount}
              onChange={handleInputChange}
              className="kuyam_survey_input"
              required
            />
          </div>

          <div className="kuyam_survey_field">
            <label className="kuyam_survey_label">
              <span className="required-field">(*)</span>
              Ad Soyad / Kurum Adı
            </label>
            <input
              type="text"
              name="nameInstitution"
              value={formData.nameInstitution}
              onChange={handleInputChange}
              className="kuyam_survey_input"
              required
            />
          </div>

          <div className="kuyam_survey_field">
            <label className="kuyam_survey_label">
              <span className="required-field">(*)</span>
              Telefon/Fax/E-Posta
            </label>
            <input
              type="text"
              name="contactInfo"
              value={formData.contactInfo}
              onChange={handleInputChange}
              className="kuyam_survey_input"
              required
            />
          </div>
        </div>

        {/* Değerlendirme Bölümü */}
        <div className="kuyam_survey_rating_section">
          <div className="kuyam_survey_rating_header">
            <div className="kuyam_survey_header_cell">Değerlendirme Soruları</div>
            <div className="kuyam_survey_header_cell">Kesinlikle Katılmıyorum</div>
            <div className="kuyam_survey_header_cell">Katılmıyorum</div>
            <div className="kuyam_survey_header_cell">Kısmen Katılıyorum</div>
            <div className="kuyam_survey_header_cell">Katılıyorum</div>
            <div className="kuyam_survey_header_cell">Kesinlikle Katılıyorum</div>
          </div>

          {questions.map((question, index) => (
            <div key={index} className="kuyam_survey_question_group">
              <div className="kuyam_survey_question">{question}</div>
              {[1, 2, 3, 4, 5].map((value) => (
                <div key={value} className="kuyam_survey_radio_cell">
                  <input
                    type="radio"
                    name={`question_${index}`}
                    value={value}
                    checked={formData.ratings[index] === value}
                    onChange={() => handleRatingChange(index, value)}
                    className="kuyam_survey_radio_input"
                    required
                  />
                </div>
              ))}
            </div>
          ))}
        </div>

        {/* Tercih Nedenleri */}
        <div className="kuyam_survey_section">
          <label className="kuyam_survey_label">
            <span className="required-field">(*)</span>
            Laboratuvarımızı tercih etmenizin nedenlerinden en az iki tanesini belirtir misiniz?
          </label>
          <div className="kuyam_survey_checkbox_group">
            {reasonOptions.map((option, index) => (
              <label key={index} className="kuyam_survey_checkbox_label">
                <input
                  type="checkbox"
                  checked={formData.reasons.includes(option)}
                  onChange={() => handleReasonChange(option)}
                  className="kuyam_survey_checkbox_input"
                />
                <span>{option}</span>
              </label>
            ))}
          </div>
        </div>

        {/* Karşılaştırma */}
        <div className="kuyam_survey_section">
          <label className="kuyam_survey_label">
            <span className="required-field">(*)</span>
            Laboratuvarımızın analiz hizmetlerini diğer kuruluşlarla kıyaslar mısınız?
          </label>
          <select
            name="comparison"
            value={formData.comparison}
            onChange={handleInputChange}
            className="kuyam_survey_select"
            required
          >
            <option value="">Seçiniz</option>
            {comparisonOptions.map((option, index) => (
              <option key={index} value={option}>{option}</option>
            ))}
          </select>
        </div>

        {/* Öneriler */}
        <div className="kuyam_survey_section">
          <label className="kuyam_survey_label">
            <span className="required-field">(*)</span>
            Laboratuvarımızın Analiz hizmetleri ile ilgili beklenti ve önerileriniz nelerdir?
          </label>
          <textarea
            name="suggestions"
            value={formData.suggestions}
            onChange={handleInputChange}
            className="kuyam_survey_textarea"
            required
          />
        </div>

        {/* Butonlar */}
        <div className="kuyam_survey_button_container">
          <button
            type="button"
            onClick={handleClear}
            className="kuyam_survey_clear_btn"
          >
            Anketi Temizle ve Çık
          </button>
          <button
            type="submit"
            className="kuyam_survey_submit_btn"
          >
            Gönder
          </button>
        </div>
      </form>
    </div>
  );
};

export default Survey;