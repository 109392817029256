// src/pages/kuyam-basvuru/dashboard/Dashboard.jsx
import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import DashboardLayout from './DashboardLayout';

// Doğrudan import edelim, lazy loading'i daha sonra ekleyebiliriz
import Home from './pages/Home';
import NewOffers from './pages/NewOffers';
import CurrentOffers from './pages/CurrentOffers';
import Budget from './pages/Budget';
import Payments from './pages/Payments';
import DeviceCalendar from './pages/DeviceCalendar';
import DeviceAppointments from './pages/DeviceAppointments';
import Profile from './pages/Profile';
import Sharing from './pages/Sharing';
import Survey from './pages/Survey';

const Dashboard = () => {
  return (
    <DashboardLayout>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/new-offers" element={<NewOffers />} />
        <Route path="/current-offers" element={<CurrentOffers />} />
        <Route path="/budget" element={<Budget />} />
        <Route path="/payments" element={<Payments />} />
        <Route path="/device-calendar" element={<DeviceCalendar />} />
        <Route path="/device-appointments" element={<DeviceAppointments />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/sharing" element={<Sharing />} />
        <Route path="/survey" element={<Survey />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </DashboardLayout>
  );
};

export default Dashboard;