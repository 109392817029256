// src/components/Yonetim/Yonetim.js
import React from 'react';
import './Yonetim.css';

const Yonetim = () => {
  const managementTeam = [
    {
      position: "MÜDÜR",
      title: "Prof. Dr.",
      name: "AHMET TEKİN",
      info: "(REKTÖR UHDESİNDE)"
    }
  ];

  return (
    <div className="yonetim-page-container">
      <div className="yonetim-content-wrapper">
        <div className="yonetim-header-card">
          <h1 className="yonetim-main-title">YÖNETİM</h1>
          <div className="yonetim-subtitle">KUYAM (UYGULAMA VE ARAŞTIRMA MERKEZİ)</div>
        </div>

        <div className="management-section">
          <div className="management-card">
            <div className="card-title">Yönetim Kadrosu</div>
            <div className="management-grid">
              {managementTeam.map((member, index) => (
                <div key={index} className="member-card">
                  <div className="member-position">{member.position}</div>
                  <div className="member-name">
                    <span className="member-title">{member.title}</span>
                    {member.name}
                  </div>
                  {member.info && <div className="member-info">{member.info}</div>}
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="responsibilities-section">
          <div className="responsibilities-card">
            <h2 className="section-title">Yönetimin Görev ve Sorumlulukları</h2>
            <ul className="responsibilities-list">
              <li>Merkezin amaçları doğrultusunda çalışmaların düzenli bir şekilde yürütülmesini sağlamak</li>
              <li>Merkez faaliyetlerinin gerektirdiği görevlendirmeleri yapmak</li>
              <li>Merkez bünyesinde oluşturulan çalışma gruplarının faaliyetlerini düzenlemek ve denetlemek</li>
              <li>Her faaliyet dönemi sonunda merkez faaliyetleriyle ilgili rapor düzenlemek</li>
              <li>Merkezi temsil etmek ve merkezin işleyişiyle ilgili stratejik kararlar almak</li>
              <li>Ulusal ve uluslararası işbirliklerini geliştirmek ve sürdürmek</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Yonetim;