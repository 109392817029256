import React, { useState } from 'react';
import './ServiceCharges.css';

const ServiceCharges = () => {
  const [activeTab, setActiveTab] = useState('discounts');

  const discountRates = [
    { institution: 'KSBÜ', rate: '65%' },
    { institution: 'KSBÜ Dışındaki Üniversiteler (Kamu, Vakıf)', rate: '40%' },
    { institution: 'KSBÜ Teknopark', rate: '30%' },
    { institution: 'Kamu Kurum ve Kuruluşları', rate: '20%' },
    { institution: 'Diğer Üniversitelerdeki Teknoparklar', rate: '15%' },
    { institution: 'İkili İş Birliği Protokolü (ARUM-İLTEM)', rate: '20%' },
  ];

  const labServices = {
    'Genel Kullanım ve Örnek Hazırlama': [
      { name: 'Preperatif Ultrasantrifüj', description: 'Cihaz kullanımı', price: '1300 ₺/Kullanım' },
      { name: 'Rotary Evaporatör', description: '', price: '700 ₺/Numune' },
      { name: 'Soğutmalı Santrifüj', description: 'Cihaz kullanımı', price: '500₺/Kullanım' },
      { name: 'Kurutma Fırını/ETÜV', description: 'Cihaz kullanımı', price: '500₺/Kullanım' },
      { name: 'Otoklav', description: 'Cihaz kullanımı', price: '150₺/Kullanım' },
      { name: 'Santrifüj', description: 'Cihaz kullanımı', price: '100₺/Kullanım' },
      { name: 'Yüksek Basınçlı Hücre Parçalayıcı', description: '', price: '700₺/Numune' },
      { name: 'Çalkalayıcı Bilyeli Öğütücü', description: '', price: '700₺/Numune' },
      { name: 'Hücre Zarı Parçalayıcı 1', description: '', price: '500₺/Numune' },
      { name: 'Soğutmalı Çalkalayıcı İnkübatör', description: 'Cihaz kullanımı', price: '140₺/Saat' },
      { name: 'Çalkalayıcı İnkübatör', description: 'Cihaz kullanımı', price: '100₺/Saat' },
      { name: 'Su Banyosu', description: 'Cihaz kullanımı', price: '100₺/Saat' },
      { name: 'pH tayini', description: '', price: '75₺/saat' },
      { name: 'Distile Su', description: 'Cihaz kullanımı', price: '15₺/Litre' },
      { name: 'Cihaz ve Hizmet Talebi', description: '', price: 'Sorunuz' },
      { name: 'Danışmanlık Talebi', description: '', price: 'Sorunuz' }
    ],
    'Biyokimya': [
          { name: 'ELISA Okuyucu', description: '1 run', price: '170₺' },
          { name: 'ELISA Yıkayıcı', description: '1 run', price: '170₺' },
          { name: 'UV-VIS Spektrofotometre', description: '1 run', price: '100₺' },
          { name: 'Yüksek Hızlı Santrifüj', description: '1 run', price: '1000₺/Kullanım' },
          { name: 'Deney Protokolü', description: 'Sorunuz', price: 'Sorunuz' },
          { name: 'Otoanalizör', description: 'Rutin Biyokimya ve Hematolojik Testler', price: 'Sorunuz' },
          { name: '-80 Dondurucu', description: '6 ay', price: '1000₺' },
          { name: '-80 Dondurucu Ek Süre', description: '6 aydan sonraki her ay için ek', price: '150₺/ay' },
          { name: '-156 Dondurucu', description: 'Cihaz kullanımı', price: '150₺/gün' },
          { name: 'Cihaz ve Hizmet Talebi', description: 'Kit karşılığı', price: 'Sorunuz' },
          { name: 'Danışmanlık Talebi', description: 'Numune kabul – Sonuç rapor', price: 'Sorunuz' }
    ],
    'Hücresel ve Moleküler Tıp': [
      { name: 'cDNA Sentezi', description: 'Kit analizi isteyen kişi/kuruluş tarafından karşılanır', price: '550₺/Numune' },
      { name: 'cDNA Sentezi', description: 'Kit KUYAM tarafından Karşılanır', price: '1250₺/Numune' },
      { name: 'Biyoanalizör', description: 'DNA, RNA, Protein', price: 'Laboratuvar ile iletişime geçiniz' },
      { name: 'Biyoanalizör', description: 'Cihaz Kullanımı', price: '1100₺/Çip' },
      { name: 'DNA Dizi Analizi', description: 'Dizi Analizi Tek Yön (Saflaştırma Hariç)', price: 'Sorunuz' },
      { name: 'DNA Dizi Analizi', description: 'Dizi Analizi Tek Yön (Saflaştırma Dahil)', price: 'Sorunuz' },
      { name: 'Düşük Hacimli Spektrofotometre', description: 'Konsantrasyon Tayini', price: '110₺/Numune' },
      { name: 'Gerçek Zamanlı PCR', description: 'Gen İfadesi Analizi (SYBR Green, ve Hidroliz Probları ile)1', price: '120₺/Kuyucuk + Cihaz Kullanım Ücreti**' },
      { name: 'Gerçek Zamanlı PCR', description: 'SNP ve Mutasyon Analizleri', price: 'Sorunuz' },
      { name: 'Gerçek Zamanlı PCR', description: 'miRNA Analizleri', price: 'Sorunuz' },
      { name: 'Gerçek Zamanlı PCR', description: 'HRM Analizleri', price: 'Sorunuz' },
      { name: 'Gerçek Zamanlı PCR', description: 'Epigenetik Analizler', price: 'Sorunuz' },
      { name: 'Gerçek Zamanlı PCR', description: 'Cihaz Kullanımı', price: '1350₺/Run' },
      { name: 'Gerçek Zamanlı PCR', description: '96 Well Plate', price: '450₺/Adet' },
      { name: 'Gerçek Zamanlı PCR', description: 'Sadece cihaz kullanımı', price: '350₺/Kullanım' },
      { name: 'Hibridizasyon Fırını', description: 'Cihaz Kullanımı2', price: '800₺/Saat' },
      { name: 'Mikroarray', description: 'cRNA Sentezi + Hibridizasyon + Yıkama-Boyama +Tarama3 (Tam Kapsamlı Analiz)', price: '13000₺' },
      { name: 'Mikroarray', description: 'Hibridizasyon + Yıkama-Boyama +Tarama4 (Yarı Kapsamlı Analiz)', price: '6500₺' },
      { name: 'Mikroarray', description: 'Veri analizi için GeneSpring 7.3.1 yazılımı kullanımı (Temel Analiz)', price: '650₺/Saat' },
      { name: 'dPCR', description: 'Cihaz kullanımı', price: 'Sorunuz' },
      { name: 'Nanodrop ile RNA/DNA miktar tayini', description: 'Cihaz kullanımı', price: '100₺/Kullanım' },
      { name: 'Western Blot Semi-Dry Transfer Sistemi', description: 'Cihaz kullanımı', price: '1000₺/Kullanım' },
      { name: 'SDS PAGE/NATIVE PAGE', description: '1,5mm kalınlıkta 6x8cm jel (Gümüş Boyama Tayini ile)', price: '6000₺' },
      { name: 'SDS PAGE/NATIVE PAGE', description: '1,5mm kalınlıkta 6x8cm jel (Comassie Boyama Tayini ile)', price: '3000₺' },
      { name: 'Jel Görüntüleme Sistemi', description: 'Cihaz kullanımı', price: '100₺/Jel' },
      { name: 'Yatay Elektroforez Sistemleri', description: 'Cihaz kullanımı', price: '100₺/Kullanım' },
      { name: 'Yatay Elektroforez Sistemleri', description: 'Jel Hazırlama ve yürütme', price: '100₺/Jel' },
      { name: 'Dikey Elektroforez Sistemleri', description: 'Cihaz kullanımı', price: '100₺/Kullanım' },
      { name: 'Dikey Elektroforez Sistemleri', description: 'Jel Hazırlama ve yürütme', price: '1500₺/Jel' },
      { name: 'Hücre açma, çoğaltma ve yedekleme', description: 'Sarf fiyat hariç', price: '3000₺/hücre' },
      { name: 'iCELLigence', description: 'Ön hazırlık dahil (Hücre açma, çoğaltma, ekme ve madde ekleme) (hücre ve sarf malzemeler araştırmacı tarafından sağlanır)', price: '3000₺' },
      { name: 'iCELLigence', description: 'Cihaz kullanımı', price: '500₺/gün' },
      { name: 'Primer Hücre Kültürü', description: 'Sorunuz', price: '500₺/gün' },
      { name: 'Görüntü Analiz İşlemleri', description: 'Cihaz kullanımı', price: '100₺/numune' },
      { name: 'Hücre Kültürü Lab genel kullanımı', description: 'Ters/düz ışık mikroskobu N2/CO2 inkübatörü Biyogüvenlik Kabini CO2 inkübatörü kullanımı fiyata dahildir (Günlük)', price: '550₺/günlük' },
      { name: 'Hücre Kültürü Lab genel kullanımı', description: 'Ters/düz ışık mikroskobu N2/CO2 inkübatörü Biyogüvenlik Kabini CO2 inkübatörü kullanımı fiyata dahildir (Haftalık)', price: '2750₺/haftalık' },
      { name: 'Hücre Kültürü Lab genel kullanımı', description: 'Ters/düz ışık mikroskobu N2/CO2 inkübatörü Biyogüvenlik Kabini CO2 inkübatörü kullanımı fiyata dahildir (Aylık)', price: '8000₺/ay' }
    ],
    'Kök Hücre ve Rejeneratif Tıp': [
          { name: 'Sitotoksisite Testi', description: '9 Konsantrasyon + kontrol, tek saat dilimi', price: '3000₺/Deney' },
          { name: 'Sitotoksisite Testi', description: '9 Konsantrasyon + kontrol, iki farklı saat dilimi', price: '3500₺/Deney' },
          { name: 'Sitotoksisite Testi', description: '9 Konsantrasyon + kontrol, üç farklı saat dilimi', price: '4000₺/Deney' },
          { name: 'Akım Sitometri', description: 'Apoptoz analizi', price: '225₺/Tüp' },
          { name: 'Akım Sitometri', description: 'ROS analizi', price: '225₺/Tüp' },
          { name: 'Akım Sitometri', description: 'Fenotipik karakterizayon analizi', price: '225₺/Tüp' },
          { name: 'Akım Sitometri', description: 'Tüp okutma', price: '225₺/Tüp' },
          { name: 'Akım Sitometri', description: 'Hücre saflaştırma', price: '3750₺/Tüp' },
          { name: 'Akım Sitometri', description: 'Annexin V & Dead Cell Kit', price: '800₺/Numune' },
          { name: 'Akım Sitometri', description: 'Caspase-3/7 Kit', price: '850₺/Numune' },
          { name: 'Akım Sitometri', description: 'Oksidatif Stres Kit', price: '800₺/Numune' },
          { name: 'Akım Sitometri', description: 'Cell Cycle Kit', price: '700₺/Numune' },
          { 
            name: 'Akım Sitometri', 
            description: 'Akım Sitometride Hücre Fenotipik Karakterizasyonu (P1: Apoptoz analizi - Annexin V/PI, P2: Mezenkimal kök hücre karakterizasyon testi, P3: Hücre döngüsü testi) \n\nNOT:\n1) Karakterizasyon protokolleri (P1, P2, P3) birbirinden bağımsızdır. İşlem öncesi sorumlu uzman ile görüşülmelidir.\n2) Numune hazırlığı, tüp okutma ve hücre fenotipik karakterizasyon analizi dahildir.\n3) Hizmet bedeli, ilgili hücre dizisinde ekim ve uygulaması yapılan flask için geçerlidir.\n4) Flow sitometri tüpleri ve flow tamponu fiyata dahildir.\n5) Hücre ve reaktiflerin (antibody, viability marker, vb.) temini araştırmacıya aittir.', 
            price: '5000₺/Deney' 
          },
          { name: 'Mikroskop', description: '1 saat', price: '550₺/Saat' },
          { name: 'Mikroskop', description: 'Inverted mikroskop ile görüntüleme', price: '320₺/Saat' },
          { name: 'Mikroskop', description: 'Steromikroskop ile görüntüleme', price: '240₺/Saat' },
          { name: 'Mikroorganizmaların moleküler taksonomisi', description: 'Sorunuz', price: 'Sorunuz' },
          { name: 'Fungusların filogenetik analizleri', description: 'Sorunuz', price: 'Sorunuz' },
          { name: 'Fungusların morfolojik tanılanması', description: 'Sorunuz', price: 'Sorunuz' },
          { name: 'Mikrobiyal biyoüretim yöntemleri', description: 'Sorunuz', price: 'Sorunuz' },
          { name: 'Mikrobiyolojik testler', description: 'Sorunuz', price: 'Sorunuz' },
          { name: 'Antimikrobiyal etki analizleri', description: 'Sorunuz', price: 'Sorunuz' },
          { name: 'Mikrobiyal proses teknolojileri ve optimizasyonu', description: 'Sorunuz', price: 'Sorunuz' },
          { name: 'Mikroorganizmaların moleküler taksonomisi', description: 'Sorunuz', price: 'Sorunuz' },
          { name: 'Fungusların filogenetik analizleri', description: 'Sorunuz', price: 'Sorunuz' },
          { name: 'Cihaz ve Hizmet Talebi', description: 'Kit karşılığı', price: 'Sorunuz' },
          { name: 'Danışmanlık Talebi', description: 'Numune kabul – Sonuç rapor', price: 'Sorunuz' }
    ],
    'Biyo-inovatif Teknoloji': [
          { name: '3 Boyutlu Modelleme', description: 'Sorunuz', price: '4000 ₺/saat' },
          { name: '3 Boyutlu Baskılama', description: 'Sorunuz', price: '2000 ₺/saat' },
          { name: 'Katı model baskılama', description: 'Sorunuz', price: 'Sorunuz' },
          { name: 'Biyobaskılama', description: 'Sorunuz', price: 'Sorunuz' },
          { name: 'Biyomalzeme modellemeleri', description: 'Sorunuz', price: 'Sorunuz' },
          { name: 'Cihaz ve Hizmet Talebi', description: 'Kit karşılığı', price: 'Sorunuz' },
          { name: 'Danışmanlık Talebi', description: 'Numune kabul – Sonuç rapor', price: 'Sorunuz' }
    ],
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-6">Hizmet Bedelleri</h1>
      
      <div className="bg-blue-50 border border-blue-200 rounded-lg p-4 mb-6">
        <div className="flex flex-col space-y-2">
          <p>Karar kuralı uygulamamız bulunmamaktadır ve uygunluk beyanı verilmemektedir.</p>
          <p>Analiz Bedellerine %20 KDV Dahil Değildir.</p>
          <p>Danışman Kurulu – Üst yönetim tarafından belirlenecektir.</p>
          <p>KSBÜ Merkez Laboratuvarı Önceden Haber Vermeksizin Ücretleri ve/veya Ücretlendirme Sistemini Değiştirme Hakkına Sahiptir.</p>
          <p>Deney listesinde belirtilmemiş deney taleplerinde, çoklu numunelerde ve araştırma deneyi taleplerinde hizmet bedelleri KSBÜ Üst Yönetimi tarafından ayrıca belirlenir.</p>
        </div>
      </div>

      {/* Tabs */}
      <div className="mb-6">
        <div className="flex flex-wrap gap-2 border-b">
          <button
            onClick={() => setActiveTab('discounts')}
            className={`px-4 py-2 ${activeTab === 'discounts' ? 'bg-blue-500 text-white' : 'bg-gray-100'} rounded-t-lg`}
          >
            İndirim Oranları
          </button>
          {Object.keys(labServices).map((lab) => (
            <button
              key={lab}
              onClick={() => setActiveTab(lab)}
              className={`px-4 py-2 ${activeTab === lab ? 'bg-blue-500 text-white' : 'bg-gray-100'} rounded-t-lg`}
            >
              {lab}
            </button>
          ))}
        </div>

        {/* Tab Content */}
        <div className="mt-6">
          {activeTab === 'discounts' ? (
            <div className="bg-white rounded-lg shadow-sm p-6">
              <h2 className="text-xl font-semibold mb-4">
                Başvuru Yapan Kuruma Bağlı Olarak Analiz Bedellerine Uygulanan İndirim Oranları
              </h2>
              <div className="flex flex-col">
                {discountRates.map((item, index) => (
                  <div key={index} className="flex justify-between py-2 border-b">
                    <span>{item.institution}</span>
                    <span className="font-semibold">{item.rate}</span>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div className="bg-white rounded-lg shadow-sm p-6">
              <h2 className="text-xl font-semibold mb-4">{activeTab} Laboratuvarı</h2>
              <div className="overflow-x-auto">
                <table className="w-full">
                  <thead>
                    <tr className="border-b">
                      <th className="text-left p-2">Metot/Deney Adı</th>
                      <th className="text-left p-2">Hizmet Tanımı</th>
                      <th className="text-left p-2">Hizmet Bedeli</th>
                    </tr>
                  </thead>
                  <tbody>
                    {labServices[activeTab].map((service, index) => (
                      <tr key={index} className="border-b">
                        <td className="p-2">{service.name}</td>
                        <td className="p-2">{service.description}</td>
                        <td className="p-2 font-semibold">{service.price}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="bg-white rounded-lg shadow-sm p-6 mt-6">
        <p className="text-sm text-gray-600 mb-4">
          * KSBÜ Merkez Laboratuvarı önceden haber vermeksizin ücretleri ve/veya ücretlendirme sistemini değiştirme hakkına sahiptir.
        </p>
        <div className="mt-4">
          <h3 className="font-semibold mb-2">KSBÜ Hesap Bilgileri:</h3>
          <p>Hesap İsmi: Kütahya Sağlık Bilimleri Üniversitesi Rektörlüğü Döner Sermaye İşletme Müdürlüğü</p>
          <p>Vergi Dairesi/Numarası: Çinili V.D. 6070671075</p>
          <p>Banka Hesabı: Halk Bankası Kütahya Şubesi</p>
          <p>IBAN NO (TL için): TR51 0001 2009 5270 0044 0000 22</p>
        </div>
      </div>
    </div>
  );
};

export default ServiceCharges;