import React from 'react';
import { Link } from 'react-router-dom';
import { 
  ClipboardList, 
  FileSpreadsheet, 
  Wallet, 
  UserCog, 
  ClipboardCheck 
} from 'lucide-react';
import './QuickAccess.css';

const quickAccessCards = [
  {
    icon: ClipboardList,
    text: 'Yeni/Taslak Fiyat Teklifleri',
    bgColor: 'bg-emerald-100',
    iconColor: 'text-white',
    gradientFrom: '#10B981',
    gradientTo: '#059669',
    path: '/kuyam-basvuru/dashboard/new-offers'
  },
  {
    icon: FileSpreadsheet,
    text: 'Mevcut Fiyat/Analiz Teklifleri',
    bgColor: 'bg-blue-100',
    iconColor: 'text-white',
    gradientFrom: '#3B82F6',
    gradientTo: '#2563EB',
    path: '/kuyam-basvuru/dashboard/current-offers'
  },
  {
    icon: Wallet,
    text: 'Bütçe',
    bgColor: 'bg-violet-100',
    iconColor: 'text-white',
    gradientFrom: '#8B5CF6',
    gradientTo: '#6D28D9',
    path: '/kuyam-basvuru/dashboard/budget'
  },
  {
    icon: UserCog,
    text: 'Bilgilerim & Şifre',
    bgColor: 'bg-indigo-100',
    iconColor: 'text-white',
    gradientFrom: '#6366F1',
    gradientTo: '#4F46E5',
    path: '/kuyam-basvuru/dashboard/profile'
  },
  {
    icon: ClipboardCheck,
    text: 'Memnuniyet Anketi',
    bgColor: 'bg-amber-100',
    iconColor: 'text-white',
    gradientFrom: '#F59E0B',
    gradientTo: '#D97706',
    path: '/kuyam-basvuru/dashboard/survey'
  },
];

const QuickAccess = () => {
  return (
    <div className="quick-access-container">
      <div className="quick-access-grid">
        {quickAccessCards.map((card, index) => (
          <Link 
            to={card.path} 
            key={index} 
            className="quick-access-card"
            style={{
              '--gradient-from': card.gradientFrom,
              '--gradient-to': card.gradientTo
            }}
          >
            <div className="quick-access-icon-container">
              <div className="icon-gradient-bg">
                <card.icon 
                  className="icon-white" 
                  size={24} 
                  strokeWidth={2}
                  stroke="white"
                  color="white"
                />
              </div>
            </div>
            <span className="quick-access-text">{card.text}</span>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default QuickAccess;