import React from 'react';

const FormPreview = ({ formData }) => {
  const checkBox = (checked) => (checked ? '☑' : '☐');

  const purposeMap = {
    yLisans: 'Y. Lisans',
    doktora: 'Doktora',
    proje: 'Proje',
    danismanlik: 'Danışmanlık',
    diger: 'Diğer'
  };

  const paymentMap = {
    universite: 'Üniversite',
    universiteSanayi: 'Üniversite-Sanayi İşbirliği',
    bireysel: 'Bireysel',
    ozelSektor: 'Özel Sektör',
    kamu: 'Kamu',
    protokol: 'Protokol',
    kuyam: 'KUYAM'
  };

  const deviceMap = {
    modeling3D: 'ELISA YIKAYICI',
    printing3D: 'ELISA OKUYUCU',
    solidModelPrinting: 'UV-VIS',
    bioprinting: 'Ultrasantrifüj',
    biomaterialMod: '-80 Dondurucu'
  };

  // Kullanım amacı satırı
  const purposeLine = Object.keys(purposeMap)
    .map(key => `${checkBox(formData.purpose[key])} ${purposeMap[key]}`)
    .join('    ');

  // Ödeme kaynağı satırı
  const paymentLine = Object.keys(paymentMap)
    .map(key => `${checkBox(formData.paymentSource[key])} ${paymentMap[key]}`)
    .join('    ');

  const msdsSolunum = formData.msds.solunum.value || '';
  const msdsDeri = formData.msds.deri.value || '';
  const msdsGoz = formData.msds.goz.value || '';

  const harmfulVar = checkBox(formData.harmfulEffect.var);
  const harmfulYok = checkBox(formData.harmfulEffect.yok);
  const deliveryElden = checkBox(formData.deliveryMethod.elden);
  const deliveryKargo = checkBox(formData.deliveryMethod.kargo);

  const returnWant = checkBox(formData.returnPreference.want);
  const returnDontWant = checkBox(formData.returnPreference.dontWant);

  // Deney Cihazları
  const showDevices = () => {
    return Object.keys(deviceMap).map(key => {
      const isChecked = formData.testDevices[key];
      return `${checkBox(isChecked)} ${deviceMap[key]}  `;
    }).join(' ');
  };

  // Deney Parametreleri
  const paramTableRows = formData.testParameters.map((row, rIndex) => (
    <tr key={rIndex}>
      {row.map((cell,cIndex) => <td style={{border:'1px solid #000', padding:'4px'}} key={cIndex}>{cell}</td>)}
    </tr>
  ));

  // Numune Bilgileri Tablosu
  const sampleRows = formData.samples.map((sample, index) => (
    <tr key={index}>
      <td style={{border:'1px solid #000', padding:'4px', textAlign:'center'}}>{sample.id}</td>
      <td style={{border:'1px solid #000', padding:'4px'}}>{sample.name}</td>
      <td style={{border:'1px solid #000', padding:'4px'}}>{sample.content}</td>
      <td style={{border:'1px solid #000', padding:'4px'}}>{sample.properties}</td>
    </tr>
  ));

  // Ek Hizmetler
  const additionalServices = [];
  if (formData.additionalServices.testProtocol) additionalServices.push('Deney protokolü');
  if (formData.additionalServices.consultingService) additionalServices.push('Danışmanlık Hizmeti');
  if (formData.additionalServices.other && formData.additionalServices.other.trim() !== '') additionalServices.push(formData.additionalServices.other);

  return (
    <div style={{fontFamily:'Arial, sans-serif', fontSize:'12px', color:'#000'}}>
      {/* Sayfa 1 */}
      <div style={{padding:'20px', width:'210mm', margin:'0 auto', background:'#fff'}}>
        <div style={{textAlign:'center', fontWeight:'bold'}}>
          <div style={{fontSize:'14px'}}>KÜTAHYA SAĞLIK BİLİMLERİ ÜNİVERSİTESİ</div>
          <div style={{fontSize:'14px'}}>KUYAM</div>
          <div style={{fontSize:'14px'}}>UYGULAMA VE ARAŞTIRMA MERKEZİ</div>
          <div style={{fontSize:'10px'}}>
            Tel: +90 274 260 00 43 Dahili: 1260 e-posta: kuyam@ksbu.edu.tr http://www.kuyam.ksbu.edu.tr
          </div>
          <div style={{fontSize:'16px', marginTop:'10px', textDecoration:'underline'}}>
            BİYOKİMYA LABORATUVARI DENEY İSTEK FORMU
          </div>
        </div>

        <div style={{display:'flex', marginTop:'10px'}}>
          <div style={{
            writingMode:'vertical-rl', 
            textOrientation:'upright', 
            background:'#ccc', 
            fontWeight:'bold', 
            fontSize:'10px', 
            textAlign:'center', 
            padding:'5px',
            marginRight:'5px'
          }}>
            MÜŞTERİ BİLGİLERİ
          </div>

          <div style={{flex:'1'}}>
            {/* Sadece Başvuranın Adı, Soyadı ve Adres satırının üstünde çizgi */}
            <div style={{
              borderTop:'1px solid #000',
              paddingTop:'5px',
              display:'flex',
              gap:'20px'
            }}>
              <div style={{flex:'1'}}>
                <div>
                  Başvuranın Adı, Soyadı:<br/>
                  <span>{formData.name}</span>
                </div>

                <div style={{marginTop:'10px'}}>
                  Kurum/Üniversite-Bölüm:<br/>
                  <span>{formData.institution}</span>
                </div>

                <div style={{marginTop:'10px'}}>
                  Tel: <span>{formData.phone}</span><br/>
                  e-Posta: <span>{formData.email}</span>
                </div>
              </div>

              <div style={{flex:'1'}}>
                Adres:<br/>
                <span style={{whiteSpace:'pre-wrap'}}>{formData.address}</span>
              </div>
            </div>

            <div style={{marginTop:'10px'}}>
              Deney sonuçlarının kullanım amacı:<br/>
              {purposeLine}
            </div>

            <div style={{marginTop:'5px'}}>
              Ödemenin Yapılacağı Kaynak:<br/>
              {paymentLine}
            </div>
          </div>
        </div>

        <div style={{marginTop:'10px', fontSize:'11px'}}>
          KSBÜ, Uygulama ve Araştırma Merkezi Koordinatörlüğüne’ne,<br/>
          .........  adet numunenin deneyleri yapılarak deney raporunun tarafıma {deliveryElden} Elden   {deliveryKargo}  Kargo ile iletilmesini arz ederim. <br/>
          Numunenin çevre, insan sağlığına veya cihaza  zararlı etkisi      {harmfulVar}  Vardır	   {harmfulYok}  Yoktur	<br/>
          Kullanıcıya, çevreye veya cihaza zarar verme olasılığı olan numunelerin Madde Güvenlik Bilgilerinde (MSDS) belirtilen miktarları yazılmalıdır.   
          {checkBox(!!msdsSolunum)}  Solunum: {msdsSolunum || '__________'}       
          {checkBox(!!msdsDeri)}  Deri: {msdsDeri || '__________'}      
          {checkBox(!!msdsGoz)}  Göz: {msdsGoz || '__________'}
        </div>

        <div style={{marginTop:'10px', fontSize:'10px', lineHeight:'1.3'}}>
          <b>KUYAM Deney Hizmet Sözleşmesi</b><br/>
          Bu sözleşme KSBÜ Merkez Laboratuvarı ile Müşteri arasındaki Hizmet Sözleşmesidir. KUYAM Merkez Laboratuvarın’dan hizmet talebinde bulunan tüm kişi ve kuruluşlar “MÜŞTERİ”, KSBÜ Merkez Laboratuvarı ise “KUYAM” olarak adlandırılmıştır. <br/>
          1.	Deney şartlarına uygun şekilde numune alma işlemi müşteriye aittir.<br/>
          2.	Numunenin KUYAM’a kabulüne kadar geçen süre zarfında taşınması, ambalajlanması ve muhafazası müşterinin sorumluluğundadır. Bu etkenlerden dolayı deney sonuçlarında oluşacak olumsuzluklardan KUYAM sorumlu tutulamaz.<br/>
          3.	Numune kabul kriterlerine uygun olmayan numunelerin başvurusu KUYAM tarafından kabul edilmez.<br/>
          4.	Müşteri, numuneleri 01‘den başlayarak kodlamalı ve sıralamalıdır. Kodlama silinmeyecek şekilde numunelerin üzerine yazılmalıdır.<br/>
          5.	Başvuruların kabul edilebilmesi için ilgili Deney İstek Formu’nun eksiksiz olarak doldurulması ve yetkili kişi tarafından imzalanması gerekmektedir.<br/>
          6.	Deney İstek Formu’nun doldurulup imzalanmasıyla müşteri, deneye gönderilen numunenin (varsa) insan ve çevre sağlığına olan zararlı etkilerini beyan ettiğini, etmediği takdirde oluşacak uygunsuzluklardan sorumlu olacağını kabul eder.<br/>
          7.	Deneyler ve ücretlerinin listesi, numune kabul kriterleri ve ödeme şartları web sitesinde (kuyam.ksbu.edu.tr) ayrıntılı olarak yayınlanmıştır.<br/>
          8.	Beyan edilen deney süreleri tahmini süre olup elde olmayan nedenlerden dolayı olabilecek gecikmelerden KUYAM sorumlu tutulamaz. Taahhüt edilen şartlardan sapma olduğunda müşteri yazılı veya sözlü olarak bilgilendirilir.<br/>
          9.	Müşteri randevulu deneylerde; randevu zamanında belirtilen laboratuvarda hazır olacağını, zorunlu sebeplerle hazır olamadığı durumlarda en az bir gün önce haber vereceğini, aksi durumda deney ücretini ödeyeceğini taahhüt eder.<br/>
          10.	Müşteri tarafından iadesi talep edilen numuneler deney raporu ile birlikte iade edilir. Bu numuneler onbeş gün içinde teslim alınmadığı takdirde atığa gönderilir. Deney işlemleri tamamlandıktan sonra, müşteri tarafından aksi belirtilmediği sürece saklanması mümkün olan numuneler üç ay süreyle uygun şartlarda saklanır, bu süre sonunda atığa gönderilir.<br/>
          11.	Her türlü kargo masrafı müşteriye aittir.<br/>
          12.	Deney ve hizmet ücretinin ödendiğine dair belge KUYAM’a ibraz edilmeden deney raporu düzenlenmez.<br/>
          13.	Deney sonuçlarının bilimsel bir yayında kullanılması halinde bu deneylerin yapıldığı yerin KSBÜ Merkez Laboratuvarı olduğunun yayında belirtilmesi gerekmektedir.<br/>
          14.	Müşteri, deney sonuçlarının sadece deneyi yapılan numuneye ait olduğunu, ticari bir amaçla kullanılmayacağını ve reklamlarda KSBÜ’nün adının zikredilerek ve/veya söz konusu ürünün KSBÜ tarafından onaylandığı anlamına gelecek şekilde kullanılmayacağını taahhüt eder.<br/>
          15.	Her deney sonucunda bir adet deney raporu düzenlenir. İlave raporlar ve farklı sonuç formatları ek ücrete tabiidir.<br/>
          16.	Müşterinin deney sonuçlarına itirazı durumunda yapılan deney tekrarlarında aynı sonuçların bulunması durumunda müşteriden tam hizmet bedeli tahsil edilir.<br/>
          17.	KUYAM’a deney talebinde bulunulan SANTEZ, KOSGEB, BAP, AB, TÜBİTAK vb. kapsamında yürütülen projelerin içerik, süre ve deney bilgileri KUYAM tarafından bilinmemekte ve takibi yapılmamaktadır. KUYAM’da yapılması talep edilen deneylerin ilgili Deney İstek Formu’nda ve/veya Para Aktarma Formu’nda numarası belirtilen proje şartlarına uygunluğunun olmamasından doğabilecek hukuki ve cezai sorumluluk proje yürütücüsü ve müşterilere aittir. Süresi bitmiş proje ücretlerinin kullanılması, projede belirtilen deneyler harici olan ve proje kapsamındaymış gibi talep edilecek deney yaptırılması ve yapılan deney ile KSBÜ Döner Sermaye İşletmesi’nden alınacak fatura içeriğinin birbiriyle farklı olmasında doğabilecek hukuki ve cezai sorumluluklar KUYAM tarafından tanzim edilen işbu sözleşme gereğince proje yürütücüsü ve müşterilere aittir.<br/>
          18.	Anlaşmazlık durumlarında Kütahya Mahkemeleri yetkilidir.<br/><br/>
          BU FORMDA BELİRTMİŞ OLDUĞUM BİLGİLERİN DOĞRULUĞUNU VE KUYAM DENEY HİZMETİ SÖZLEŞMESİ’NDEKİ HÜKÜMLERİ AYNEN KABUL ETTİĞİMİ BEYAN EDERİM.
        </div>

        <div style={{textAlign:'right', marginTop:'30px', marginBottom:'30px', fontWeight:'bold'}}>
          YETKİLİ/PROJE YÜRÜTÜCÜSÜ ADI SOYADI VE İMZA
        </div>

        <div style={{fontSize:'10px', display:'flex', justifyContent:'space-between'}}>
          <div>MERKEZ LABORATUVARI TARAFINDAN DOLDURULACAKTIR.</div>
          <div style={{textAlign:'right'}}>
            Evrak Kayıt No       Deney Başlama – Bitiş Tarihi<br/>
            Başvuru Tarihi       Deney Ücreti<br/>
            DYS Adı, Soyadı ve İmza
          </div>
        </div>
        <div style={{textAlign:'right', fontSize:'10px', marginTop:'10px'}}>Tarih: {new Date().toLocaleDateString()}</div>
      </div>

      <div style={{pageBreakAfter:'always'}}></div>

      {/* Sayfa 2 */}
      <div style={{padding:'20px', width:'210mm', margin:'0 auto', background:'#fff', fontSize:'11px'}}>
        <div style={{textAlign:'center', fontWeight:'bold'}}>
          <div style={{fontSize:'14px'}}>KÜTAHYA SAĞLIK BİLİMLERİ ÜNİVERSİTESİ</div>
          <div style={{fontSize:'14px'}}>KUYAM</div>
          <div style={{fontSize:'14px'}}>UYGULAMA VE ARAŞTIRMA MERKEZİ</div>
          <div style={{fontSize:'10px'}}>
            Tel: +90 274 260 00 43 Dahili: 1260 e-posta: kuyam@ksbu.edu.tr http://www.kuyam.ksbu.edu.tr
          </div>
          <div style={{fontSize:'16px', marginTop:'10px', textDecoration:'underline'}}>
            BİYOKİMYA LABORATUVARI DENEY İSTEK FORMU
          </div>
        </div>

        <div style={{marginTop:'10px', fontSize:'11px'}}>
          Deneylerin gerçekleştirilebilmesi için bu formun eksiksiz doldurulması gereklidir.
        </div>

        <table style={{width:'100%', border:'1px solid #000', borderCollapse:'collapse', marginTop:'10px', fontSize:'11px'}}>
          <thead>
            <tr>
              <th style={{border:'1px solid #000', padding:'4px', width:'50%'}}>Deney Metodu</th>
              <th style={{border:'1px solid #000', padding:'4px', width:'50%'}}>Deney Süresi</th>
            </tr>
          </thead>
          <tbody>
            {formData.testMethods.map((m,i)=>(
              <tr key={i}>
                <td style={{border:'1px solid #000', padding:'4px'}}>{m.isActive ? m.method : ''}</td>
                <td style={{border:'1px solid #000', padding:'4px'}}>{m.isActive ? m.duration : ''}</td>
              </tr>
            ))}
          </tbody>
        </table>

        <div style={{marginTop:'10px', fontSize:'11px'}}>
          Deney Cihazı {showDevices()}<br/>
          {additionalServices.map((srv, i) => (
            <span key={i}>{checkBox(true)} {srv}  </span>
          ))}
        </div>

        <div style={{marginTop:'10px', fontSize:'11px'}}>
          Deney Parametreleri:
          <table style={{width:'100%', border:'1px solid #000', borderCollapse:'collapse', marginTop:'5px'}}>
            <tbody>
              {paramTableRows}
            </tbody>
          </table>
        </div>

        <div style={{marginTop:'10px', fontSize:'11px'}}>
          Deney ile İlgili Ek İstekler:<br/>
          <div style={{border:'1px solid #000', minHeight:'30px', whiteSpace:'pre-wrap', padding:'5px'}}>
            {formData.additionalInfo}
          </div>
        </div>

        <table style={{width:'100%', border:'1px solid #000', borderCollapse:'collapse', marginTop:'10px', fontSize:'11px'}}>
          <thead>
            <tr>
              <th style={{border:'1px solid #000', padding:'4px'}}>Etiket No</th>
              <th style={{border:'1px solid #000', padding:'4px'}}>Numune Adı</th>
              <th style={{border:'1px solid #000', padding:'4px'}}>Numune İçeriği</th>
              <th style={{border:'1px solid #000', padding:'4px'}}>Deney ile ilgili özellikler</th>
            </tr>
          </thead>
          <tbody>
            {sampleRows}
          </tbody>
        </table>

        <div style={{marginTop:'10px', fontSize:'11px'}}>
          Numunenin (varsa) özel saklama koşulları:<br/>
          <div style={{border:'1px solid #000', minHeight:'30px', padding:'5px'}}>
            {formData.storageConditions}
          </div>
        </div>

        <div style={{marginTop:'10px', fontSize:'11px'}}>
          Artan Numunenin İadesini {returnWant} İstiyorum   {returnDontWant} İstemiyorum
        </div>

        <div style={{textAlign:'left', fontSize:'10px', marginTop:'10px'}}>
          FRM-MBB-SPL-01    &nbsp;&nbsp;&nbsp;&nbsp; Tarih: 02/16.03.2015
        </div>
        <div style={{textAlign:'right', fontSize:'10px'}}>
          2/2
        </div>
      </div>
    </div>
  );
};

export default FormPreview;
