import React from 'react';
import './styles.css';

const Tab2 = ({ formData, setFormData }) => {
  const handleCheckboxChange = (section, field) => {
    setFormData(prev => ({
      ...prev,
      [section]: {
        ...prev[section],
        [field]: !prev[section][field]
      }
    }));
  };

  return (
    <div className="tab2-container tab-container">
      <h2>Kullanım Amacı</h2>
      {[
        { key: 'yLisans', label: 'Y. Lisans' },
        { key: 'doktora', label: 'Doktora' },
        { key: 'proje', label: 'Proje' },
        { key: 'danismanlik', label: 'Danışmanlık' },
        { key: 'diger', label: 'Diğer' }
      ].map(({ key, label }) => (
        <label key={key}>
          <input
            type="checkbox"
            checked={formData.purpose[key]}
            onChange={() => handleCheckboxChange('purpose', key)}
          />
          {label}
        </label>
      ))}

      <h2>Ödeme Kaynağı</h2>
      {[
        { key: 'universite', label: 'Üniversite' },
        { key: 'universiteSanayi', label: 'Üniversite-Sanayi İşbirliği' },
        { key: 'bireysel', label: 'Bireysel' },
        { key: 'ozelSektor', label: 'Özel Sektör' },
        { key: 'kamu', label: 'Kamu' },
        { key: 'protokol', label: 'Protokol' },
        { key: 'kuyam', label: 'KUYAM' }
      ].map(({ key, label }) => (
        <label key={key}>
          <input
            type="checkbox"
            checked={formData.paymentSource[key]}
            onChange={() => handleCheckboxChange('paymentSource', key)}
          />
          {label}
        </label>
      ))}
    </div>
  );
};

export default Tab2;
