// src/pages/KuyamBasvuru/KuyamLogin.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const KuyamLogin = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    rememberMe: false
  });
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // API çağrısı burada yapılacak
    try {
      // API çağrısı
      navigate('/kuyam-basvuru/dashboard');
    } catch (error) {
      setErrors({ submit: error.message });
    }
  };

  return (
    <div className="page-container">
      <div className="kuyam-container">
        <div className="login-box">
          <h2>Giriş Yap</h2>
          <form onSubmit={handleSubmit} className="auth-form">
            <div className="form-group">
              <input
                type="email"
                name="email"
                placeholder="E-posta"
                value={formData.email}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <input
                type="password"
                name="password"
                placeholder="Şifre"
                value={formData.password}
                onChange={handleChange}
              />
            </div>
            <div className="remember-me">
              <input
                type="checkbox"
                name="rememberMe"
                checked={formData.rememberMe}
                onChange={handleChange}
              />
              <label>Beni Hatırla</label>
            </div>
            <button type="submit" className="login-button">
              Giriş Yap
            </button>
          </form>
          <div className="links">
            <a href="#" onClick={() => navigate('/kuyam-basvuru/forgot-password')}>
              Şifrenizi mi unuttunuz?
            </a>
            <a href="#" onClick={() => navigate('/kuyam-basvuru/register')}>
              Yeni üyelik için tıklayınız
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KuyamLogin;