import React, { useState } from 'react';
import { Printer, Plus, Search } from 'lucide-react';
import AddPaymentModal from './AddPaymentModal';
import './Payments.css';

const Payments = () => {
  const [pageSize, setPageSize] = useState(20);
  const [searchTerm, setSearchTerm] = useState('');
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [payments, setPayments] = useState([
    // Örnek veri
    {
      id: 1,
      date: '2024-01-15',
      transactionType: 'Havale',
      amount: 1500.00,
      invoiceNo: 'INV-2024-001',
      paymentMethod: 'Banka Transferi',
      description: 'Ocak ayı ödemesi'
    }
  ]);

  const handlePrint = () => {
    window.print();
  };

  const handleAdd = () => {
    setIsAddModalOpen(true);
  };

  const handleSavePayment = (formData) => {
    // Yeni ödeme oluştur
    const newPayment = {
      id: payments.length + 1,
      date: formData.date,
      transactionType: 'Havale', // Varsayılan tip
      amount: parseFloat(formData.amount),
      invoiceNo: `INV-${new Date().getFullYear()}-${String(payments.length + 1).padStart(3, '0')}`,
      paymentMethod: 'Banka Transferi', // Varsayılan yöntem
      description: formData.description
    };

    // Payments state'ini güncelle
    setPayments([...payments, newPayment]);
    
    // Modal'ı kapat
    setIsAddModalOpen(false);
  };

  // Arama fonksiyonu
  const filteredPayments = payments.filter(payment => {
    const searchLower = searchTerm.toLowerCase();
    return (
      payment.invoiceNo.toLowerCase().includes(searchLower) ||
      payment.description.toLowerCase().includes(searchLower) ||
      payment.transactionType.toLowerCase().includes(searchLower) ||
      payment.paymentMethod.toLowerCase().includes(searchLower)
    );
  });

  // Sayfalama hesaplaması
  const paginatedPayments = pageSize === 0 
    ? filteredPayments 
    : filteredPayments.slice(0, pageSize);

  return (
    <div className="payments-container">
      {/* Başlık ve Üst Toolbar */}
      <div className="payments-header">
        <h1 className="page-title">Ödemelerim ve Faturalar</h1>
        <div className="header-buttons">
          <button
            onClick={handleAdd}
            className="add-button"
          >
            <Plus size={20} />
            <span>Ekle</span>
          </button>
          <button
            onClick={handlePrint}
            className="print-button"
          >
            <Printer size={20} />
            <span>Yazdır</span>
          </button>
        </div>
      </div>

      {/* Tablo Bölümü */}
      <div className="table-container">
        <table className="payments-table">
          <thead>
            <tr>
              <th>#</th>
              <th>Tarih</th>
              <th>İşlem Tip</th>
              <th>Tahsilat Tutar</th>
              <th>Fatura No</th>
              <th>Tahsilat Yöntemi</th>
              <th>Açıklama</th>
            </tr>
          </thead>
          <tbody>
            {paginatedPayments.length > 0 ? (
              paginatedPayments.map((payment) => (
                <tr key={payment.id}>
                  <td>{payment.id}</td>
                  <td>{new Date(payment.date).toLocaleDateString('tr-TR')}</td>
                  <td>{payment.transactionType}</td>
                  <td className="amount">
                    {payment.amount.toLocaleString('tr-TR', {
                      style: 'currency',
                      currency: 'TRY'
                    })}
                  </td>
                  <td>{payment.invoiceNo}</td>
                  <td>{payment.paymentMethod}</td>
                  <td>{payment.description}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="7" className="no-data">
                  {searchTerm ? 'Arama sonucu bulunamadı.' : 'Veri bulunamadı.'}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* Alt Toolbar */}
      <div className="payments-toolbar">
        <div className="page-size-selector">
          <button 
            className={`page-button ${pageSize === 20 ? 'active' : ''}`}
            onClick={() => setPageSize(20)}
          >
            20
          </button>
          <button 
            className={`page-button ${pageSize === 50 ? 'active' : ''}`}
            onClick={() => setPageSize(50)}
          >
            50
          </button>
          <button 
            className={`page-button ${pageSize === 100 ? 'active' : ''}`}
            onClick={() => setPageSize(100)}
          >
            100
          </button>
          <button 
            className={`page-button ${pageSize === 0 ? 'active' : ''}`}
            onClick={() => setPageSize(0)}
          >
            Tümü
          </button>
        </div>
        
        <div className="search-box">
          <Search className="search-icon" size={20} />
          <input
            type="text"
            placeholder="Ara"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="search-input"
          />
        </div>
      </div>

      {/* Ödeme Ekleme Modalı */}
      <AddPaymentModal 
        isOpen={isAddModalOpen}
        onClose={() => setIsAddModalOpen(false)}
        onSave={handleSavePayment}
      />
    </div>
  );
};

export default Payments;