import React, { useState } from 'react';
import './CustomerProfileModal.css';

const CustomerProfileModal = ({ isOpen, onClose, customer }) => {
  const [formData, setFormData] = useState({
    fullName: customer?.fullName || '',
    title: customer?.title || '',
    email: customer?.email || '',
    institution: customer?.institution || '',
    birthDate: customer?.birthDate || '',
    tcNo: customer?.tcNo || '',
    invoiceAddress: customer?.invoiceAddress || '',
    address: customer?.address || '',
    taxOffice: customer?.taxOffice || '',
    taxNo: customer?.taxNo || '',
    phoneNumber: customer?.phoneNumber || '',
    mobilePhone: customer?.mobilePhone || ''
  });

  if (!isOpen) return null;

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form data:', formData);
    onClose();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  return (
    <div className="customer-modal-overlay">
      <div className="customer-modal-container">
        <div className="customer-modal-content">
          <div className="customer-modal-header">
            <h2 className="customer-modal-title">Müşteri Bilgileri</h2>
            <button 
              onClick={onClose}
              className="customer-modal-close"
            >
              ✕
            </button>
          </div>

          <form onSubmit={handleSubmit} className="customer-form">
            <div className="customer-form-grid">
              <div className="form-field">
                <label className="form-label">Adı Soyadı</label>
                <input
                  type="text"
                  name="fullName"
                  value={formData.fullName}
                  onChange={handleChange}
                  className="form-input"
                />
              </div>

              <div className="form-field">
                <label className="form-label">Ünvanı</label>
                <input
                  type="text"
                  name="title"
                  value={formData.title}
                  onChange={handleChange}
                  className="form-input"
                />
              </div>

              <div className="form-field">
                <label className="form-label">E-mail</label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="form-input"
                />
              </div>

              <div className="form-field">
                <label className="form-label">Kurumu</label>
                <input
                  type="text"
                  name="institution"
                  value={formData.institution}
                  onChange={handleChange}
                  className="form-input"
                />
              </div>

              <div className="form-field">
                <label className="form-label">TC KİMLİK NO</label>
                <input
                  type="text"
                  name="tcNo"
                  value={formData.tcNo}
                  onChange={handleChange}
                  className="form-input"
                />
              </div>

              <div className="form-field">
                <label className="form-label">Doğum Tarihi</label>
                <input
                  type="date"
                  name="birthDate"
                  value={formData.birthDate}
                  onChange={handleChange}
                  className="form-input"
                />
              </div>

              <div className="form-field full-width">
                <label className="form-label">Fatura Adresi</label>
                <textarea
                  name="invoiceAddress"
                  value={formData.invoiceAddress}
                  onChange={handleChange}
                  className="form-textarea"
                />
              </div>

              <div className="form-field full-width">
                <label className="form-label">Adres</label>
                <textarea
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                  className="form-textarea"
                />
              </div>

              <div className="form-field">
                <label className="form-label">Vergi Dairesi</label>
                <input
                  type="text"
                  name="taxOffice"
                  value={formData.taxOffice}
                  onChange={handleChange}
                  className="form-input"
                />
              </div>

              <div className="form-field">
                <label className="form-label">Vergi No</label>
                <input
                  type="text"
                  name="taxNo"
                  value={formData.taxNo}
                  onChange={handleChange}
                  className="form-input"
                />
              </div>

              <div className="form-field">
                <label className="form-label">Telefon Numarası</label>
                <input
                  type="tel"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  className="form-input"
                />
              </div>

              <div className="form-field">
                <label className="form-label">Cep Telefonu</label>
                <input
                  type="tel"
                  name="mobilePhone"
                  value={formData.mobilePhone}
                  onChange={handleChange}
                  className="form-input"
                />
              </div>
            </div>

            <div className="form-buttons">
              <button
                type="button"
                onClick={onClose}
                className="button button-cancel"
              >
                İptal
              </button>
              <button
                type="submit"
                className="button button-save"
              >
                Kaydet
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CustomerProfileModal;