// ActivityReports22.js
import React from 'react';
import './ActivityReports22.css';

function ActivityReports22() {
  return (
    <div className="pdf-container">
      <object
        data={require('./KSBU_2022-2023_faaliyet_raporu.pdf')}
        type="application/pdf"
        className="pdf-viewer"
      >
        <p>
          PDF görüntüleyiciniz çalışmıyor olabilir.{' '}
          <a href={require('./KSBU_2022-2023_faaliyet_raporu.pdf')} target="_blank" rel="noopener noreferrer">
            PDF'i indirmek için tıklayın
          </a>
        </p>
      </object>
    </div>
  );
}

export default ActivityReports22;