import React from 'react';
import './ViewProfileModal.css';

const ViewProfileModal = ({ isOpen, onClose, profile }) => {
  if (!isOpen) return null;

  return (
    <div className="view-modal-overlay">
      <div className="view-modal-container">
        <div className="view-modal-content">
          <div className="view-modal-header">
            <h2 className="view-modal-title">Müşteri Bilgileri</h2>
            <button 
              onClick={onClose}
              className="view-modal-close"
            >
              ✕
            </button>
          </div>

          <div className="view-details-grid">
            <div className="view-detail-item">
              <div className="detail-label">Adı Soyadı</div>
              <div className="detail-value">{profile?.fullName || '-'}</div>
            </div>

            <div className="view-detail-item">
              <div className="detail-label">Ünvanı</div>
              <div className="detail-value">{profile?.title || '-'}</div>
            </div>

            <div className="view-detail-item">
              <div className="detail-label">E-mail</div>
              <div className="detail-value">
                <a href={`mailto:${profile?.email}`} className="detail-link">
                  {profile?.email || '-'}
                </a>
              </div>
            </div>

            <div className="view-detail-item">
              <div className="detail-label">Kurumu</div>
              <div className="detail-value">{profile?.institution || '-'}</div>
            </div>

            <div className="view-detail-item">
              <div className="detail-label">TC KİMLİK NO</div>
              <div className="detail-value">{profile?.tcNo || '-'}</div>
            </div>

            <div className="view-detail-item">
              <div className="detail-label">Doğum Tarihi</div>
              <div className="detail-value">{profile?.birthDate || '-'}</div>
            </div>

            <div className="view-detail-item full-width">
              <div className="detail-label">Fatura Adresi</div>
              <div className="detail-value address">{profile?.invoiceAddress || '-'}</div>
            </div>

            <div className="view-detail-item full-width">
              <div className="detail-label">Adres</div>
              <div className="detail-value address">{profile?.address || '-'}</div>
            </div>

            <div className="view-detail-item">
              <div className="detail-label">Vergi Dairesi</div>
              <div className="detail-value">{profile?.taxOffice || '-'}</div>
            </div>

            <div className="view-detail-item">
              <div className="detail-label">Vergi No</div>
              <div className="detail-value">{profile?.taxNo || '-'}</div>
            </div>

            <div className="view-detail-item">
              <div className="detail-label">Telefon Numarası</div>
              <div className="detail-value">
                <a href={`tel:${profile?.phoneNumber}`} className="detail-link">
                  {profile?.phoneNumber || '-'}
                </a>
              </div>
            </div>

            <div className="view-detail-item">
              <div className="detail-label">Cep Telefonu</div>
              <div className="detail-value">
                <a href={`tel:${profile?.mobilePhone}`} className="detail-link">
                  {profile?.mobilePhone || '-'}
                </a>
              </div>
            </div>
          </div>

          <div className="view-modal-footer">
            <button
              onClick={onClose}
              className="close-button"
            >
              Kapat
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewProfileModal;