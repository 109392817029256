import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Catalog.css';

const Catalog = () => {
    const navigate = useNavigate();

    const openPdf = () => {
        // PDF'i yeni pencerede aç
        window.open("/brucellosis_prevention_questionnaire__bpq__.16.pdf", "_blank");
    };

    const goToSurvey = () => {
        // Ankete yönlendir
        window.location.href = 'http://192.168.64.205:3000/kuyam-basvuru/dashboard/survey';
        // Eğer React Router kullanıyorsanız bunun yerine:
        // navigate('/kuyam-basvuru/dashboard/survey');
    };

    return (
        <div className="items-container">
            <div className="catalog-container" onClick={openPdf}>
                <img src="/makale.png" alt="Katalog" className="catalog-image" />
                <div className="catalog-overlay">
                    <span>Makale Görüntüle</span>
                </div>
            </div>
            
            <div className="survey-container" onClick={goToSurvey}>
                <img src="/anket.png" alt="Anket" className="survey-image" />
                <div className="survey-overlay">
                    <span>Ankete Git</span>
                </div>
            </div>
        </div>
    );
};

export default Catalog;