// src/components/MainLayout/MainLayout.js
import React from 'react';
import './MainLayout.css';
import NewsSection from '../NewsSection/NewsSection';
import Announcements from '../Announcements/Announcements'; // Yeni import
import Catalog from '../Catalog/Catalog'; // Yeni import

import img001 from '../../assets/centermenu/001.png';
import img002 from '../../assets/centermenu/002.png';
import img003 from '../../assets/centermenu/003.png';
import img004 from '../../assets/centermenu/004.png';
import img005 from '../../assets/centermenu/005.png';
import img006 from '../../assets/centermenu/006.png';

const MainLayout = () => {
    return (
        <div className="main-layout">
            {/* Sol Sütun */}
            <div className="left-column">
                <h2 className="column-title">KUYAM ÇALIŞMALARI</h2>
                <div className="catalog-wrapper">
                    <Catalog />
                </div>            
            </div>

            {/* Orta Sütun */}
            <div className="center-column">
                <div className="lab-grid-top">
                    <a href="/merkezimiz/analiz-basvuru-sureci" className="lab-item mineralogy">
                        <img src={img003} alt="İş Akış Şeması" className="lab-image" />
                        <span>İş Akış Şeması</span>
                    </a>
                    <a href="/biyokimya-laboratuvari" className="lab-item electron">
                        <img src={img001} alt="Biyokimya Laboratuvarı" className="lab-image" />
                        <span>Biyokimya Laboratuvarı</span>
                    </a>
                    <a href="/kok-hucre-ve-rejeneratif-tip-laboratuvari" className="lab-item metalography">
                        <img src={img002} alt="Kök Hücre ve Rejeneratif Tıp Laboratuvarı" className="lab-image" />
                        <span>Kök Hücre ve Rejeneratif Tıp Laboratuvarı</span>
                    </a>
                    <a href="/hucresel-ve-molekuler-tip-laboratuvari" className="lab-item chemical">
                        <img src={img004} alt="Hücresel ve Moleküler Tıp Laboratuvarı" className="lab-image" />
                        <span>Hücresel ve Moleküler Tıp Laboratuvarı</span>
                    </a>
                    <a href="/biyo-inovatif-teknoloji-laboratuvari" className="lab-item thermal">
                        <img src={img005} alt="Biyo-inovatif Teknoloji Laboratuvarı" className="lab-image" />
                        <span>Biyo-inovatif Teknoloji Laboratuvarı</span>
                    </a>
                    <a href="/analizler" className="lab-item spectroscopy">
                        <img src={img006} alt="Analizler" className="lab-image" />
                        <span>Analizler</span>
                    </a>
                </div>


                <div className="news-section">
                    <h3 className="section-title">TÜM HABERLER</h3>

                    <div className="center-column">
                        <div className="lab-grid-top">
                            {/* ... mevcut lab-grid-top içeriği ... */}
                        </div>
                        
                        <NewsSection />  {/* Yeni eklenen bölüm */}
                    
                    </div>

                    <div className="contact-section">
                        <div className="contact-column animate-up">
                            <h4 className="contact-title">KOORDİNATÖRLÜK İLETİŞİM</h4>
                            <p className="contact-name">DOÇ. DR. FATİH KAR</p>
                            <p className="contact-phone">0 (274) 260 00 43-44-45-46 Dahili (1260)</p>
                        </div>
                        {/* <div className="contact-column animate-up delay-1">
                            <h4 className="contact-title">MUHASEBE</h4>
                            <p className="contact-name">Ercan Özcan</p>
                            <p className="contact-phone">+90 (274) 4431817</p>
                        </div>
                        <div className="contact-column animate-up delay-2">
                            <h4 className="contact-title">ANALİZ BİLGİ - ÖRNEK KABUL</h4>
                            <p className="contact-name">Mehmet Karademir</p>
                            <p className="contact-phone">+90 (274) 4431818</p>
                        </div> */}
                    </div>
                </div>
            </div>

            {/* Sağ Sütun */}
            <div className="right-column">
                <Announcements /> {/* Announcements bileşenini ekleyin */}
            </div>
        </div>
    );
};

export default MainLayout;