import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './SliderMenu.css';

import image1 from '../../assets/slider/001.jpg';
import image2 from '../../assets/slider/002.jpg';
import image3 from '../../assets/slider/003.jpg';
import image4 from '../../assets/slider/004.jpg';
import image5 from '../../assets/slider/005.jpg';
import image6 from '../../assets/slider/006.jpg';

const images = [image1, image2, image3, image4, image5, image6];

const SliderMenu = () => {
  const [sliderHeight, setSliderHeight] = useState(null);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    fade: true,
    cssEase: "linear",
    beforeChange: (current, next) => handleImageLoad(next),
  };

  useEffect(() => {
    const updateSliderHeight = () => {
      const container = document.querySelector('.slider-menu-container');
      if (!container) return;
      const containerWidth = container.clientWidth;
      const img = new Image();
      img.src = images[0];
      img.onload = () => {
        const aspectRatio = img.naturalHeight / img.naturalWidth;
        setSliderHeight(containerWidth * aspectRatio);
      };
    };

    updateSliderHeight();

    window.addEventListener('resize', updateSliderHeight);
    return () => window.removeEventListener('resize', updateSliderHeight);
  }, []);

  const handleImageLoad = (index) => {
    const container = document.querySelector('.slider-menu-container');
    if (!container) return;
    const containerWidth = container.clientWidth;
    const img = new Image();
    img.src = images[index];
    img.onload = () => {
      const aspectRatio = img.naturalHeight / img.naturalWidth;
      setSliderHeight(containerWidth * aspectRatio);
    };
  };

  return (
    <div
      className="slider-menu-container"
      style={{ height: sliderHeight ? `${sliderHeight}px` : 'auto' }}
    >
      <Slider {...settings}>
        {images.map((image, index) => (
          <div
            key={index}
            className="slider-item"
            style={{ height: sliderHeight ? `${sliderHeight}px` : 'auto' }}
          >
            <img
              src={image}
              alt={`Slide ${index + 1}`}
              className="slider-image"
              style={{ maxHeight: sliderHeight ? `${sliderHeight}px` : 'auto' }}
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default SliderMenu;
