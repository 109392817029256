import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./ToastMenu.css";

const ToastMenu = () => {
  const navigate = useNavigate();

  // Dropdown state'leri
  const [showMenu, setShowMenu] = useState(false);
  const [showMerkezimiz, setShowMerkezimiz] = useState(false);
  const [showYonetim, setShowYonetim] = useState(false);
  const [showLaboratuvarlar, setShowLaboratuvarlar] = useState(false);
  const [showArastirmalar, setShowArastirmalar] = useState(false);
  const [showIsAkisSurecleri, setShowIsAkisSurecleri] = useState(false);
  const [showFormlar, setShowFormlar] = useState(false);
  const [showReports, setShowReports] = useState(false);

  const handleToggleMenu = () => setShowMenu(!showMenu);

  const handleHomeClick = () => navigate("/");

  const handleNavigate = (path) => {
    setShowMenu(false); // Menü kapansın
    navigate(path);
  };

  return (
    <div className={`toast-menu ${showMenu ? "open" : ""}`}>
      <button className="toggle-menu" onClick={handleToggleMenu}>
        {showMenu ? "❌" : "☰"}
      </button>

      <div className="toast-menu-content">
        <span onClick={handleHomeClick} className="menu-link">
          Anasayfa
        </span>

        {/* Merkezimiz */}
        <div
          className="menuItem"
          onMouseEnter={() => setShowMerkezimiz(true)}
          onMouseLeave={() => setShowMerkezimiz(false)}
        >
          <span>Merkezimiz</span>
          {showMerkezimiz && (
            <div className="dropdown">
              <a onClick={() => handleNavigate("/merkezimiz/yonetmelik")}>
                Yönetmelik
              </a>
              <a onClick={() => handleNavigate("/merkezimiz/kurulus-amacimiz")}>
                Kuruluş Amacımız
              </a>
              <a onClick={() => handleNavigate("/merkezimiz/misyon-vizyon")}>
                Misyon ve Vizyon
              </a>
              <a onClick={() => handleNavigate("/merkezimiz/calisanlarimiz")}>
                Çalışanlarımız
              </a>
              <a onClick={() => handleNavigate("/merkezimiz/tanitim-videolari")}>
                Tanıtım Videoları
              </a>
              <a onClick={() => handleNavigate("/merkezimiz/kvkk")}>
                KVKK-Aydınlatma Metni
              </a>
              <div
                className="subMenuItem"
                onMouseEnter={() => setShowIsAkisSurecleri(true)}
                onMouseLeave={() => setShowIsAkisSurecleri(false)}
              >
                <span>İş Akış Süreçleri</span>
                {showIsAkisSurecleri && (
                  <div className="subDropdown">
                    <a
                      onClick={() =>
                        handleNavigate("/merkezimiz/analiz-basvuru-sureci")
                      }
                    >
                      Analiz Başvuru Süreci
                    </a>
                    <span>Western Blot İş Akış Diyagramı</span>
                    <span>ELISA İş Akış Diyagramı</span>
                    <span>PCR İş Akış Diyagramı</span>
                    <span>RT-PCR İş Akış Diyagramı</span>
                    <span>3D Print İş Akış Diyagramı</span>
                    <span>Hücre Kültürü İş Akış Diyagramı</span>
                    <span>Görüntüleme Teknikleri İş Akış Diyagramı</span>
                    <span>Ultrasantrifüj İş Akış Diyagramı</span>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>

        {/* Yönetim */}
        <div
          className="menuItem"
          onMouseEnter={() => setShowYonetim(true)}
          onMouseLeave={() => setShowYonetim(false)}
        >
          <span>Yönetim</span>
          {showYonetim && (
            <div className="dropdown">
              <a onClick={() => handleNavigate("/yonetim")}>Yönetim</a>
              <a onClick={() => handleNavigate("/yonetim/organizasyon-semasi")}>
                Organizasyon Şeması
              </a>
              <a onClick={() => handleNavigate("/yonetim/yonetim-kurulu")}>
                Yönetim Kurulu
              </a>
              <a onClick={() => handleNavigate("/yonetim/danisma-kurulu")}>
                Danışma Kurulu
              </a>
            </div>
          )}
        </div>

        {/* Laboratuvarlar */}
        <div
          className="menuItem"
          onMouseEnter={() => setShowLaboratuvarlar(true)}
          onMouseLeave={() => setShowLaboratuvarlar(false)}
        >
          <span>Laboratuvarlar</span>
          {showLaboratuvarlar && (
            <div className="dropdown">
              <a onClick={() => handleNavigate("/biyokimya-laboratuvari")}>
                Biyokimya Laboratuvarı
              </a>
              <a
                onClick={() =>
                  handleNavigate("/kok-hucre-ve-rejeneratif-tip-laboratuvari")
                }
              >
                Kök Hücre ve Rejeneratif Tıp Laboratuvarı
              </a> 
              <a
                onClick={() =>
                  handleNavigate("/hucresel-ve-molekuler-tip-laboratuvari")
                }
              >
                Hücresel ve Moleküler Tıp Laboratuvarı
              </a>
              <a
                onClick={() =>
                  handleNavigate("/biyo-inovatif-teknoloji-laboratuvari")
                }
              >
                Biyo-inovatif Teknoloji Laboratuvarı
              </a>

              <div
                className="menuItem subMenuItem"
                onMouseEnter={() => setShowFormlar(true)}
                onMouseLeave={() => setShowFormlar(false)}
              >
                <span>Formlar</span>
                {showFormlar && (
                  <div className="subDropdown">
                    <a onClick={() => handleNavigate("/forms/etikkurul")}>
                      Etik Kurul İzin Formu
                    </a>
                    <a onClick={() => handleNavigate("/forms/biyokimya")}>
                      Biyokimya Laboratuvarı
                    </a>
                    <a onClick={() => handleNavigate("/forms/kok-hucre")}>
                      Kök Hücre ve Rejeneratif Tıp Laboratuvarı
                    </a>
                    <a onClick={() => handleNavigate("/forms/molekuler-tip")}>
                      Hücresel ve Moleküler Tıp Laboratuvarı
                    </a>
                    <a onClick={() => handleNavigate("/forms/biyo-inovatif")}>
                      Biyo-inovatif Teknoloji Laboratuvarı
                    </a>
                  </div>
                )}
              </div>

              <a onClick={() => handleNavigate("/laboratuvar-cihazlari")}>
                Laboratuvar Cihazları
              </a>
              <a onClick={() => handleNavigate("/hizmet-bedelleri")}>
                Hizmet Bedelleri
              </a>
            </div>
          )}
        </div>

        {/* Araştırmalar */}
        <div
          className="menuItem"
          onMouseEnter={() => setShowArastirmalar(true)}
          onMouseLeave={() => setShowArastirmalar(false)}
        >
          <span>Araştırmalar</span>
          {showArastirmalar && (
            <div className="dropdown">
              <a onClick={() => handleNavigate("/arastirmalar/altyapi-projelerimiz")}>
                Alt Yapı Projelerimiz
              </a>
              <a onClick={() => handleNavigate("/arastirmalar/egitimler-seminerler")}>
                Eğitimler ve Seminerler
              </a>
              <a onClick={() => handleNavigate("/arastirmalar/staj-islemleri")}>
                Staj İşlemleri
              </a>
              <div
                className="subMenuItem"
                onMouseEnter={() => setShowReports(true)}
                onMouseLeave={() => setShowReports(false)}
              >
                <span>Faaliyet Raporları</span>
                {showReports && (
                  <div className="subDropdown">
                    <a onClick={() => handleNavigate("/arastirmalar/reports/2024")}>
                      2024
                    </a>
                    <a onClick={() => handleNavigate("/arastirmalar/reports/2023")}>
                      2023
                    </a>
                    <a onClick={() => handleNavigate("/arastirmalar/reports/2022")}>
                      2022
                    </a>
                    <a onClick={() => handleNavigate("/arastirmalar/reports/2021")}>
                      2021
                    </a>
                    <a onClick={() => handleNavigate("/arastirmalar/reports/2020")}>
                      2020
                    </a>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>

        {/* Duyurular */}
        <span onClick={() => handleNavigate("/duyurular")} className="menu-link">
          Duyurular
        </span>

        {/* Analizler */}
        <span onClick={() => handleNavigate("/analizler")} className="menu-link">
          Analizler
        </span>

        {/* İletişim */}
        <span onClick={() => handleNavigate("/iletisim")} className="menu-link">
          İletişim
        </span>
      </div>
    </div>
  );
};

export default ToastMenu;
