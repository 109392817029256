import React, { useState } from 'react';
import './EtikKurulIzinForm.css';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';

const EtikKurulIzinForm = () => {
  const [formData, setFormData] = useState({
    projectName: '',
    fullName: '',
    department: '',
    date: '',
  });
  const [loading, setLoading] = useState(false);

  console.log('EtikKurulIzinForm component rendered.');

  const handleInputChange = (e) => {
    console.log('handleInputChange called.', e.target.name, e.target.value);
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const getPrintContent = () => {
    console.log('getPrintContent called.');
    // Daha büyük font, sabit genişlik (600px), resmi bir düzen
    return `
      <div id="print-content" style="
        font-family: Arial, sans-serif; 
        width: 600px;
        margin: 0 auto;
        line-height:1.5;
        font-size:16px;
        color:#000;
      ">

        <div style="text-align:right; font-size:14px; margin-bottom:20px;">
          Tarih: ${formData.date || '...'}
        </div>

        <div style="text-align:center; margin-bottom:30px;">
          <h1 style="font-size:18px; margin:0; font-weight:bold;">T.C.</h1>
          <h2 style="font-size:18px; margin:10px 0 0 0; font-weight:bold;">KÜTAHYA SAĞLIK BİLİMLERİ ÜNİVERSİTESİ REKTÖRLÜĞÜ</h2>
          <h3 style="font-size:14px; margin:10px 0 0 0; font-weight:normal;">
            MERKEZİ ARAŞTIRMA LABORATUVARI UYGULAMA VE ARAŞTIRMA MERKEZİ MÜDÜRLÜĞÜ'NE
          </h3>
        </div>

        <div style="margin:20px; text-align:justify;">
          <p style="margin-bottom:20px;">
            Bilimsel araştırma projesi olarak belirlenen 
            <strong>"${formData.projectName || '...'}"</strong> isimli çalışmayı 
            Merkezi Araştırma Laboratuvarı (KUYAM)'da yapmak istiyorum. 
            Gerekli izinlerin verilmesi hususunda.
          </p>
          <p style="margin-bottom:20px;">Gereğini arz ederim.</p>
        </div>

        <div style="margin-top:30px; display:flex; justify-content:flex-end; margin:20px;">
          <div style="text-align:right; margin-right:10px; font-weight:bold;"></div>
          <div style="text-align:left;">
            <p style="margin:0; font-weight:bold;">${formData.fullName || '...'}</p>
            <p style="margin:0;">${formData.department || '...'}</p>
          </div>
        </div>
        
      </div>
    `;
  };

  const saveToDatabase = async () => {
    console.log('saveToDatabase başladı, gönderilecek veriler:', formData);
    try {
      setLoading(true);
      console.log('Fetch isteği gönderiliyor...');
      const response = await fetch('https://kuyam.ksbu.edu.tr:443/api/ethics-form', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          projectName: formData.projectName,
          fullName: formData.fullName,
          department: formData.department,
          date: formData.date
        })
      });
      console.log('Fetch yanıtı alındı. Status:', response.status);
      
      if (!response.ok) {
        const errorData = await response.json();
        console.error('Hata yanıtı:', errorData);
        throw new Error(errorData.error || 'Form kaydedilemedi');
      }
  
      const result = await response.json();
      console.log('Kayıt başarılı:', result);
      alert(`Form başarıyla kaydedildi. Form Numarası: ${result.data.form_number}`);
      return true;
    } catch (error) {
      console.error('Hata oluştu:', error);
      alert('Hata: ' + error.message);
      return false;
    } finally {
      console.log('saveToDatabase finally bloğu');
      setLoading(false);
    }
  };

  const handlePrint = async () => {
    console.log('handlePrint called.');
    const savedSuccessfully = await saveToDatabase();
    console.log('savedSuccessfully:', savedSuccessfully);

    if (!savedSuccessfully) {
      console.log('Kayıt başarısız, PDF oluşturma iptal.');
      return;
    }

    console.log('Kayıt başarılı, getPrintContent çağırılıyor.');
    const printContent = getPrintContent();
    console.log('printContent alındı.', { printContent });

    // Geçici div oluştur
    const tempDiv = document.createElement('div');
    console.log('Geçici div oluşturuldu.', tempDiv);
    tempDiv.innerHTML = printContent;
    document.body.appendChild(tempDiv);
    console.log('Temp div bodye eklendi.');

    setTimeout(async () => {
      try {
        console.log('html2canvas ile ekran görüntüsü alınıyor...');
        const canvas = await html2canvas(tempDiv, { scale: 2 }); 
        const imgData = canvas.toDataURL('image/png');
        console.log('Görüntü data alındı.');

        const pdf = new jsPDF('p', 'mm', 'a4');
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);

        console.log('PDF oluşturuldu, yeni sekmede açılıyor...');
        const pdfUrl = pdf.output('bloburl');
        window.open(pdfUrl, '_blank');
      } catch (err) {
        console.error('PDF oluşturma hatası:', err);
        alert('PDF oluşturulurken hata oluştu: ' + err.message);
      } finally {
        console.log('Temp div kaldırılıyor...');
        document.body.removeChild(tempDiv);
        console.log('Temp div kaldırıldı.');
      }
    }, 100);
  };

  return (
    <div className="etikNoGap-root">
      <div className="etikNoGap-form-preview-wrapper">
        <div className="etikNoGap-form-container">
          <h1 className="etikNoGap-form-title">Etik Kurul İzin Formu</h1>

          <div className="etikNoGap-form-group">
            <label>Proje İsmi:</label>
            <input
              type="text"
              name="projectName"
              value={formData.projectName}
              onChange={handleInputChange}
            />
          </div>
          <div className="etikNoGap-form-group">
            <label>Ad Soyad:</label>
            <input
              type="text"
              name="fullName"
              value={formData.fullName}
              onChange={handleInputChange}
            />
          </div>
          <div className="etikNoGap-form-group">
            <label>Bölüm/Anabilim Dalı:</label>
            <input
              type="text"
              name="department"
              value={formData.department}
              onChange={handleInputChange}
            />
          </div>
          <div className="etikNoGap-form-group">
            <label>Tarih:</label>
            <input
              type="text"
              name="date"
              value={formData.date}
              onChange={handleInputChange}
            />
          </div>

          <button
            onClick={handlePrint}
            className="etikNoGap-print-button"
            disabled={loading}
          >
            {loading ? 'Kaydediliyor...' : 'PDF Olarak Aç'}
          </button>
        </div>

        <div className="etikNoGap-preview-container">
          <h2>Önizleme</h2>
          <div
            className="etikNoGap-preview-content"
            dangerouslySetInnerHTML={{ __html: getPrintContent() }}
          />
        </div>
      </div>
    </div>
  );
};

export default EtikKurulIzinForm;
