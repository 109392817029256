// src/pages/Videos/Videos.js
import React, { useState } from 'react';
import './Videos.css';

const Videos = () => {
    const videoData = [
        {
            id: '001',
            title: "Kütahya Sağlık Bilimleri Üniversitesi ailesine hoşgeldiniz",
            src: "/video/001.mp4"
        },
        {
            id: '002',
            title: "KUYAM'da sakin bir cuma günü..",
            src: "/video/002.mp4"
        },
        {
            id: '003',
            title: "30 Ağustos Zafer Bayramımız kutlu olsun 🇹🇷",
            src: "/video/003.mp4"
        },
        {
            id: '004',
            title: "Gelişerek büyüyoruz. #ksbü",
            src: "/video/004.mp4"
        },
        {
            id: '005',
            title: "Hücre çalışmaları için biyo-güvenlik sınıf II kabinimiz Kuyam'da yerini aldı. Bu sayede primer hücre kültürü ve rutin hücre kültürü çalışmaları yapılabilecektir.",
            hashtags: "#cellculture #primercellculture #researchanddevelopment #researchandhealthcare",
            src: "/video/005.mp4"
        },
        {
            id: '006',
            title: "Yeni class II güvenlik kabimizle Dr. Öğretim Üyesi Derya Berikten ve Öğretim Görevlisi Elif Aydın çalışmalarına başladılar.",
            src: "/video/006.mp4"
        },
        {
            id: '007',
            title: "Formlabs 3D Printer ile hem mühendislik uygulamaları hem de kişiye özel protetik çözümler için çalışmalarımıza başladık.",
            hashtags: "#ksbü #ksbükuyam #innovation #3dprinting",
            src: "/video/007.mp4"
        },
        {
            id: '008',
            title: "Küçük hanımlar, küçük beyler! Sizler hepiniz geleceğin bir gülü, yıldızı ve ikbal ışığısınız. Memleketi asıl ışığa boğacak olan sizsiniz. Kendinizin ne kadar önemli, değerli olduğunuzu düşünerek ona göre çalışınız. Sizlerden çok şey bekliyoruz. Mustafa",
            src: "/video/008.mp4"
        },
        {
            id: '009',
            title: "What is KSBU",
            hashtags: "#KSBU #KSBUKUYAM #highereducation #healthsciences #DoctorofPhilosophy #masterofscience #PhD #MSc",
            src: "/video/009.mp4"
        },
        {
            id: '010',
            title: "Başta Gazi Mustafa Kemal Atatürk olmak üzere tüm aziz şehit ve gazilerimize minnetle",
            hashtags: "#18martçanakkalezaferi",
            src: "/video/010.mp4"
        }
    ];

    const [selectedVideo, setSelectedVideo] = useState(null);

    return (
        <div className="videos-container">
            <div className="videos-header">
                <h1>Tanıtım Videoları</h1>
            </div>

            {selectedVideo && (
                <div className="video-modal" onClick={() => setSelectedVideo(null)}>
                    <div className="video-modal-content" onClick={e => e.stopPropagation()}>
                        <button className="close-button" onClick={() => setSelectedVideo(null)}>✕</button>
                        <video controls autoPlay>
                            <source src={selectedVideo.src} type="video/mp4" />
                            Tarayıcınız video elementini desteklemiyor.
                        </video>
                        <div className="video-info">
                            <h3>{selectedVideo.title}</h3>
                            {selectedVideo.hashtags && (
                                <div className="hashtags">{selectedVideo.hashtags}</div>
                            )}
                        </div>
                    </div>
                </div>
            )}

            <div className="videos-grid">
                {videoData.map((video) => (
                    <div 
                        key={video.id} 
                        className="video-card"
                        onClick={() => setSelectedVideo(video)}
                    >
                        <div className="video-thumbnail">
                            <video>
                                <source src={video.src} type="video/mp4" />
                            </video>
                            <div className="play-button">▶</div>
                        </div>
                        <div className="video-info">
                            <h3>{video.title}</h3>
                            {video.hashtags && (
                                <div className="hashtags">{video.hashtags}</div>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Videos;