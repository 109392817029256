import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './KuyamBasvuru.css';
import warningIcon from '../../assets/warning-icon.svg';
import kuyamLogo from '../../assets/kuyam_logo.png';
import trFlag from '../../assets/tr.png';
import enFlag from '../../assets/en.png';
import { Eye, EyeOff, ArrowLeft } from 'lucide-react';

const cleanText = (text) => text.replace(/^[AU]/, '');

const KuyamBasvuru = () => {
  const [currentView, setCurrentView] = useState('login');
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    confirmPassword: '',
    rememberMe: false,
  });
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({});
  const [resetEmailSent, setResetEmailSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const validateForm = () => {
    const newErrors = {};
    if (!formData.email) {
      newErrors.email = 'E-posta adresi gereklidir';
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      newErrors.email = 'Geçerli bir e-posta adresi giriniz';
    }

    if (!formData.password) {
      newErrors.password = 'Şifre gereklidir';
    } else if (formData.password.length < 6) {
      newErrors.password = 'Şifre en az 6 karakter olmalıdır';
    }

    if (currentView === 'register' && formData.password !== formData.confirmPassword) {
      newErrors.confirmPassword = 'Şifreler eşleşmiyor';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setIsLoading(true);
    try {
      if (currentView === 'login') {
        const response = await fetch('http://kuyam.ksbu.edu.tr:3001/api/auth/login', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            email: formData.email,
            password: formData.password,
            rememberMe: formData.rememberMe
          }),
        });

        const data = await response.json();

        if (!response.ok) {
          throw new Error(data.error || 'Giriş işlemi başarısız');
        }

        localStorage.setItem('token', data.token);
        toast.success('Giriş başarılı! Yönlendiriliyorsunuz...');
        setTimeout(() => {
          navigate('/kuyam-basvuru/dashboard');
        }, 2000);

      } else {
        const response = await fetch('http://kuyam.ksbu.edu.tr:3001/api/auth/register', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            email: formData.email,
            password: formData.password,
          }),
        });

        const data = await response.json();

        if (!response.ok) {
          throw new Error(data.error || 'Kayıt işlemi başarısız');
        }

        toast.success('Kayıt başarılı! Giriş yapabilirsiniz.');
        setTimeout(() => {
          setCurrentView('login');
          setFormData({ email: '', password: '', confirmPassword: '', rememberMe: false });
        }, 2000);
      }
    } catch (error) {
      toast.error(error.message);
      setErrors({ submit: error.message });
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
    if (errors[name]) {
      setErrors(prev => ({ ...prev, [name]: '' }));
    }
  };

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    if (!formData.email) {
      setErrors({ email: 'E-posta adresi gereklidir' });
      return;
    }
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      setErrors({ email: 'Geçerli bir e-posta adresi giriniz' });
      return;
    }

    setIsLoading(true);
    try {
      const response = await fetch('http://kuyam.ksbu.edu.tr:3001/api/auth/forgot-password', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: formData.email }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || 'Şifre sıfırlama işlemi başarısız');
      }

      setResetEmailSent(true);
      toast.success('Şifre sıfırlama bağlantısı e-posta adresinize gönderildi');
    } catch (error) {
      toast.error(error.message);
      setErrors({ submit: error.message });
    } finally {
      setIsLoading(false);
    }
  };

  const renderForgotPasswordForm = () => (
    <form onSubmit={handleForgotPassword} className="auth-form">
      <h2>Şifre Sıfırlama</h2>
      <div className="form-group">
        <input
          type="email"
          name="email"
          placeholder="E-Posta"
          value={formData.email}
          onChange={handleInputChange}
          className={errors.email ? 'error' : ''}
          disabled={isLoading}
        />
        {errors.email && <span className="error-message">{errors.email}</span>}
      </div>
      {errors.submit && <div className="error-message submit-error">{errors.submit}</div>}
      <button
        type="submit"
        className={`login-button ${isLoading ? 'loading' : ''}`}
        disabled={isLoading}
      >
        {isLoading ? (
          <span className="loading-spinner"></span>
        ) : 'Şifre Sıfırlama Bağlantısı Gönder'}
      </button>
    </form>
  );

  const renderLoginRegisterForm = () => (
    <form onSubmit={handleSubmit} className="auth-form">
      <h2>{currentView === 'login' ? 'Giriş Yap' : 'Yeni Üyelik'}</h2>
      
      <div className="form-group">
        <input
          type="email"
          name="email"
          placeholder="E-Posta"
          value={formData.email}
          onChange={handleInputChange}
          className={errors.email ? 'error' : ''}
          disabled={isLoading}
        />
        {errors.email && <span className="error-message">{errors.email}</span>}
      </div>

      <div className="form-group password-group">
        <input
          type={showPassword ? "text" : "password"}
          name="password"
          placeholder="Şifre"
          value={formData.password}
          onChange={handleInputChange}
          className={errors.password ? 'error' : ''}
          disabled={isLoading}
        />
        <button
          type="button"
          className="toggle-password"
          onClick={() => setShowPassword(!showPassword)}
          disabled={isLoading}
        >
          {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
        </button>
        {errors.password && <span className="error-message">{errors.password}</span>}
      </div>

      {currentView === 'register' && (
        <div className="form-group password-group">
          <input
            type={showPassword ? "text" : "password"}
            name="confirmPassword"
            placeholder="Şifre Tekrar"
            value={formData.confirmPassword}
            onChange={handleInputChange}
            className={errors.confirmPassword ? 'error' : ''}
            disabled={isLoading}
          />
          {errors.confirmPassword && 
            <span className="error-message">{errors.confirmPassword}</span>
          }
        </div>
      )}

      {currentView === 'login' && (
        <div className="remember-me">
          <input
            type="checkbox"
            id="rememberMe"
            name="rememberMe"
            checked={formData.rememberMe}
            onChange={handleInputChange}
            disabled={isLoading}
          />
          <label htmlFor="rememberMe">Beni Hatırla</label>
        </div>
      )}

      {errors.submit && <div className="error-message submit-error">{errors.submit}</div>}

      <button
        type="submit"
        className={`login-button ${isLoading ? 'loading' : ''}`}
        disabled={isLoading}
      >
        {isLoading ? (
          <span className="loading-spinner"></span>
        ) : currentView === 'login' ? 'Giriş' : 'Üye Ol'}
      </button>
    </form>
  );

  return (
    <div className="page-container">
      <div className="kuyam-container">
        <div className="login-box">
          <div className="logos">
            <img src={kuyamLogo} alt="KUYAM Logo" className="kuyam-logo" />
          </div>
          
          {currentView === 'forgotPassword' ? renderForgotPasswordForm() : renderLoginRegisterForm()}
          
          {currentView !== 'forgotPassword' && (
            // KuyamBasvuru.js içinde links bölümü
            <div className="links">
              {currentView === 'login' ? (
                <>
                  <a 
                    href="#" 
                    className="forgot-password"
                    onClick={(e) => {
                      e.preventDefault();
                      setCurrentView('forgotPassword');
                      setFormData(prev => ({ ...prev, password: '', confirmPassword: '' }));
                      setErrors({});
                    }}
                  >
                    Şifrenizi unuttunuz?
                  </a>
                  <a 
                    href="#" 
                    className="register"
                    onClick={(e) => {
                      e.preventDefault();
                      setCurrentView('register');
                      setFormData({ email: '', password: '', confirmPassword: '', rememberMe: false });
                      setErrors({});
                    }}
                  >
                    Yeni üyelik için tıklayınız
                  </a>
                </>
              ) : (
                <a 
                  href="#" 
                  className="login-link"
                  onClick={(e) => {
                    e.preventDefault();
                    setCurrentView('login');
                    setFormData({ email: '', password: '', confirmPassword: '', rememberMe: false });
                    setErrors({});
                  }}
                >
                  Zaten üye misiniz? Giriş yapın
                </a>
              )}
            </div>
          )}
          
          <div className="language-flags">
            <img src={trFlag} alt="Türkçe" className="flag" />
            <img src={enFlag} alt="English" className="flag" />
          </div>
        </div>

        <div className="info-box">
          <div className="header">
            <h1>KUYAM Otomasyon Sistemi</h1>
            <h2>KÜTAHYA SAĞLIK BİLİMLERİ ÜNİVERSİTESİ UYGULAMA VE ARAŞTIRMA MERKEZİ</h2>
          </div>
          
          <div className="notice">
            <img src={warningIcon} alt="Uyarı" className="warning-icon" />
            <div className="notice-content">
              <h3>Değerli Analiz Sahibi;</h3>
              <p>
                Üniversitemiz ve Merkezimiz kalite hedefleri doğrultusunda sizlere daha iyi hizmet verebilmek için yeni otomasyon programımız kullanıma açılmıştır. 
                <span className="highlight">Kullanıcı kimliği oluşturarak hemen analiz talebinde bulunabilirsiniz.</span>
              </p>              
              <p>
                Herhangi bir desteğe ihtiyaç duymanız halinde 
                <a href="mailto:kuyam@ksbu.edu.tr" className="email">
                  {cleanText('kuyam@ksbu.edu.tr')}
                </a>
                e-posta adresinden veya 
                <span className="phone">
                  {cleanText('+90 (274) 260 00 43-46 Dahili (1260)')}
                </span>
                numaralı telefondan bizimle iletişime geçebilirsiniz. İlginiz için teşekkür ederiz.
              </p>

            </div>
          </div>


          
          <div className="note">
            <p><strong>Not:</strong> <em>Yeni üye kaydı yaparken fatura adresiniz belli değil ise Ad Soyad yazıp ilerleyebilirsiniz. Fatura bilgisini daha sonra hesabınıza giriş yapıp, sol taraftaki menüde "Bilgilerim & Şifre" sekmesi altında Düzenle işlemini seçerek güncelleyebilirsiniz.</em></p>
          </div>

          <div className="process-steps">
            <div className="step">
              <h3>1- Fiyat Teklifi (Proforma) Oluşturma</h3>
              <p>Teklifinizi kendiniz oluşturabilirsiniz. Onaylanmasının ardından sistemden ulaşabilirsiniz.</p>
            </div>
            
            <div className="step">
              <h3>2- Analiz Talebi Oluşturma</h3>
              <p>Numune Kabul Biriminin kontrol ederek onaylamasının ardından analiz sürecinizi başlatabilirsiniz.</p>
            </div>
            
            <div className="step">
              <h3>3- Analiz Sürecini Takip Etme</h3>
              <p>Analiz sürecinizi takip edebilir, analiz sonuçlarınıza ulaşabilirsiniz.</p>
            </div>
            
            <div className="step">
              <h3>4- Ödeme İşlemleri Takibi</h3>
              <p>Ödeme işlemlerinizi ve finansal durumunuzu görebilir ve takip edebilirsiniz.</p>
            </div>
          </div>

          <div className="contact-info">
            <div className="contact-item phone">
              <i className="phone-icon"></i>
              <a href="tel:02744431818">+90 (274) 260 00 43-44-45-46 Dahili (1260)</a>
            </div>
            
            <div className="contact-item email">
              <i className="email-icon"></i>
              <a href="mailto:kuyam.analiz@ksbu.edu.tr">kuyam@ksbu.edu.tr</a>
            </div>
            
            <div className="contact-item website">
              <i className="website-icon"></i>
              <a href="https://kuyamlabsis.ksbu.edu.tr/" target="_blank" rel="noopener noreferrer">
                https://kuyamlabsis.ksbu.edu.tr/
              </a>
            </div>
          </div>

          <div className="video-container">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/YOUR_VIDEO_ID"
              title="Laboratuvar Yazılımı Kayıt, Giriş ve Analiz Talebi"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen>
            </iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KuyamBasvuru;