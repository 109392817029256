import React from 'react';
import './OrganizasyonSema.css';

const OrganizasyonSema = () => {
  const laboratories = [
    "Biyokimya Laboratuvarı",
    "Kök Hücre ve Rejeneratif Tıp Laboratuvarı",
    "Hücresel ve Moleküler Tıp Laboratuvarı",
    "Biyo-inovatif Teknoloji Laboratuvarı"
  ];

  return (
    <div className="org-page-container">
      <div className="org-content">
        <div className="org-header">
          <h1 className="org-title">Organizasyon Şeması</h1>
          <div className="org-subtitle">KUYAM (UYGULAMA VE ARAŞTIRMA MERKEZİ)</div>
        </div>

        <div className="org-chart-container">
          <div className="org-tree">
            {/* Müdür */}
            <div className="level level-1">
              <div className="org-box director">
                <h3>MÜDÜR</h3>
                <p>Prof. Dr. Ahmet TEKİN</p>
              </div>
            </div>

            {/* Koordinatör */}
            <div className="level level-2">
              <div className="org-box coordinator">
                <h3>KOORDİNATÖR</h3>
                <p>Doç. Dr. Fatih KAR</p>
              </div>
            </div>

            {/* Laboratuvar Sorumlusu ve Laboratuvarlar */}
            <div className="level level-4">
              <div className="lab-section">
                <div className="org-box lab-supervisor">
                  <h3>LABORATUVAR SORUMLUSU</h3>
                </div>
                <div className="laboratories">
                  {laboratories.map((lab, index) => (
                    <div key={index} className="lab-box">
                      {lab}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="org-info">
          <div className="info-card">
            <h2>Organizasyon Yapımız</h2>
            <p>
              KUYAM, bilimsel araştırma ve geliştirme faaliyetlerini etkin bir şekilde 
              yürütmek için hiyerarşik bir yapıda organize edilmiştir. Merkez müdürü 
              liderliğindeki yönetim yapısı, koordinatör ve yardımcısı ile desteklenmekte, 
              laboratuvar çalışmaları ise laboratuvar sorumlusu gözetiminde yürütülmektedir.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrganizasyonSema;