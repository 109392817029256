// src/components/Footer/Footer.js
import React from 'react';
import './Footer.css';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-left">
                <div className="ssl-info">
                    <span className="ssl-text">This site is</span>
                    <span className="ssl-secured">SSL SECURED</span>
                </div>
            </div>
            <div className="footer-center">
                Kütahya Sağlık Bilimleri Üniversitesi İleri Teknolojiler Merkezi © Tüm Hakları Saklıdır.
            </div>
            <div className="footer-right">
                KSBÜ - BİDB
            </div>
        </footer>
    );
};

export default Footer;