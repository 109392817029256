import React from 'react';
import './KokHucreLab.css';

function KokHucreLab() {
    return (
        <div className="laboratory-container">
            <div className="laboratory-content">
                <h1 className="lab-title">Kök Hücre ve Rejeneratif Tıp Laboratuvarı</h1>
                
                <div className="lab-description">
                    <p>
                        Laboratuvarımızda; <em>in vitro</em> kök hücre araştırmaları, hücresel ve 
                        moleküler tıp modülüne paralel tüm moleküler analizler ile gerçekleştirilebilmekte, 
                        uygulanan ilaç ve moleküllerin hücre proliferasyonuna etkisini, morfolojik 
                        değişimleri ve sitotoksisite çalışmaları ile test edilebilmektedir.
                    </p>
                    
                    <p>
                        Araştırma imkanlarının genişlemesi ile birlikte in vivo hayvan çalışmalarına 
                        entegrasyon sağlanması planlanmaktadır.
                    </p>
                    
                    <p>
                        Kök hücre yöntemini çalışmalarına dahil etmek isteyen akademisyenlerimizin 
                        proje süreçlerine de teknik olarak destek sağlamaktayız.
                    </p>
                </div>

                <div className="lab-contact">
                    <h2>Sorumlu Akademisyen</h2>
                    <p>Dr. Öğr. Üyesi NESLİHAN MERİÇ</p>
                    
                    <div className="last-update">
                        <p>Son Güncelleme Tarihi: 01 Ağustos 2024, Perşembe</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default KokHucreLab;