// src/components/FooterUp/FooterUp.js
import React from 'react';
import logo from './logo-1.png';
import './FooterUp.css';

const FooterUp = () => {
    return (
        <div className="footer-up">
            <div className="footer-up-left">
                <div className="logo-name-container">
                    <img src={logo} alt="DPÜ Logo" className="footer-logo" />
                    <div className="university-name">
                        <span className="university-text">KÜTAHYA</span>
                        <span className="university-text">SAĞLIK</span>
                        <span className="university-text">BİLİMLERİ</span>
                        <span className="university-text">ÜNİVERSİTESİ</span>
                    </div>
                </div>
            </div>
            <div className="footer-up-right">
                <div className="contact-info">
                    <p><strong>Adres:</strong> Zafertepe, 43020 Kütahya Merkez/Kütahya</p>
                    <p><strong>Telefon:</strong> 0 (274) 260 00 43 - 46 Dahili (1260)</p>
                    <p><strong>Faks:</strong> 0 (274) 260 00 43</p>
                    <p><strong>E-mail (Bilgi ve Destek):</strong> kuyam@ksbu.edu.tr</p>
                    <p><strong>E-mail (Analiz İşlemleri):</strong> kuyam@ksbu.edu.tr</p>
                    <a href="#" className="kvkk-link">KVKK - Aydınlatma Metni</a>
                </div>
            </div>
        </div>
    );
};

export default FooterUp;