// src/pages/kuyam-basvuru/dashboard/DashboardLayout.jsx
import React from 'react';
import { useLocation } from 'react-router-dom';
import DashboardNavbar from './components/DashboardNavbar';
import Sidebar from './components/Sidebar';
import QuickAccess from './components/QuickAccess';
import './DashboardLayout.css';

const DashboardLayout = ({ children }) => {
  const location = useLocation();
  const isHomePage = location.pathname === '/kuyam-basvuru/dashboard' || location.pathname === '/kuyam-basvuru/dashboard/';

  return (
    <div className="dashboard-layout">
      {/* Navbar */}
      <DashboardNavbar />
      
      {/* Main Container */}
      <div className="dashboard-container">
        {/* Sidebar */}
        <div className="dashboard-sidebar">
          <Sidebar />
        </div>

        {/* Main Content */}
        <div className="dashboard-content">
          {isHomePage && <QuickAccess />} {/* QuickAccess sadece ana sayfada gösterilecek */}
          <div className="dashboard-children">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;