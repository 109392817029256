import React, { useState } from 'react';
import { X, Upload, AlertTriangle } from 'lucide-react';
import SampleInfoModal from './SampleInfoModal';
import './CreateRequestModal.css';

const CreateRequestModal = ({ isOpen, onClose }) => {
  const [formData, setFormData] = useState({
    paymentSource: '',
    usageIntent: '',
    projectNo: '',
    supportedOrg: '',
    file: null,
    sampleCount: ''
  });

  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const [showSampleWarning, setShowSampleWarning] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  if (!isOpen) return null;

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsSubmitting(true);
      console.log('Form data:', formData);
      // API çağrısı burada yapılacak
      await new Promise(resolve => setTimeout(resolve, 1000)); // Simüle edilmiş API çağrısı
      onClose();
    } catch (error) {
      console.error('Form submission error:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleFileChange = (e) => {
    if (e.target.files?.length) {
      setFormData(prev => ({
        ...prev,
        file: e.target.files[0]
      }));
    }
  };

  const handleAddSample = () => {
    if (!formData.sampleCount) {
      setShowSampleWarning(true);
      return;
    }
    setShowSampleWarning(false);
    // Numune ekleme işlemi
    console.log('Numune ekleniyor:', formData.sampleCount);
  };

  return (
    <div className="kuyam-request-modal__overlay">
      <div className="kuyam-request-modal__container">
        {/* Modal Header */}
        <div className="kuyam-request-modal__header">
          <h2 className="kuyam-request-modal__title">Analiz İstek Formu Düzenle</h2>
          <button 
            className="kuyam-request-modal__close-btn"
            onClick={onClose}
            aria-label="Kapat"
          >
            <X size={24} />
          </button>
        </div>

        {/* Warning Banner */}
        <div className="kuyam-request-modal__warning">
          <AlertTriangle className="kuyam-request-modal__warning-icon" size={20} />
          <div className="kuyam-request-modal__warning-content">
            <div>Dikkat: 10 ve üzeri numunelere indirim uygulanması için NUMUNELERİN HEPSİNİN AYNI ANDA LABORATUVARA GELMESİ GEREKMEKTEDİR.</div>
            <div className="kuyam-request-modal__warning-highlight">
              BAP Projeleri için BAP'tan "Malzeme İstek Formu" alıp 
              <span className="kuyam-request-modal__warning-text-highlight"> Ekli Dosya </span>
              alanına yükleyiniz!
            </div>
          </div>
        </div>

        <form onSubmit={handleSubmit} className="kuyam-request-modal__form">
          {/* User Info Section */}
          <div className="kuyam-request-modal__info-section">
            <div className="kuyam-request-modal__info-grid">
              <div className="kuyam-request-modal__info-item">
                <label className="kuyam-request-modal__info-label">Adı Soyadı</label>
                <div className="kuyam-request-modal__info-value">Önder Öztürk</div>
              </div>
              <div className="kuyam-request-modal__info-item">
                <label className="kuyam-request-modal__info-label">Tutar</label>
                <div className="kuyam-request-modal__info-value">0.00</div>
              </div>
              <div className="kuyam-request-modal__info-item">
                <label className="kuyam-request-modal__info-label">Ünvan</label>
                <div className="kuyam-request-modal__info-value">Öğretim Görevlisi</div>
              </div>
              <div className="kuyam-request-modal__info-item">
                <label className="kuyam-request-modal__info-label">KDV Oranı</label>
                <div className="kuyam-request-modal__info-value">%20 KDV: 0.00</div>
              </div>
              <div className="kuyam-request-modal__info-item">
                <label className="kuyam-request-modal__info-label">İstek Tarihi</label>
                <div className="kuyam-request-modal__info-value">14.11.2024</div>
              </div>
              <div className="kuyam-request-modal__info-item">
                <label className="kuyam-request-modal__info-label">Toplam</label>
                <div className="kuyam-request-modal__info-value">0.00</div>
              </div>
              <div className="kuyam-request-modal__info-item">
                <label className="kuyam-request-modal__info-label">Durum</label>
                <div className="kuyam-request-modal__info-value">Taslak</div>
              </div>
              <div className="kuyam-request-modal__info-item">
                <label className="kuyam-request-modal__info-label">Teklif No</label>
                <div className="kuyam-request-modal__info-value">1045</div>
              </div>
            </div>
          </div>

          {/* Form Fields */}
          <div className="kuyam-request-modal__form-grid">
            <div className="kuyam-request-modal__form-group">
              <label className="kuyam-request-modal__label">
                Ödeme Kaynağı *
              </label>
              <select 
                name="paymentSource"
                value={formData.paymentSource}
                onChange={handleChange}
                required
                className="kuyam-request-modal__select"
              >
                <option value="">Seçiniz</option>
                <option value="bap">BAP Projesi</option>
                <option value="individual">Bireysel</option>
                <option value="other_projects">Diğer Projeler</option>
                <option value="university_budget">Üniversite Bütçesi</option>
              </select>
            </div>

            <div className="kuyam-request-modal__form-group">
              <label className="kuyam-request-modal__label">
                Analiz sonuçlarının kullanım amacı *
              </label>
              <select 
                name="usageIntent"
                value={formData.usageIntent}
                onChange={handleChange}
                required
                className="kuyam-request-modal__select"
              >
                <option value="">Seçiniz</option>
                <option value="masters">Y.Lisans</option>
                <option value="phd">Doktora</option>
                <option value="project">Proje</option>
                <option value="consulting">Danışmanlık</option>
                <option value="other">Diğer</option>
              </select>
            </div>

            <div className="kuyam-request-modal__form-group">
              <label className="kuyam-request-modal__label">
                Proje No
              </label>
              <input
                type="text"
                name="projectNo"
                value={formData.projectNo}
                onChange={handleChange}
                className="kuyam-request-modal__input"
              />
            </div>

            <div className="kuyam-request-modal__form-group">
              <label className="kuyam-request-modal__label">
                Destek Alınan Kuruluş
              </label>
              <input
                type="text"
                name="supportedOrg"
                value={formData.supportedOrg}
                onChange={handleChange}
                className="kuyam-request-modal__input"
              />
            </div>

            <div className="kuyam-request-modal__form-group kuyam-request-modal__form-group--full">
              <label className="kuyam-request-modal__label">
                Ekli Dosya
              </label>
              <div className="kuyam-request-modal__file-upload">
                <input
                  type="file"
                  id="file-upload"
                  onChange={handleFileChange}
                  className="kuyam-request-modal__file-input"
                />
                <label htmlFor="file-upload" className="kuyam-request-modal__file-label">
                  <Upload size={24} />
                  <span>
                    {formData.file ? formData.file.name : 'Dosya seçin veya sürükleyin'}
                  </span>
                </label>
              </div>
            </div>
          </div>

          {/* Sample Management Section */}
          <div className="kuyam-request-modal__sample-section">
            <h3 className="kuyam-request-modal__section-title">
              Analiz eklemek için numune ekleyiniz
            </h3>
            <div className="kuyam-request-modal__sample-input-group">
              <input
                type="number"
                name="sampleCount"
                value={formData.sampleCount}
                onChange={handleChange}
                placeholder="Numune / Paket Sayısı"
                className={`kuyam-request-modal__input ${showSampleWarning ? 'error' : ''}`}
                min="1"
              />
              <button 
                type="button" 
                className="kuyam-request-modal__btn kuyam-request-modal__btn--success"
                onClick={handleAddSample}
              >
                Ekle
              </button>
            </div>
            <div 
              className="kuyam-request-modal__sample-warning"
              onClick={() => setIsInfoModalOpen(true)}
            >
              Dikkat: Numune Eklemeden önce okuyunuz
            </div>
            {showSampleWarning && (
              <div className="kuyam-request-modal__error-message">
                Lütfen numune sayısı giriniz
              </div>
            )}
          </div>

          {/* Samples Table */}
          <div className="kuyam-request-modal__table-container">
            <table className="kuyam-request-modal__table">
              <thead>
                <tr>
                  <th>Sıra/Kod</th>
                  <th>Miktar</th>
                  <th>Birim Fiyat</th>
                  <th>Tutar</th>
                  <th>Analiz Ekle / Sil</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan="5" className="kuyam-request-modal__table-empty">
                    Henüz numune eklenmemiş
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          {/* Form Actions */}
          <div className="kuyam-request-modal__form-actions">
            <button 
              type="submit" 
              className={`kuyam-request-modal__btn kuyam-request-modal__btn--primary ${
                isSubmitting ? 'kuyam-request-modal__loading' : ''
              }`}
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Kaydediliyor...' : 'Kaydet'}
            </button>
          </div>
        </form>
      </div>

      {/* Info Modal */}
      <SampleInfoModal 
        isOpen={isInfoModalOpen}
        onClose={() => setIsInfoModalOpen(false)}
      />
    </div>
  );
};

export default CreateRequestModal;