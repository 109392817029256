import React, { useState, useEffect } from 'react';
import './NewsSection.css';

// Import images with relative paths
import news1 from '../../newsimg/001.jpeg';
import news2 from '../../newsimg/002.jpeg';
import news3 from '../../newsimg/003.jpeg';

const NewsSection = () => {
  const newsData = [
        {
            title: "Öğrencimiz Emirhan Günek’ten Uluslararası Akademik Başarı",
            date: "06-12-2024 11:37:00",
            link: "https://tip.ksbu.edu.tr/defaultx/slide/9712/ogrencimiz-emirhan-gunekten-uluslararasi-akademik-basari"
        },
        { 
            title: "TÜSEB 2025 aktif çağrı listesi yayınlandı. Lisans ve akademisyenlere yöneleri ilk açılan çağrılar için başvurular başladı",
            date: "05-12-2024 12:51:00",
            link: "https://tbys.tuseb.gov.tr/#/aktifcagrilistesidispanel"
        },
        {
            title: "KSBÜ'DEN CERRAHİ ALANDA YENİLİKÇİ ATILIM",
            date: "02-08-2024 15:33:00",
            link: "https://www.ksbu.edu.tr/defaultx/slide/9391/ksbuden-cerrahi-alanda-yenilikci-atilim"
        },
        {
            title: "AKADEMİSYENİMİZE TÜSEB'DEN DESTEK",
            date: "03-05-2024 14:57:00", 
            link: "https://www.ksbu.edu.tr/defaultx/slide/9231/akademisyenimize-tusebden-destek"
        },
        {
            title: "KSBÜ ARAŞTIRMA EKİBİNE TÜSEB'DEN DESTEK",
            date: "16-04-2024 15:46:00",
            link: "https://www.ksbu.edu.tr/defaultx/slide/9204/ksbu-arastirma-ekibine-tusebden-destek"
        },
        {
            title: "AKADEMİSYENİMİZE TÜBİTAK'TAN DESTEK",
            date: "02-04-2024 11:14:00",
            link: "https://ksbu.edu.tr/defaultx/slide/9182/akademisyenimize-tubitaktan-destek"
        },
        {
            title: "KSBÜ AKADEMİSYENLERİNDEN BAĞIRSAK-BEYİN EKSENİ HASTALIKLARININ TEDAVİSİ İÇİN ÖNEMLİ ÇALIŞMA",
            date: "12-10-2023 13:00:00",
            link: "https://www.ksbu.edu.tr/defaultx/Contents/8455"
        },
        {
            title: "PROJE DESTEĞİ",
            date: "11-08-2023 09:10:00",
            link: "https://www.ksbu.edu.tr/defaultx/slide/8810/proje-destegi"
        },
        {
            title: "KSBÜ AKADEMİSYENLERİNDEN 'KİREÇLENME' TEDAVİSİ İÇİN ÖNEMLİ ÇALIŞMA",
            date: "05-09-2022 14:17:00",
            link: "https://www.ksbu.edu.tr/defaultx/slide/8437/ksbu-akademisyenlerinden-kireclenme-tedavisi-icin-onemli-calisma"
        },
        {
            title: "KSBÜ AKADEMİSYENLERİNİN BİLİMSEL PROJESİ TÜSEB TARAFINDAN DESTEKLENECEK",
            date: "31-08-2022 16:47:00",
            link: "https://www.ksbu.edu.tr/defaultx/Contents/7732"
        },
        {
            title: "TÜBİTAK 2209-A ÜNİVERSİTE ÖĞRENCİLERİ ARAŞTIRMA PROJELERİ DESTEKLEME PROGRAMI 2023/1. DÖNEM ÇAĞRI SONUCUNA İLİŞKİN, DESTEK HAKKI KAZANANLAR",
            date: "",
            link: "https://ksbu.edu.tr/defaultx/duyuru/8475/tubitak-2209-a-universite-ogrencileri-arastirma-projeleri-destekleme-programi-20231-donem-cagri-sonucuna-iliskin-destek-hakki-kazananlar"
        }
  ];

  const [currentNewsIndex, setCurrentNewsIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentNewsIndex((prevIndex) => (prevIndex + 1) % newsData.length);
    }, 3000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div className="news-container">
      {/* Üst kısımdaki sabit haberler */}
      <div className="news-grid">
        <div className="news-item">
          <div className="news-image">
            <img src={news1} alt="KUYAM Laboratuvar" />
          </div>
          <div className="news-content">
            <h3 className="news-title">KUYAM'dan Yeni Laboratuvar Hizmetleri</h3>
            <p className="news-date">DEVAMINI OKU</p>
          </div>
        </div>
        
        <div className="news-item">
          <div className="news-image">
            <img src={news2} alt="KUYAM Eğitim" />
          </div>
          <div className="news-content">
            <h3 className="news-title">KUYAM Personeline Özel Eğitim Semineri</h3>
            <p className="news-date">DEVAMINI OKU</p>
          </div>
        </div>
        
        <div className="news-item">
          <div className="news-image">
            <img src={news3} alt="KUYAM Test" />
          </div>
          <div className="news-content">
            <h3 className="news-title">KUYAM Test ve Analiz Hizmetleri Genişletildi</h3>
            <p className="news-date">DEVAMINI OKU</p>
          </div>
        </div>
      </div>

      {/* Kayan haberler bölümü */}
      <div className="news-scroll">
        <div className="news-scroll-container">
          {newsData.map((news, index) => (
            <div
              key={index}
              className={`scroll-news-item ${index === currentNewsIndex ? 'active' : ''}`}
            >
              <a 
                href={news.link}
                target="_blank"
                rel="noopener noreferrer"
                className="news-title"
              >
                {news.title}
              </a>
              <p className="news-date">{news.date}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default NewsSection;