import React, { useState } from 'react';
import { Search, X } from 'lucide-react';
import './SearchAnalysisModal.css';

const SearchAnalysisModal = ({ isOpen, onClose }) => {
  const [searchTerm, setSearchTerm] = useState('');
  
  const analyses = [
    {
      lab: "Biyo-inovatif Teknoloji Laboratuvarı",
      items: [
        "3 Boyutlu Modelleme",
        "3 Boyutlu Baskılama",
        "Katı model baskılama",
        "Biyobaskılama",
        "Biyomalzeme modellemeleri"
      ]
    },
    {
      lab: "Biyokimya Laboratuvarı",
      items: [
        "ELISA-Kit uygulamaları",
        "Kan, idrar ve doku örneklerinden numune hazırlama",
        "Rutin ve İleri Biyokimyasal testlerin yapılması",
        "İlaç-doz uygulamaları",
        "Toksisite analizi",
        "Biyobelirteç araştırmaları",
        "İn vitro ve in vivo uygulamalar",
        "Deney hayvan model oluşturma",
        "DNA Fragmantasyon",
        "Ultrasantrifüj ayırma yöntemi"
      ]
    },
    {
      lab: "Kök Hücre ve Rejeneratif Tıp Laboratuvarı",
      items: [
        "Akış Sitometrisi",
        "Kanser kök hücre çalışmaları",
        "Sitotoksisite analizleri",
        "Mikroorganizmaların moleküler taksonomisi",
        "Fungusların filogenetik analizleri",
        "Fungusların morfolojik tanılanması",
        "Mikrobiyal biyoüretim yöntemleri",
        "Mikrobiyolojik testler",
        "Antimikrobiyal etki analizleri",
        "Mikrobiyal proses teknolojileri ve optimizasyonu",
        "Işık mikroskobu ve stereo mikroskop uygulamaları"
      ]
    },
    {
      lab: "Hücresel ve Moleküler Tıp Laboratuvarı",
      items: [
        "Kanser çalışmaları",
        "Hücre kültürü uygulamaları",
        "Primer hücre kültürü uygulamaları",
        "Konvansiyonel PCR",
        "iCELLigence hücre kültürü sistemi",
        "Microarray",
        "qPCR gen ekspresyon analizi",
        "Western blotting-Protein ifade analizi",
        "dPCR analizi",
        "DNA saflığı ve miktarının ölçülmesi",
        "Işık mikroskobu ve stereo mikroskop uygulamaları",
        "Floresans mikroskop ile görüntüleme",
        "Biyoinformatik araçlar kullanılarak DNA dizi veri analizleri"
      ]
    }
  ];
  
  const filteredAnalyses = analyses.map(lab => ({
    lab: lab.lab,
    items: lab.items.filter(item => 
      item.toLowerCase().includes(searchTerm.toLowerCase())
    )
  })).filter(lab => lab.items.length > 0);

  if (!isOpen) return null;

  return (
    <div className="analysis-modal-overlay">
      <div className="analysis-modal-container">
        <div className="analysis-modal-header">
          <h2 className="analysis-modal-title">Analiz Ara</h2>
          <button 
            className="analysis-modal-close"
            onClick={onClose}
          >
            <X size={24} />
          </button>
        </div>

        <div className="analysis-search-container">
          <Search className="analysis-search-icon" size={20} />
          <input
            type="text"
            placeholder="Analiz adı yazın..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="analysis-search-input"
            autoFocus
          />
        </div>

        <div className="analysis-results">
          {filteredAnalyses.map((lab, index) => (
            <div key={index} className="analysis-lab-group">
              <h3 className="analysis-lab-title">{lab.lab}</h3>
              <ul className="analysis-items-list">
                {lab.items.map((item, itemIndex) => (
                  <li key={itemIndex} className="analysis-item">
                    {item}
                  </li>
                ))}
              </ul>
            </div>
          ))}
          {filteredAnalyses.length === 0 && (
            <div className="analysis-no-results">
              Arama sonucu bulunamadı.
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchAnalysisModal;