import React from 'react';
import './styles.css';

const Tab4 = ({ formData, setFormData }) => {
  const handleMethodChange = (index, field, value) => {
    setFormData(prev => {
      const newMethods = [...prev.testMethods];
      newMethods[index] = { ...newMethods[index], [field]: value };
      return { ...prev, testMethods: newMethods };
    });
  };
  
  const toggleMethod = (index) => {
    setFormData(prev => {
      const newMethods = [...prev.testMethods];
      newMethods[index] = { 
        ...newMethods[index], 
        isActive: !newMethods[index].isActive,
        ...(newMethods[index].isActive ? { method: '', duration: '' } : {})
      };
      return { ...prev, testMethods: newMethods };
    });
  };

  const handleCheckboxChange = (section, field) => {
    setFormData(prev => ({
      ...prev,
      [section]: {
        ...prev[section],
        [field]: !prev[section][field]
      }
    }));
  };

  const handleParameterChange = (rowIndex, colIndex, value) => {
    setFormData(prev => {
      const newParams = [...prev.testParameters];
      newParams[rowIndex][colIndex] = value;
      return { ...prev, testParameters: newParams };
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  return (
    <div className="tab4-container tab-container">
      <h2>Deney Bilgileri</h2>
      {formData.testMethods.map((item, index) => (
        <div key={index} style={{ marginBottom: '10px' }}>
          <label>
            <input
              type="checkbox"
              checked={item.isActive}
              onChange={() => toggleMethod(index)}
            />
            Deney Metodu {index+1}
          </label>
          <input type="text" placeholder="Metod Adı" value={item.method} onChange={(e) => handleMethodChange(index, 'method', e.target.value)} disabled={!item.isActive} className="renkli-input" />
          <input type="text" placeholder="Süre" value={item.duration} onChange={(e) => handleMethodChange(index, 'duration', e.target.value)} disabled={!item.isActive} className="renkli-input" />
        </div>
      ))}

      <h3>Deney Cihazları</h3>
      {[
        { key: 'modeling3D', label: '3D Modelleme' },
        { key: 'printing3D', label: '3D Baskılama' },
        { key: 'solidModelPrinting', label: 'Katı model Baskılama' },
        { key: 'bioprinting', label: 'Biyobaskılama' },
        { key: 'biomaterialMod', label: 'Biyomalzeme mod.' }
      ].map(({ key, label }) => (
        <label key={key} style={{ marginRight: '10px' }}>
          <input
            type="checkbox"
            checked={formData.testDevices[key]}
            onChange={() => handleCheckboxChange('testDevices', key)}
          />
          {label}
        </label>
      ))}

      <h3>Ek Hizmetler</h3>
      <label>
        <input
          type="checkbox"
          checked={formData.additionalServices.testProtocol}
          onChange={() => handleCheckboxChange('additionalServices', 'testProtocol')}
        />
        Deney Protokolü
      </label>
      <label>
        <input
          type="checkbox"
          checked={formData.additionalServices.consultingService}
          onChange={() => handleCheckboxChange('additionalServices', 'consultingService')}
        />
        Danışmanlık Hizmeti
      </label>
      <label>
        Diğer:
        <input
          type="text"
          name="additionalServices.other"
          value={formData.additionalServices.other}
          onChange={handleInputChange}
          className="renkli-input"
        />
      </label>

      <h3>Deney Parametreleri</h3>
      <table>
        <tbody>
          {formData.testParameters.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {row.map((cell, colIndex) => (
                <td key={colIndex}>
                  <input type="text" value={cell} onChange={(e) => handleParameterChange(rowIndex, colIndex, e.target.value)} className="renkli-input"/>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>

      <label>Ek İstekler:</label>
      <textarea name="additionalInfo" value={formData.additionalInfo} onChange={handleInputChange} className="renkli-textarea" />
    </div>
  );
};

export default Tab4;
