// src/pages/KVKK/KVKK.js
import React from 'react';
import './KVKK.css';

const KVKK = () => {
    return (
        <div className="kvkk-container">
            <div className="kvkk-header">
                <h1>Kişisel Verilerin Korunması ve İşlenmesi Hakkında Aydınlatma Metni</h1>
            </div>

            <div className="kvkk-content">
                <div className="kvkk-intro">
                    <p>
                        Kütahya Sağlık Bilimleri Üniversitesi olarak üniversite ile ilişkili tüm şahıslara ait 
                        her türlü kişisel verilerin hukuka uygun olarak toplanması, saklanması, işlenmesi, 
                        gerekmesi durumunda paylaşılması sırasında gizliliğinizi korumak amacıyla mümkün olan 
                        en üst seviyede güvenlik tedbirlerini almaktayız.
                    </p>
                    <p>
                        Üniversitemizin amacı; kişisel verilerinizin toplanma şekilleri, işlenme amaçları, 
                        işlemenin hukuki nedenleri ve haklarınız konularında sizleri en şeffaf şekilde 
                        bilgilendirmektir.
                    </p>
                </div>

                <section className="kvkk-section">
                    <h2>a) Veri Sorumlusu</h2>
                    <p>
                        6698 sayılı Kişisel Verilerin Korunması Kanunu ("6698 sayılı Kanun") uyarınca, 
                        kişisel verileriniz; veri sorumlusu olarak Kütahya Sağlık Bilimleri Üniversitesi 
                        ("KSBÜ") tarafından aşağıda açıklanan kapsamda toplanacak ve işlenecektir.
                    </p>
                </section>

                <section className="kvkk-section">
                    <h2>b) Kişisel Verilerin Hangi Amaçla İşleneceği</h2>
                    <p>Toplanan kişisel verileriniz;</p>
                    <ul className="kvkk-list">
                        <li>Üniversitemiz ve/veya üniversitemize bağlı merkez ve birimlerin hizmetlerinden faydalanmanız için gerekli çalışmaların ilgili birimlerce yapılması,</li>
                        <li>Üniversitemiz ve/veya üniversitemize bağlı merkez ve birimlerinin ve faaliyetlerinin tanıtılması ve sunulması,</li>
                        <li>2547 sayılı Yükseköğretim Kanunu ve ilgili mevzuat kapsamındaki yükümlülüklerin karşılanması,</li>
                        <li>Eğitim-öğretim, bilimsel araştırma, yayın ve danışmanlık faaliyetlerinin sürdürülmesi,</li>
                        <li>Kimlik kartı üretimi, basımı ile çeşitli akademik ve idari işlemlerin yapılması,</li>
                        <li>İş sağlığı ve güvenliği çerçevesinde yükümlülüklerin yerine getirilmesi,</li>
                        <li>Öğrenciler ile akademik ve idari personel hakkında açılan/açılabilecek disiplin soruşturması süreçlerinin yönetilebilmesi,</li>
                        <li>Yargı organlarının ve/veya idari makamların taleplerine cevap verilmesi,</li>
                        <li>Üniversitemizin ticari ve iş stratejilerinin belirlenmesi ve uygulanması,</li>
                        <li>İnsan Kaynakları politikalarının yürütülmesi</li>
                    </ul>
                    <p>
                        amaçlarıyla 6698 sayılı Kanun'un 5. ve 6. maddelerinde belirtilen kişisel veri işleme 
                        şartları ve amaçları dâhilinde işlenecektir.
                    </p>
                </section>

                <section className="kvkk-section">
                    <h2>c) İşlenen Kişisel Verilerin Kimlere ve Hangi Amaçla Aktarılabileceği</h2>
                    <p>
                        Toplanan kişisel verileriniz; üniversitemizin eğitim politikaları ve süreçlerinin 
                        planlanması ve yürütülmesi amacıyla aşağıdaki kurum ve kuruluşlara aktarılabilecektir:
                    </p>
                    <ul className="kvkk-list">
                        <li>Yükseköğretim Kurumu, SGK, bakanlıklar ve yargı mercileri gibi yetkili kamu kurumlarına,</li>
                        <li>Üniversitemize bağlı merkez ve birimlere,</li>
                        <li>İş ortaklarımıza, tedarikçilerimize,</li>
                        <li>Mevzuatın izin verdiği hallerde işbirliği yaptığımız danışmanlık firmaları ve kuruluşlara</li>
                    </ul>
                </section>

                <section className="kvkk-section">
                    <h2>ç) Kişisel Veri Toplamanın Yöntemi ve Hukuki Sebebi</h2>
                    <p>
                        Kişisel verileriniz, otomatik ya da otomatik olmayan yöntemlerle, üniversitemiz 
                        birimleri, internet sitesi, sosyal medya mecraları, mobil uygulamalar ve benzeri 
                        vasıtalarla sözlü, yazılı ya da elektronik olarak toplanabilmektedir.
                    </p>
                </section>

                <section className="kvkk-section">
                    <h2>d) Kişisel Veri Sahibinin 6698 sayılı Kanun'un 11. Maddesinde Sayılan Hakları</h2>
                    <p>Kişisel veri sahipleri olarak aşağıdaki haklara sahipsiniz:</p>
                    <ul className="kvkk-list">
                        <li>Kişisel veri işlenip işlenmediğini öğrenme,</li>
                        <li>Kişisel verileri işlenmişse buna ilişkin bilgi talep etme,</li>
                        <li>Kişisel verilerin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme,</li>
                        <li>Yurt içinde veya yurt dışında kişisel verilerin aktarıldığı üçüncü kişileri bilme,</li>
                        <li>Kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini isteme,</li>
                        <li>Kanun hükümlerine uygun olarak işlenmiş olmasına rağmen, işlenmesini gerektiren sebeplerin ortadan kalkması hâlinde kişisel verilerin silinmesini veya yok edilmesini isteme,</li>
                        <li>İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle kişinin kendisi aleyhine bir sonucun ortaya çıkmasına itiraz etme,</li>
                        <li>Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara uğraması hâlinde zararın giderilmesini talep etme.</li>
                    </ul>
                </section>

                <div className="kvkk-contact">
                    <h3>Başvuru ve İletişim</h3>
                    <p>
                        Yukarıda belirtilen haklarınızı kullanmak için başvurularınızı aşağıdaki yollarla 
                        iletebilirsiniz:
                    </p>
                    <ul className="kvkk-list">
                        <li>
                            Evliya Çelebi Yerleşkesi Tavşanlı Yolu 10.km Kütahya / Türkiye adresine yazılı olarak,
                        </li>
                        <li>
                            kuyam@ksbu.edu.tr adresine elektronik posta yoluyla.
                        </li>
                    </ul>
                    <p>
                        Başvurularınız, talebin niteliğine göre en kısa sürede ve en geç 30 gün içinde ücretsiz 
                        olarak sonuçlandırılacaktır.
                    </p>
                </div>
            </div>

            <div className="kvkk-footer">
                <p>Bu metin en son 01 Ağustos 2024 tarihinde güncellenmiştir.</p>
            </div>
        </div>
    );
};

export default KVKK;