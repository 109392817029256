import React from 'react';
import './MolekulerLab.css';

function MolekulerLab() {
    return (
        <div className="laboratory-container">
            <div className="laboratory-content">
                <h1 className="lab-title">Hücresel ve Moleküler Tıp Laboratuvarı</h1>
                
                <div className="lab-description">
                    <p>
                        <em>İn vivo</em> ve <em>in vitro</em> çeşitli deneysel hastalık modellerinde ve 
                        insan hastalıklarında hücre ölüm, hasar ve onarım mekanizmaları moleküler düzeyde 
                        araştırılmaktadır. Bu laboratuvarda apoptoz, nekroz, otofaji, hücre çoğalması, 
                        doku rejenerasyonu, fibrozis, çeşitli büyüme faktörleri ve sitokinler ile ilişkili 
                        sinyal yolakları ve bazı moleküllerin antikanser aktiviteleri üzerinde hücre kültürü 
                        ve moleküler çalışmalar yapılmaktadır.
                    </p>
                    
                    <p>
                        Ayrıca yeni mikrobiyal metabolitlerin [birincil (amino asitler vb.) ve ikincil 
                        metabolitler (antibiyotikler, antikanser ilaçları, immün baskılayıcılar, biyoinsektisidler 
                        vb.) ve biyopolimerlerin (doku mühendisliği ve ilaç dağılımı için kullanılan)] 
                        biyosentezi ve bilinen yararlı biyoaktif metabolitlerin biyosentezlerinin ve sağlık 
                        alanındaki kullanım alanlarının arttırılması üzerine çalışmalar yapmaktadır.
                    </p>
                </div>

                <div className="lab-contact">
                    <h2>Sorumlu Akademisyen</h2>
                    <p>Doç. Dr. Derya BERİKTEN</p>
                    
                    <div className="last-update">
                        <p>Son Güncelleme Tarihi: 01 Ağustos 2024, Perşembe</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MolekulerLab;