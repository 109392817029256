// AnalysisProcess.js
import React from 'react';
import './AnalysisProcess.css';
import processImage from './is_akis_semasi.png';

function AnalysisProcess() {
 return (
   <div className="analysis-container">
     <div className="process-content">
       <div className="image-wrapper">
         <img 
           src={processImage} 
           alt="İş Akış Şeması" 
           className="process-image"
           onLoad={(e) => {
             e.target.classList.add('loaded');
           }}
         />
       </div>
     </div>
   </div>
 );
}

export default AnalysisProcess;