import React from 'react';
import './styles.css';

const Tab1 = ({ formData, setFormData }) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  return (
    <div className="tab1-container tab-container">
      <h2>Müşteri Bilgileri</h2>
      <label>Ad Soyad:</label>
      <input type="text" name="name" value={formData.name} onChange={handleChange} className="renkli-input" />

      <label>Kurum/Üniversite-Bölüm:</label>
      <input type="text" name="institution" value={formData.institution} onChange={handleChange} className="renkli-input" />

      <label>Tel:</label>
      <input type="text" name="phone" value={formData.phone} onChange={handleChange} className="renkli-input" />

      <label>e-Posta:</label>
      <input type="email" name="email" value={formData.email} onChange={handleChange} className="renkli-input" />

      <label>Adres:</label>
      <textarea name="address" value={formData.address} onChange={handleChange} className="renkli-textarea" />
    </div>
  );
};

export default Tab1;
