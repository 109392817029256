import React from 'react';
import './styles.css';

const Tab3 = ({ formData, setFormData }) => {
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleCheckboxChange = (section, field) => {
    setFormData(prev => ({
      ...prev,
      [section]: {
        ...prev[section],
        [field]: !prev[section][field]
      }
    }));
  };

  const handleSampleChange = (index, field, value) => {
    setFormData(prev => ({
      ...prev,
      samples: prev.samples.map((sample, i) =>
        i === index ? { ...sample, [field]: value } : sample
      )
    }));
  };

  return (
    <div className="tab3-container tab-container">
      <h2>Numune Bilgileri</h2>

      <label>Numune Sayısı:</label>
      <input type="text" name="sampleCount" value={formData.sampleCount} onChange={handleInputChange} className="renkli-input" />

      <label>Numune Teslim Şekli:</label>
      <label>
        <input
          type="checkbox"
          checked={formData.deliveryMethod.elden}
          onChange={() => handleCheckboxChange('deliveryMethod', 'elden')}
        />
        Elden
      </label>
      <label>
        <input
          type="checkbox"
          checked={formData.deliveryMethod.kargo}
          onChange={() => handleCheckboxChange('deliveryMethod', 'kargo')}
        />
        Kargo
      </label>

      <label>Numune Tehlikeli Mi?</label>
      <label>
        <input
          type="checkbox"
          checked={formData.harmfulEffect.var}
          onChange={() => handleCheckboxChange('harmfulEffect', 'var')}
        />
        Var
      </label>
      <label>
        <input
          type="checkbox"
          checked={formData.harmfulEffect.yok}
          onChange={() => handleCheckboxChange('harmfulEffect', 'yok')}
        />
        Yok
      </label>

      <table>
        <thead>
          <tr>
            <th>Etiket No</th>
            <th>Numune Adı</th>
            <th>Numune İçeriği</th>
            <th>Özellikler</th>
          </tr>
        </thead>
        <tbody>
          {formData.samples.map((sample, index) => (
            <tr key={index}>
              <td>{sample.id}</td>
              <td><input type="text" value={sample.name} onChange={(e) => handleSampleChange(index, 'name', e.target.value)} className="renkli-input" /></td>
              <td><input type="text" value={sample.content} onChange={(e) => handleSampleChange(index, 'content', e.target.value)} className="renkli-input" /></td>
              <td><input type="text" value={sample.properties} onChange={(e) => handleSampleChange(index, 'properties', e.target.value)} className="renkli-input" /></td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Tab3;
