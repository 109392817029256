import React, { useState } from 'react';
import './AllAnnouncements.css';
import { Search } from 'lucide-react';

const AllAnnouncements = () => {
    const [selectedCategory, setSelectedCategory] = useState('all');
    const [searchQuery, setSearchQuery] = useState('');

    const allItems = [
        // Sertifikalı Kurslar
        {
            type: 'course',
            date: '22.02.2023',
            title: 'Uygulamalı Flow Sitometri Kursu'
        },
        {
            type: 'course',
            date: '22.02.2023',
            title: 'Ultrasantrifüj Eğitimi'
        },
        {
            type: 'course',
            date: '17.10.2021',
            title: 'Tübitak 2237/B Sağlık ve Biyoteknoloji Alanında Araştırma Projeleri Hazırlama'
        },
        {
            type: 'course',
            date: '27.01.2021',
            title: 'Uygulamalı Western Blot Kursu'
        },
        // Öğrenci Ziyaretleri
        {
            type: 'student',
            date: '20.05.2019',
            title: 'İstek İlkokulları Öğrenci Grubu'
        },
        {
            type: 'student',
            date: '20.05.2019',
            title: 'DPU Biyokimya Bölümü Öğrenci Grubu'
        },
        {
            type: 'student',
            date: '20.05.2019',
            title: 'Tavşanlı Bilsem Öğrenci Grubu'
        },
        // Kurum Ziyaretleri
        {
            type: 'institution',
            date: '16.09.2021',
            title: 'Bilecik Şeyh Edebali Üniversitesi Merkezi Araştırma Laboratuvarı'
        },
        {
            type: 'institution',
            title: 'Bezmialem',
            date: '16.09.2021'
        },
        {
            type: 'institution',
            title: 'KUTTAM',
            date: '16.09.2021'
        },
        {
            type: 'institution',
            title: 'Arum',
            date: '16.09.2021'
        },
        {
            type: 'institution',
            title: 'Anadolu Üniversitesi TTO',
            date: '16.09.2021'
        }
    ];

    // Filtreleme fonksiyonu
    const filteredItems = allItems.filter(item => {
        const matchesCategory = selectedCategory === 'all' || item.type === selectedCategory;
        const matchesSearch = item.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
                            (item.date && item.date.includes(searchQuery));
        return matchesCategory && matchesSearch;
    });

    // Kategori sayılarını hesapla
    const getCategoryCount = (category) => {
        return category === 'all' 
            ? allItems.length 
            : allItems.filter(item => item.type === category).length;
    };

    return (
        <div className="all-announcements-page">
            <h1 className="page-title">Tüm Duyurular ve Etkinlikler</h1>
            
            <div className="filters-section">
                <div className="search-container">
                    <Search className="search-icon" />
                    <input
                        type="text"
                        placeholder="Ara..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className="search-input"
                    />
                </div>

                <div className="category-filters">
                    <button 
                        className={`category-button ${selectedCategory === 'all' ? 'active' : ''}`}
                        onClick={() => setSelectedCategory('all')}
                    >
                        Tümü ({getCategoryCount('all')})
                    </button>
                    <button 
                        className={`category-button ${selectedCategory === 'course' ? 'active' : ''}`}
                        onClick={() => setSelectedCategory('course')}
                    >
                        Sertifikalı Kurslar ({getCategoryCount('course')})
                    </button>
                    <button 
                        className={`category-button ${selectedCategory === 'student' ? 'active' : ''}`}
                        onClick={() => setSelectedCategory('student')}
                    >
                        Öğrenci Ziyaretleri ({getCategoryCount('student')})
                    </button>
                    <button 
                        className={`category-button ${selectedCategory === 'institution' ? 'active' : ''}`}
                        onClick={() => setSelectedCategory('institution')}
                    >
                        Kurum Ziyaretleri ({getCategoryCount('institution')})
                    </button>
                </div>
            </div>

            <div className="items-grid">
                {filteredItems.map((item, index) => (
                    <div key={index} className="announcement-card">
                        <span className="card-category">
                            {item.type === 'course' && '🎓 Sertifikalı Kurs'}
                            {item.type === 'student' && '👥 Öğrenci Ziyareti'}
                            {item.type === 'institution' && '🏛️ Kurum Ziyareti'}
                        </span>
                        <h3 className="card-title">{item.title}</h3>
                        {item.date && <span className="card-date">{item.date}</span>}
                    </div>
                ))}

                {filteredItems.length === 0 && (
                    <div className="no-results">
                        <p>Arama kriterlerinize uygun sonuç bulunamadı.</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default AllAnnouncements;