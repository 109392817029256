import React, { useState } from 'react';
import { Search } from 'lucide-react';
import './EgitimlerSeminerler.css';

const EgitimlerSeminerler = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('all');

  const activities = [
    // Eğitimler
    {
      type: 'egitim',
      date: '2024-01-10',
      title: 'Elektroporatör Temel Cihaz Kullanımı',
      displayDate: '10.01.2024'
    },
    {
      type: 'egitim',
      date: '2023-11-08',
      title: 'Xcelligence Temel Cihaz Kullanımı',
      displayDate: '08.11.2023'
    },
    {
      type: 'egitim',
      date: '2023-11-01',
      title: 'pH metre Temel Cihaz Kullanımı',
      displayDate: '01.11.2023'
    },
    {
      type: 'egitim',
      date: '2023-10-04',
      title: 'Vizkozimetre Cihaz Bilgilendirme',
      displayDate: '04.10.2023'
    },
    {
      type: 'egitim',
      date: '2023-09-21',
      title: 'Elisa Reader ve Elisa Yıkayıcı Temel Cihaz Kullanımı',
      displayDate: '21.09.2023'
    },
    {
      type: 'egitim',
      date: '2023-08-08',
      title: 'Ultra saf su cihazı Temel Cihaz Kullanımı',
      displayDate: '08.08.2023'
    },
    {
      type: 'egitim',
      date: '2023-02-22',
      title: 'Soğutmalı Santrifüj Cihaz Bilgilendirme',
      displayDate: '22.02.2023'
    },
    {
      type: 'egitim',
      date: '2022-01-12',
      title: 'İnverted- Sterio Mikroskobu Temel Cihaz Kullanımı',
      displayDate: '12.01.2022'
    },
    {
      type: 'egitim',
      date: '2022-05-10',
      title: 'Hücre Sayım Cihazı Kullanımı Eğitimi',
      displayDate: '10.05.2022'
    },
    {
      type: 'egitim',
      date: '2021-09-22',
      title: 'Yatay Dikey Elektroforez / RNA-DNA-Protein Görüntüleme',
      displayDate: '22.09.2021'
    },
    {
      type: 'egitim',
      date: '2021-05-25',
      title: 'Rejeneratif Tıp ve Plastik Rekonstrüktif Cerrahide Kişiye Özel Çözümler',
      displayDate: '25.05.2021'
    },
    {
      type: 'egitim',
      date: '2021-05-21',
      title: 'Yurtdışı Değişim ve Araştırma Projeleri',
      displayDate: '21.05.2021'
    },

    // Toplantılar
    {
      type: 'toplanti',
      date: '2024-08-13',
      title: 'Prof.Istvan Borzsak Eötvös Lorand Üniversitesi',
      displayDate: '13.08.2024'
    },
    {
      type: 'toplanti',
      date: '2022-06-10',
      title: 'Adım Üniversiteleri',
      displayDate: '10.06.2022'
    },
    {
      type: 'toplanti',
      date: '2022-06-02',
      title: 'Prof.Istvan Borzsak Eötvös Lorand Üniversitesi',
      displayDate: '02.06.2022'
    },
    {
      type: 'toplanti',
      date: '2022-05-22',
      title: 'KSBU Ağız ve Diş Sağlığı Merkezi Bilgilendirme Toplantısı',
      displayDate: '22.05.2022'
    },
    {
      type: 'toplanti',
      date: '2021-09-09',
      title: 'KSBU Tıp Fakültesi Ortopedi ve Travmatoloji Anabilim Dalı',
      displayDate: '09.09.2021'
    },
    {
      type: 'toplanti',
      date: '2021-04-10',
      title: 'Kütahya Ticaret ve Sanayi Odası (KUTSO)',
      displayDate: '10.04.2021'
    },
    {
      type: 'toplanti',
      date: '2021-04-03',
      title: 'KSBU Tıp Fakültesi Göğüs Hastalıkları, Tıbbi Genetik, Dahiliye, Histoloji ve Embriyoloji, Enfeksiyon Hastalıkları ve Tıbbi Mikrobiyoloji Anabilim Dalları',
      displayDate: '03.04.2021'
    },
    {
      type: 'toplanti',
      date: '2021-04-01',
      title: 'KSBU Tıp Fakültesi Radyasyon Onkolojisi, Medikal Onkoloji ve Adli Tıp Anabilim Dalları',
      displayDate: '01.04.2021'
    },
    {
      type: 'toplanti',
      date: '2021-02-18',
      title: 'ARBİS Genel Bilgilendirme Toplantısı',
      displayDate: '18.02.2021'
    },
    {
      type: 'toplanti',
      date: '2021-02-09',
      title: 'Bursa Teknik Üniversitesi TTO',
      displayDate: '09.02.2021'
    },
    {
      type: 'toplanti',
      date: '2019-05-20',
      title: 'Çankaya Üniversitesi Metalurji Malzeme Mühendisliği Öğretim Üyeleri',
      displayDate: '20.05.2019'
    },
    {
      type: 'toplanti',
      date: '2021-02-24',
      title: 'BİGG TEAM Teknogirişim Programı Tanıtım Toplantısı',
      displayDate: '24.02.2021'
    },
    {
      type: 'toplanti',
      date: '2020-12-23',
      title: 'TÜBİTAK Projeleri Hakkında Bilgilendirme Toplantısı',
      displayDate: '23.12.2020'
    },
    {
      type: 'toplanti',
      date: '2020-05-14',
      title: 'Akademik Bilgilendirme WEBINAR Programı',
      displayDate: '14.05.2020'
    },
    {
      type: 'toplanti',
      date: '2019-12-13',
      title: 'Endoskopik Transfenodial Cerrahi Bölge Kursu',
      displayDate: '13.12.2019'
    },
    {
      type: 'toplanti',
      date: '2019-11-19',
      title: 'ZEISS on Your Campus Workshop Serisi',
      displayDate: '19.11.2019'
    },
    {
      type: 'toplanti',
      date: '2019-11-07',
      title: 'Sağlıkta Bor Uygulamaları Çalıştayı',
      displayDate: '07.11.2019'
    },
    {
      type: 'toplanti',
      date: '2019-10-17',
      title: 'Patent eğitim Semineri',
      displayDate: '17.10.2019'
    }
  ];

  // Tarihe göre sıralama
  const sortedActivities = [...activities].sort((a, b) => new Date(b.date) - new Date(a.date));

  // Filtreleme
  const filteredActivities = sortedActivities.filter(activity => {
    const matchesSearch = activity.title.toLowerCase().includes(searchQuery.toLowerCase());
    const matchesCategory = selectedCategory === 'all' || activity.type === selectedCategory;
    return matchesSearch && matchesCategory;
  });

  // Aktivite sayılarını hesapla
  const totalCount = filteredActivities.length;
  const egitimCount = filteredActivities.filter(a => a.type === 'egitim').length;
  const toplantiCount = filteredActivities.filter(a => a.type === 'toplanti').length;

  return (
    <div className="container">
      <h1 className="page-title">Eğitimler ve Seminerler</h1>

      <div className="filters">
        <div className="search-container">
          <Search className="search-icon" />
          <input
            type="text"
            placeholder="Eğitim veya toplantı ara..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="search-input"
          />
        </div>

        <div className="category-filter">
          <select
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
            className="category-select"
          >
            <option value="all">Tümü ({totalCount})</option>
            <option value="egitim">Eğitimler ({egitimCount})</option>
            <option value="toplanti">Toplantılar ({toplantiCount})</option>
          </select>
        </div>
      </div>

      <div className="summary-stats">
        <div className="stat-item">
          <span className="stat-label">Toplam Etkinlik:</span>
          <span className="stat-value">{totalCount}</span>
        </div>
        <div className="stat-item">
          <span className="stat-label">Eğitimler:</span>
          <span className="stat-value">{egitimCount}</span>
        </div>
        <div className="stat-item">
          <span className="stat-label">Toplantılar:</span>
          <span className="stat-value">{toplantiCount}</span>
        </div>
      </div>

      <div className="activities-grid">
        {filteredActivities.map((activity, index) => (
          <div key={index} className="activity-card">
            <div className="activity-date">
              {activity.displayDate}
              <span className={`activity-type ${activity.type}`}>
                {activity.type === 'egitim' ? 'Eğitim' : 'Toplantı'}
              </span>
            </div>
            <h3 className="activity-title">{activity.title}</h3>
          </div>
        ))}
      </div>

      {filteredActivities.length === 0 && (
        <div className="no-results">
          <p>Aramanızla eşleşen etkinlik bulunamadı.</p>
        </div>
      )}
    </div>
  );
};

export default EgitimlerSeminerler;