// src/pages/KuyamBasvuru/KuyamResetPassword.js
import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

const KuyamResetPassword = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const [formData, setFormData] = useState({
    password: '',
    confirmPassword: ''
  });
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // API çağrısı
      navigate('/kuyam-basvuru/login');
    } catch (error) {
      setErrors({ submit: error.message });
    }
  };

  return (
    <div className="page-container">
      <div className="kuyam-container">
        <div className="login-box">
          <h2>Yeni Şifre Belirleme</h2>
          <form onSubmit={handleSubmit} className="auth-form">
            <div className="form-group">
              <input
                type="password"
                name="password"
                placeholder="Yeni Şifre"
                value={formData.password}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <input
                type="password"
                name="confirmPassword"
                placeholder="Yeni Şifre Tekrar"
                value={formData.confirmPassword}
                onChange={handleChange}
              />
            </div>
            <button type="submit" className="login-button">
              Şifreyi Güncelle
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default KuyamResetPassword;