// src/pages/Regulations/Regulations.js
import React from 'react';
import './Regulations.css';

const Regulations = () => {
    return (
        <div className="regulations-container">
            <div className="regulations-header">
                <h1>KÜTAHYA SAĞLIK BİLİMLERİ ÜNİVERSİTESİ</h1>
                <h2>MERKEZİ ARAŞTIRMA LABORATUVARI</h2>
                <h2>UYGULAMA VE ARAŞTIRMA MERKEZİ (KUYAM)</h2>
                <h2>YÖNETMELİĞİ</h2>
            </div>

            <div className="regulations-content">
                {/* BİRİNCİ BÖLÜM */}
                <section className="regulation-section">
                    <h3>BİRİNCİ BÖLÜM</h3>
                    <h4>Amaç, Kapsam, Dayanak ve Tanımlar</h4>
                    
                    <article>
                        <h5>Amaç</h5>
                        <p>MADDE 1- (1) Bu Yönetmeliğin amacı; Kütahya Sağlık Bilimleri Üniversitesi Uygulama ve Araştırma Merkezinin amacına, faaliyet alanlarına, yönetim organlarına, yönetim organlarının görevlerine ilişkin usul ve esasları düzenlemektir.</p>
                    </article>

                    <article>
                        <h5>Kapsam</h5>
                        <p>MADDE 2- (1) Bu Yönetmelik, Kütahya Sağlık Bilimleri Üniversitesi Uygulama ve Araştırma Merkezinin amacına, faaliyet alanlarına, yönetim organlarına, yönetim organlarının görevlerine ilişkin hükümleri kapsar.</p>
                    </article>

                    <article>
                        <h5>Dayanak</h5>
                        <p>MADDE 3- (1) Bu Yönetmelik; 4/11/1981 tarihli ve 2547 sayılı Yükseköğretim Kanununun 7 nci maddesinin birinci fıkrasının (d) bendinin (2) numaralı alt bendi ile 14 üncü maddesine dayanılarak hazırlanmıştır.</p>
                    </article>

                    <article>
                        <h5>Tanımlar</h5>
                        <p>MADDE 4- (1) Bu Yönetmelikte geçen tanımlar;</p>
                        <ol>
                            <li>Üniversite: Kütahya Sağlık Bilimleri Üniversitesini,</li>
                            <li>Merkez (KUYAM): Kütahya Sağlık Bilimleri Üniversitesi Uygulama ve Araştırma Merkezini,</li>
                            <li>Rektör: Kütahya Sağlık Bilimleri Üniversitesi Rektörünü,</li>
                            <li>Müdür: Proje tanımlama ve yönetim beceri ve deneyimine sahip, projenin bilimsel, teknik, idari, mali ve hukuki her türlü sorumluluğunu taşıyan ve Üniversite Rektörü tarafından görevlendirilmiş öğretim üyesini,</li>
                            <li>Yönetim Kurulu: Merkezin Yönetim Kurulunu,</li>
                            <li>Birim Sorumluları: Merkez bünyesinde merkezin amaç ve faaliyet alanları doğrultusunda çalışmalarda bulunmak üzere kurulan birimlerin sorumlularını ifade eder.</li>
                        </ol>
                    </article>
                </section>

                {/* İKİNCİ BÖLÜM */}
                <section className="regulation-section">
                    <h3>İKİNCİ BÖLÜM</h3>
                    <h4>Merkezin Amaçları ve Faaliyet Alanları</h4>

                    <article>
                        <h5>Merkezin Amaçları</h5>
                        <p>MADDE 5- (1) Merkezin amaçları şunlardır;</p>
                        <ol>
                            <li>Ülkemize katma değer sağlayacak yerli ve milli teknoloji geliştirmek ve uluslararası alanda rekabetçi ürün çalışmaları yapmak,</li>
                            <li>Sağlık ve Mühendislik alanlarında AR-GE ve ÜR-GE faaliyetleri yapmak ve disiplinler arası entegrasyonu sağlamak,</li>
                            <li>Multidisipliner bir araştırma ortamı oluşturmak, insan kaynakları ve varlık değerlerini etkin ve verimli kullanmak,</li>
                            <li>Temel ve Klinik Tıp Bilimleri ile Mühendislik ve Doğa Bilimleri alanlarında bilimsel araştırma projelerini hazırlamak ve yürütmek,</li>
                            <li>Laboratuvar ve teknolojik donanım kullanım eğitimleri vermek,</li>
                            <li>Yüksek lisans, Doktora ve Doktora sonrası eğitim programlarına alt yapı sağlamak,</li>
                            <li>Uluslararası düzeyde araştırmacı akademisyen, uzman ve öğrenciler için bilimsel araştırma ve çalışma ortamı sağlamak,</li>
                            <li>Bölgemizde sektörel sanayinin kurulması, geliştirilmesi ve rekabet gücünün arttırılması amacıyla inovatif araştırma ve geliştirme altyapısı kurmak ve bilimsel danışmanlık hizmeti sağlamak,</li>
                            <li>Sağlık hizmetlerinin niteliği, verimliliği ve ulaşılabilirliğini arttırmak amacıyla ürünler geliştirmek,</li>
                            <li>Ulusal ve Uluslararası düzeyde araştırma merkezleri, kurum ve kuruluşlar ile işbirliği yapmak,</li>
                            <li>Genç nesillerde araştırma ve bilim kültürünün gelişmesine katkıda bulunmaktır.</li>
                        </ol>
                    </article>

                    <article>
                        <h5>Merkezin faaliyet alanları</h5>
                        <p>MADDE 6- (1) Merkezin faaliyet alanları şunlardır:</p>
                        <ol>
                            <li>Sağlık Bilimleri Alanlarında ülkemize katma değer sağlayacak yerli ve milli teknoloji geliştirmek ve uluslararası alanda rekabetçi ürün çalışmaları yapan bilim insanları ve uzmanları bir araya getirerek ilgili kurumların ve diğer akademik araştırma merkezlerinin de katıldığı ulusal ve uluslararası araştırma ve geliştirme projeleri oluşturulmasını, yürütülmesini ve yayın yapılmasını sağlamak,</li>
                            <li>Ulusal ve uluslararası düzeyde üniversiteler, sanayi kuruluşları, araştırma merkezleri ile diğer kurum ve kuruluşlar ile işbirliği yaparak ortak projeler yürütmek,</li>
                            <li>Merkezde yürütülen araştırma projeleri ve laboratuvar faaliyetleri ile ilgili konularda lisans, yüksek lisans, doktora ve doktora sonrası araştırmacılara uygulamalı eğitim vermek,</li>
                            <li>Yenilikçi fikirleri bulunan araştırmacılara (öğrenci, akademisyen, sanayi temsilcileri) deney düzenekleri ve sarf malzemeler ile alanında uzman kişiler tarafından sunum, seminer, çalıştay vs düzenleyerek destek sağlamak,</li>
                            <li>Ulusal ve Uluslararası fon kaynakları ile bilimsel proje destekleri (TUBITAK, HORIZON 2020, ERA, TÜBA, Kalkınma Ajansları, vb) hakkında araştırmacılara danışmanlık hizmeti sağlamak,</li>
                            <li>Yönetim Kurulunun kararlaştıracağı diğer faaliyetlerde bulunmaktır.</li>
                        </ol>
                    </article>
                </section>

                {/* ÜÇÜNCÜ BÖLÜM */}
                <section className="regulation-section">
                    <h3>ÜÇÜNCÜ BÖLÜM</h3>
                    <h4>Merkezin Yönetim Organları ve Görevleri</h4>

                    <article>
                        <h5>Merkezin yönetim organları</h5>
                        <p>MADDE 7- (1) Merkezin yönetim organları şunlardır:</p>
                        <ol type="a">
                            <li>Müdür.</li>
                            <li>Yönetim Kurulu.</li>
                            <li>Birim Sorumluları.</li>
                        </ol>
                    </article>

                    <article>
                        <h5>Müdür</h5>
                        <p>MADDE 8 - (1) Müdür; konu ile ilgili çalışmalarıyla tanınan öğretim üyeleri arasından, Rektör tarafından bir yıl süre için görevlendirilir. Süresi biten Müdür yeniden görevlendirilebilir.</p>
                        <p>(2) Müdür, görevinden ayrılacağı hallerde birim sorumlularından birini vekil bırakır. Vekâlet altı aydan fazla sürerse, yeni Müdür görevlendirilir.</p>
                    </article>

                    <article>
                        <h5>Müdürün görevleri</h5>
                        <p>MADDE 9 - (1) Müdürün görevleri şunlardır:</p>
                        <ol>
                            <li>Müdür, Merkezin bütünlüğünden sorumlu olup, tüm birimler arasında koordinasyonu da üstlenir. Merkezin tüm etkinliklerinin gözetim ve denetiminin yapılmasından ve sonuçlarının alınmasından Rektöre karşı birinci derecede sorumludur.</li>
                            <li>Merkezi temsil eden Müdür, Yönetim Kuruluna başkanlık eder. Merkez çalışmalarının düzenli ve etkin bir biçimde yürütülmesi ve denetimi ile ilgili gerekli önlemleri alır. Merkez çalışmalarının gerektirdiği görevlendirmeleri yapar.</li>
                            <li>Her öğretim yılı sonunda ve istenildiğinde, Merkezin genel durumu ve işleyişi hakkındaki raporunu Yönetim Kurulunun görüşünü aldıktan sonra Rektöre sunar.</li>
                            <li>Müdür, Merkez'den T.C. Cumhurbaşkanlığı Strateji ve Bütçe Başkanlığı tarafından istenilen ilerleme ve nihai raporun hazırlanması, sunulması ve T.C. Cumhurbaşkanlığı Strateji ve Bütçe Başkanlığı ile olan yazışmaların eksiksiz olarak sürdürülmesi hususunda birinci derecede sorumludur.</li>
                        </ol>
                    </article>

                    <article>
                        <h5>Yönetim Kurulu</h5>
                        <p>MADDE 10 - (1) Yönetim Kurulu, Yönetim Kuruluna başkanlık yapan Müdür dahil yedi üyeden oluşur. Müdür dışındaki altı üye, Merkezin faaliyet alanına giren konularda Kütahya Sağlık Bilimleri Üniversitesi öğretim üyelerinden Rektör tarafından seçilir ve bir yıl süre için görevlendirilir.</p>
                        <p>(2) Süresi dolan üyeler yeniden görevlendirilebilir. Süresi bitmeden ayrılanların veya altı aydan fazla Üniversite dışında görevlendirilenlerin yerine, kalan süreyi tamamlamak üzere aynı usulle yeni üyeler görevlendirilir.</p>
                        <p>(3) Yönetim Kurulu, Müdür tarafından belirlenen ve üyelere önceden bildirilen gündem doğrultusunda, Müdürün çağrısı ve üye tam sayısının salt çoğunluğu ile her ay olağan olarak toplanır. Gerekli görüldüğü hallerde Müdürün ya da Yönetim Kurulu üyelerinin salt çoğunluğunun çağrısı üzerine olağanüstü toplanır.</p>
                        <p>(4) Yönetim Kurulu kararları toplantıya katılanların oy çokluğu ile alınır.</p>
                    </article>

                    <article>
                        <h5>Yönetim Kurulunun görevleri</h5>
                        <p>MADDE 11 – (1) Yönetim Kurulu, Merkezin yönetimi ile ilgili konularda aşağıdaki görevleri yapar:</p>
                        <ol>
                            <li>Merkezin çalışmalarıyla ilgili plan ve programların hazırlanmasını ve uygulamasını sağlar.</li>
                            <li>Merkez personelinin eğitim, uygulama, araştırma, hizmet üretimi ve yayım konularındaki isteklerini değerlendirip önerilerde bulunur.</li>
                            <li>Gerekli hallerde Merkezin faaliyetleri ile ilgili geçici çalışma gurupları kurar ve bunların görevlerini düzenler.</li>
                            <li>Müdürün, Merkezin yönetimi ile ilgili getireceği bütün işleri değerlendirerek karar alır.</li>
                            <li>Merkezin işleyişi için gerekli olan ve ihtiyaç duyulan birimleri kurabilir ve görev tanımlarını belirler.</li>
                        </ol>
                    </article>

                    <article>
                        <h5>Birimler</h5>
                        <p>MADDE 12 – (1) Merkez bünyesinde Merkezin amaç ve faaliyet alanları doğrultusunda çalışmalarda bulunmak üzere Yönetim Kurulu önerisi ile Rektör tarafından birimler kurulabilir.</p>
                        <p>(2) Birim sorumluları; Yönetim Kurulu önerisi üzerine Üniversitenin ilgili birimlerinde görev yapan öğretim üyeleri arasından Rektör onayı ile bir yıl süre için görevlendirilir. Birim sorumlularının görev, yetki ve sorumlulukları Yönetim Kurulunca belirlenir.</p>
                    </article>
                </section>

                {/* DÖRDÜNCÜ BÖLÜM */}
                <section className="regulation-section">
                    <h3>DÖRDÜNCÜ BÖLÜM</h3>
                    <h4>Çeşitli ve Son Hükümler</h4>

                    <article>
                        <h5>Personel ihtiyacı</h5>
                        <p>MADDE 13 – (1) Merkezin akademik, teknik ve idari personel ihtiyacı 2547 sayılı Kanunun 13 üncü maddesine göre Rektör tarafından görevlendirilecek personel tarafından karşılanır.</p>
                    </article>

                    <article>
                        <h5>Hüküm bulunmayan haller</h5>
                        <p>MADDE 14 – (1) Bu Yönetmelikte hüküm bulunmayan hallerde ilgili diğer mevzuat hükümleri ve Senato kararları uygulanır.</p>
                    </article>

                    <article>
                        <h5>Harcama Yetkilisi</h5>
                        <p>MADDE 15 – (1) Merkezin harcama yetkilisi Rektördür. Rektör bu yetkisini gerekli gördüğü takdirde Müdüre devredebilir.</p>
                    </article>

                    <article>
                        <h5>Yürürlük</h5>
                        <p>MADDE 16 – (1) Bu Yönetmelik yayımı tarihinde yürürlüğe girer.</p>
                    </article>

                    <article>
                        <h5>Yürütme</h5>
                        <p>MADDE 17 – (1) Bu Yönetmelik hükümlerini Kütahya Sağlık Bilimleri Üniversitesi Rektörü yürütür.</p>
                    </article>
                </section>
            </div>
        </div>
    );
};

export default Regulations;