import React, { useEffect, useState } from 'react';
import './styles.css';
import { Page, Text, View, Document, PDFViewer } from '@react-pdf/renderer';

const PDFView = () => {
  const [formData, setFormData] = useState(null);

  useEffect(() => {
    const data = sessionStorage.getItem('formData');
    if (data) setFormData(JSON.parse(data));
  }, []);

  if (!formData) return <div>Yükleniyor...</div>;

  const MyDocument = (
    <Document>
      <Page size="A4" style={{ padding: 30 }}>
        <View>
          <Text style={{ fontSize: 20, marginBottom: 10 }}>BİYOKİMYA LAB DENEY FORMU</Text>
          <Text>Ad Soyad: {formData.name}</Text>
          <Text>Kurum: {formData.institution}</Text>
          <Text>Telefon: {formData.phone}</Text>
          <Text>Email: {formData.email}</Text>
          <Text>Adres: {formData.address}</Text>
        </View>
      </Page>
    </Document>
  );

  return (
    <div className="pdf-view-container">
      <PDFViewer className="pdf-viewer">
        {MyDocument}
      </PDFViewer>
    </div>
  );
};

export default PDFView;
