// src/pages/LabEquipment/LabEquipment.js
import React from 'react';
import LabEquipmentList from '../../components/LabEquipmentList/LabEquipmentList';
import './LabEquipment.css';

const LabEquipment = () => {
  return (
    <div className="lab-equipment-page">
      <h1 className="page-title">Laboratuvar Cihazları</h1>
      <LabEquipmentList />
    </div>
  );
};

export default LabEquipment;
