import React from 'react';
import './Budget.css';
import ksbuLogo from '../../../../assets/ksbu_logo.png';
import kuyamLogo from '../../../../assets/kuyam_logo.png';

const Budget = () => {
    const customerInfo = {
        name: "Öğretim Görevlisi Önder Öztürk",
        phone: "05333798110",
        email: "onder.ozturk@ksbu.edu.tr",
        address: "Ali paşa mah. Hamza Güner cd. 16/4 Kütahya"
    };

    return (
        <div className="budget-page-wrapper">
            {/* Header Section */}
            <div className="budget-header">
                <div className="budget-logo-wrapper">
                    <img src={ksbuLogo} alt="KSBU Logo" className="budget-ksbu-logo" />
                    <img src={kuyamLogo} alt="KUYAM Logo" className="budget-kuyam-logo" />
                </div>
                <div className="budget-header-content">
                    <h1 className="budget-uni-title">KÜTAHYA SAĞLIK BİLİMLERİ ÜNİVERSİTESİ</h1>
                    <h2 className="budget-center-title">KUYAM UYGULAMA VE ARAŞTIRMA MERKEZİ</h2>
                    <h3 className="budget-report-title">MÜŞTERİ İŞLEM RAPORU</h3>
                </div>
            </div>

            {/* Customer Information Section */}
            <div className="budget-customer-section">
                <div className="budget-info-row">
                    <div className="budget-info-label">Müşteri Bilgileri:</div>
                    <div className="budget-info-value">{customerInfo.name}</div>
                </div>
                <div className="budget-info-row">
                    <div className="budget-info-label">Telefon:</div>
                    <div className="budget-info-value">{customerInfo.phone}</div>
                    <div className="budget-info-label">email:</div>
                    <div className="budget-info-value">{customerInfo.email}</div>
                </div>
                <div className="budget-info-row">
                    <div className="budget-info-label">Adres:</div>
                    <div className="budget-info-value">{customerInfo.address}</div>
                </div>
            </div>

            {/* Transactions Table */}
            <div className="budget-table-container">
                <table className="budget-table">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Tarih</th>
                            <th>İşlem Türü</th>
                            <th>Tahsilat Tutarı</th>
                            <th>Borç Tutarı</th>
                        </tr>
                    </thead>
                    <tbody>
                        {/* Boş tablo satırı */}
                    </tbody>
                    <tfoot>
                        <tr className="budget-total-row">
                            <td colSpan="3">TOPLAMLAR</td>
                            <td>0,00 TL</td>
                            <td>0,00 TL</td>
                        </tr>
                        <tr className="budget-balance-row">
                            <td colSpan="3">ALACAK BAKİYESİ</td>
                            <td colSpan="2">0,00 TL</td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    );
};

export default Budget;